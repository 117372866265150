import React, { FC, useState, useEffect } from "react";
// @ts-ignore
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";

import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import { DateTimeUtils, Icon } from "../../../modules";
import { getLocaleKey } from "../../../languages";
import moment from "moment";

export const FilterInputDateTime: FC<any> = (props) => {
    const { 
        paramKey,
        onChange, 
        minDate, 
        maxDate, 
        maxDetail, 
        isSecondTime, 
        disabled, 
        className, 
        format
    } = props;

    const [value, setValue] = useState<any>(props.params[paramKey]);

    const handleChange = (date: any) => {
        let time: any;

        if (date) {
            time = (isSecondTime === true) ? moment(date).unix() : moment(date).valueOf();
        }

        setValue(time);
        if (onChange) return onChange({ [paramKey]: time });
    };

    const convertTime = (time: any) => {
        if (!time) return;
        if (isSecondTime) return moment.unix(time).toDate();
        return moment(time).toDate();
    };

    return (
        <DateTimePicker
            className={`FilterInputDateTime ${className}`}
            calendarClassName="FilterInputDateTimeCalendar"
            clockClassName="FilterInputDateTimeClock"
            maxDetail={maxDetail}
            disableClock={true}
            onChange={handleChange}
            minDate={convertTime(minDate)}
            maxDate={convertTime(maxDate)}
            value={convertTime(value)}
            locale={getLocaleKey()}
            disabled={disabled}
            format={format}
            clearIcon={null}
            onCalendarClose={() => {
                if (props.onFocus) props.onFocus();
            }}
            onCalendarOpen={() => {
                if (props.onBlur) props.onBlur();
            }}
            onBlur={(e: any) => {
                if (props.onBlur) props.onBlur(e);
            }}
            onFocus={(e: any) => {
                if (props.onFocus) props.onFocus(e);
            }}
        />
    );
};
