import React, { useState } from "react";
import { translate } from "../../languages";
import {
  Button,
  CreateAlert,
  InputWraper,
  NumberUtils,
  ObjectUtils,
  onError,
  Table,
  useForm,
} from "../../modules";
import * as Yup from "yup";
import { InputNumber, InputText } from "../../components";
import { useSelector } from "../../store";
import moment from "moment";
import { getEnv } from "../../configs";
import { capitalize } from "lodash";
import { AdminService } from "../../services/admin";
import { PoolService } from "../../services";

type Props = {};

export const PoolPage = (props: Props) => {
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

  const coinsState = useSelector((state) => state.coins);
  const coinsOptions = ObjectUtils.getIn(coinsState, "data", [], (arr) =>
    arr.map((item: any) => ({
      label: item.name,
      value: item.coinId,
    }))
  );

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "amount",
        // defaultValue: Number(withdrawConfig.USER_MAXIMUM_IN_DAY),
        validate: Yup.number()
          .required(translate("must be provided"))
          .min(1, translate("not filled in zero")),
      },
      {
        name: "description",
        // defaultValue: Number(withdrawConfig.SYSTEM_MAXIMUM_IN_DAY),
        validate: Yup.string().required(translate("must be provided")),
      },
    ],
    onSubmit: async (values) => {
      const payload = {
        value: values.amount,
        description: values.description,
        feeCoinId: 2,
        coinId: 2,
        transactionTypeId: 68,
        receiverEmail: getEnv("RECEIVER_EMAIL"),
      };
      return PoolService.transferPool(payload)
        .then(async () => {
          CreateAlert({ type: "success", message: "Transfer pool successful." });
          setForceUpdateTable(Math.random());
        })
        .catch(onError);
    },
  });

  return (
    <div className="mb20">
      <span className="config--title mb32">Pool Transfer</span>
      <div className="row config--item ml3 mr3">
        <div className="col-sm-5">
          <form className="blockLists" onSubmit={handleSubmit}>
            <InputWraper
              label={"Amount"}
              inputProps={getInputProps("amount")}
              component={InputNumber}
            />
            <InputWraper
              label={"Description"}
              inputProps={getInputProps("description")}
              component={InputText}
            />
            <div>
              <Button
                // className="mb20"
                label="Submit"
                // onClick={props.onClick}
                isMiddle
                buttonType="success"
                className="btnBan"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
      <span className="config--title mt40 mb32">Pool Transaction</span>
      <Table
        hasOrderColumn
        itemPerPages={10}
        structure={[
          {
            name: "Coin",
            key: "coinId",
            className: "coin",
            render: (item) => {
              const coin = coinsOptions.find((v: any) => v.value === item.coinId);
              let coinAvatar;
              switch (coin?.value) {
                case 2: {
                  coinAvatar = "usd.png";
                  break;
                }
                case 4: {
                  coinAvatar = "usd.png";
                  break;
                }
                case 5: {
                  coinAvatar = "usd.png";
                  break;
                }
              }
              return (
                <>
                  <img className="coin-avatar" src={`/assets/images/coins/${coinAvatar}`} alt="" />
                  <span className="coin-label">{ObjectUtils.getIn(coin, "label", "--")}</span>
                </>
              );
            },
          },
          {
            name: "Time",
            key: "created",
            render: (item) => {
              return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
            },
          },
          {
            name: "Amount",
            key: "value",
            render: (item) => {
              const { balanceAfter, balanceBefore } = item;
              let isIncrease = false;
              if (balanceAfter > balanceBefore) isIncrease = true;
              return (
                <span className={isIncrease ? "textSuccess" : "textDanger"}>
                  {isIncrease ? "+" : "-"}
                  {NumberUtils.toFormatNumber(+item?.value, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
                </span>
              );
            },
          },
          {
            name: "Type",
            key: "transactionTypeId",
            render: (item) => {
              if (item.transactionType.name === "EXGAME_REFUND")
                return capitalize("EXPERIECE_GAME_REFUND".replace(/_/g, " "));
              return ObjectUtils.getIn(
                capitalize(item.transactionType.name.replace(/_/g, " ")),
                "",
                "--"
              );
            },
            //render: (item) => ObjectUtils.getIn(item, 'transactionType.name', '--'),
          },
          {
            name: translate("description"),
            key: "description",
          },
        ]}
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListTransactions({
            page: params.pageNumber,
            numberOfTransactionsPerPage: params.limit,
            transactionTypeId: 68,
            ...params,
          });
        }}
        forceUpdateTable={forceUpdateTable}
      />
    </div>
  );
};
