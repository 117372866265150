import React, { FC, useState } from "react";
import * as Yup from "yup";
import { InputNumber, InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
// import "./index.scss";

export const TransferMainWallet: FC<{ data: any; onFinish: () => void }> = (
  props
) => {
  const [coinId, setCoinId] = useState(3);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "id",
        defaultValue: props?.data?.id,
        validate: Yup.string().required(translate("must-be-provided")),
        isDisabled: true,
      },
      {
        name: "amount",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const params = {
        id: props?.data?.id,
        value: values?.amount,
        coinId,
      };

      const arrPush = [
        props?.data?.type === "plus"
          ? AdminService.receiveTransfer(params)
          : AdminService.sendTransfer(params),
      ];

      return Promise.all(arrPush).then(() => {
        CreateAlert({
          type: "success",
          message: "Successful transfer",
        });
        props.onFinish();
      });
    },
  });
  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper
        label={translate("id")}
        inputProps={getInputProps("id")}
        component={InputText}
      />

      <InputWraper
        label={translate("amount")}
        inputProps={getInputProps("amount")}
        component={InputNumber}
      />

      <div>
        <Button
          label="Update"
          isMiddle
          buttonType="primary"
          className="btnBan"
          type="submit"
        />
      </div>
    </form>
  );
};
