import React, { FC } from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { Routes } from '../../AppRoutes';
import { Icon } from '../../components';

export interface IProductManagementWrapperProps extends RouteComponentProps {}

export const withProductManagementWrapper =
  (Component: FC<IProductManagementWrapperProps>) =>
  (props: IProductManagementWrapperProps) => {
    return (
      <div className="withProductManagement">
        <div className="TabLinks">
          <NavLink to={Routes.WaitingApprovalProducts.path} exact={true}>
            {/* <Icon.User /> */}
            Waiting Approval Products
          </NavLink>
          <NavLink to={Routes.ApprovedProducts.path} exact={true}>
            {/* <Icon.User /> */}
            Approved Products
          </NavLink>
          <NavLink to={Routes.RejectedProducts.path} exact={true}>
            {/* <Icon.User /> */}
            Recjected Products
          </NavLink>
          <NavLink to={Routes.DeletedProducts.path} exact={true}>
            {/* <Icon.User /> */}
            Deleted Products
          </NavLink>
          <NavLink to={Routes.orderManagement.path} exact={true}>
            {/* <Icon.User /> */}
            Order Management
          </NavLink>
        </div>

        <Component {...props} />
      </div>
    );
  };
