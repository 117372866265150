import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { FilterInputDateTime, TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, InputWraper, ITableStructureItem, NumberUtils, Table, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";
import XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import * as Yup from "yup";
import { PopupWraper } from "../../../components/popup";
import { InputText, InputTextArea } from "../../../components";
import { ConfirmPopup } from "../../startup/components";

export const GrantLimitHistoryList = withTransactionWraper(() => {

    const history = useHistory();

    let today = moment();
    
    const [params, setParams] = useState<any>({});
    const [currentData, setCurrentData] = useState<any>();

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getGrantLimitHistoryList({
                    ...params,
                    page: 1,
                    numberOfTransactionsPerPage: 10000,
                });
                const data = response.data;

                let fileHead: any = structure.map((v) => v.name);
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) => {
                        return structure.map((column:any, index:any) => {
                            if (column.key === "created") return moment(item?.[column.key]).format("L HH:mm:ss");
                            if (!column.key) return "";
                            return item[column.key];
                        });
                    }),
                ];
                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                const now = new Date();
                XLSX.writeFile(wb, `Grant Limit History List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, { type: "binary" });
                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    const filter: any = [
        {
            name: translate("search"),
            key: "keyword",
            input: (e: any) => (
                <TableFilterInputText
                    {...e}
                    placeholder={"Search"}
                />
            ),
        },
        {
            name: 'Time',
            key: 'created',
            input: (e: any) => (
                <TableFilterRangeTimeInput
                    {...e}
                    fromKey="fromDate"
                    toKey="toDate"
                />
            ),
            defaultValue: {
                fromDate: today.clone().subtract(30, 'days').startOf('day').format('L HH:mm:ss'), 
                toDate: today.clone().endOf('day').format('L HH:mm:ss')
            }
        },
    ];

    const structure: ITableStructureItem[] = [
        {
            name: "Admin ID Action",
            key: "actionById",
        },
        {
            name: "Admin Name Action",
            key: "actionByName",
        },
        {
            name: "Grant Limit Amount",
            key: "creditLimit",
            render: item => (item?.creditLimit != null) ? NumberUtils.toFormatNumber(+item.creditLimit, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000",
        },
        {
            name: "Pre-Grant Limit Amount",
            key: "creditLimitBefore",
            render: item => (item?.creditLimitBefore != null) ? NumberUtils.toFormatNumber(+item.creditLimitBefore, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000",
        },
        {
            name: "Machine ID Granted",
            key: "machineId",
        },
        {
            name: "Project Name",
            key: "projectName",
        },
        {
            name: "Project ID",
            key: "agencyId",
        },
        {
            name: "Representative's ID",
            key: "representativeId",
        },
        {
            name: "Date",
            key: "created",
            render: (item: any) => moment(item?.created).format("L HH:mm:ss"),
        },
    ];

    return (
        <div className="GrantLimitHistoryList">
            <div className="button-container">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="primary"
                    disabled={!currentData}
                    onClick={handleExportExcel}
                />
            </div>
            <Table
                hasOrderColumn
                itemPerPages={10}
                className="overload"
                structure={structure}
                filters={filter}
                fetchData={async (params) => {
                    if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).toISOString();
                    if (params["toDate"]) params["toDate"] = moment(params["toDate"]).toISOString();
                    setParams(params);
                    return AdminService.getGrantLimitHistoryList({
                        ...params,
                        page: params.pageNumber,
                        numberOfTransactionsPerPage: params.limit,
                    }).then((res:any) => {
                        setCurrentData(res);
                        return res;
                    });
                }}
            />
        </div>
    );
});