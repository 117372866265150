import moment from "moment";
import React, { useState } from "react";
import { PopupWraper } from "../../../components/popup";
import { Button, Icon, ITableStructureItem, Table } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import CreatePool from "./createPool";
import EditPool from "./editPool";

const TablePool = () => {
  const [editData, setEditData] = useState(null);
  const [createData, setCreateData] = useState(false);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

  const structure: ITableStructureItem[] = [
    {
      name: "Pool Streak Id",
      key: "poolStreakId",
    },
    {
      name: "Reward",
      key: "reward",
    },
    {
      name: "Streak",
      key: "streak",
    },
    {
      name: "Created Date",
      render: (item) => {
        return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
    {
      name: "Modified Date",
      render: (item) => {
        return <span>{moment(item?.modified).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
    {
      name: "Actions",
      key: "actions",
      render: (item) => {
        return (
          <div className="action">
            <div className="action__edit" onClick={() => setEditData({ ...item })}>
              <Icon.ActionEditIcon />
            </div>
            <div
              className="action__edit"
              onClick={() =>
                ConfigService.deletePoolStreak(item?.poolStreakId).then(() =>
                  setForceUpdateTable(Math.random)
                )
              }
            >
              <Icon.Close />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Button
        label="Add Pool Streak"
        onClick={() => setCreateData(true)}
        buttonType="success"
        className="btnBan mb20"
      />
      <Table
        hasOrderColumn
        itemPerPages={10}
        structure={structure}
        fetchData={async (params) => {
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          return ConfigService.getListPoolStreak();
        }}
        forceUpdateTable={forceUpdateTable}
      />
      {editData && (
        <PopupWraper center title="Edit pool streaks" onClose={() => setEditData(null)}>
          <EditPool
            data={editData}
            onFinishEdit={() => {
              setForceUpdateTable(Math.random);
              setEditData(null);
            }}
          />
        </PopupWraper>
      )}
      {createData && (
        <PopupWraper center title="Create pool streaks" onClose={() => setCreateData(false)}>
          <CreatePool
            onFinishEdit={() => {
              setForceUpdateTable(Math.random);
              setCreateData(false);
            }}
          />
        </PopupWraper>
      )}
    </>
  );
};

export default TablePool;
