import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { InputText } from '../../../components';
import { TreeView } from '../../../components/tree-view';
import {
  Button,
  CreateAlert,
  InputWraper,
  Message,
  onError,
  useForm
} from '../../../modules';
import { AdminService } from '../../../services/admin';
import { useSelector } from '../../../store';
import { withFranchiseWraper } from '../wraper';

export const PageFranchiseTree = withFranchiseWraper(() => {
  let [affiliation, setAffiliation] = useState<any>({ data: [] });
  const user = useSelector((state) => state.user);
  let [treeLists, setTreeLists] = useState([1]);

  // useEffect(() => {
  //   AdminService.getAffiliation(25, user.userId)
  //     .then((res) => setAffiliation({ data: res }))
  //     .catch((err) => setAffiliation({ error: err.error }));
  //   // eslint-disable-next-line
  // }, []);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: 'email',
        validate: Yup.string().required('Id is required')
      }
    ],
    onSubmit: async (values) => {
      await AdminService.getUserDetailByEmail(values.email)
        .then(async (res) => {
          if (res.success) {
            pushTreeLists(res.result.userId);
            await AdminService.getAffiliation(25, res.result.userId)
              .then((res) => {
                setAffiliation({ data: res });
              })
              .catch((err) => setAffiliation({ error: err.error }));
          } else {
            CreateAlert({
              message: 'Error when search.',
              type: 'danger'
            });
          }
        })
        .catch(onError);
    }
  });

  const onGetNewTree = (id: any, back: boolean) => {
    AdminService.getAffiliation(25, id)
      .then((res) => {
        setAffiliation({ data: res });
        if (!back) {
          pushTreeLists(id);
        }
      })
      .catch((err) => setAffiliation({ error: err.error }));
  };

  const onBack = () => {
    let list = treeLists;
    if (list.length > 1) {
      const back = list[list.length - 2];
      onGetNewTree(back, true);
    }
  };

  const pushTreeLists = (id: any) => {
    const treeList = treeLists;
    if (treeList[treeList.length - 1] !== id) {
      treeList.push(id);
      setTreeLists(treeList);
    }
  };

  return (
    <div className="PageFranchiseTree">
      <div className="col-sm-12">
        <form className="blockLists" onSubmit={handleSubmit}>
          <div className="row">
            {/* {affiliation} */}
            <div className="col-sm-3">
              <InputWraper
                // label={"Block Lists"}
                inputProps={getInputProps('email')}
                component={InputText}
              />
            </div>
            <div className="col-sm-3">
              <Button
                // className="mb20"
                label="Search"
                // onClick={()=>setTest("2")}
                // isMiddle
                // buttonType="warning"
                className="btnBan"
                type="submit"
              />
            </div>
            <div className="col-sm-6 text-right">
              <Button
                // className="mb20"
                label="Back"
                onClick={() => onBack()}
                // isMiddle
                buttonType="dark"
                // className="btnBan"
                // type="submit"
              />
            </div>
          </div>
        </form>
      </div>
      {(() => {
        if (!affiliation) return <Message type="loading" />;
        if (affiliation.error)
          return <Message type="error" message={affiliation.error.message} />;
        return (
          <TreeView
            onGetTree={(id) => {
              onGetNewTree(id, false);
            }}
            affiliate={affiliation.data}
          />
        );
      })()}
    </div>
  );
});
