import React, { useState } from "react";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { withStartupWraper } from "../wrapper";
import moment from "moment";
import { translate } from "../../../languages";
import { StartupService } from "../../../services/startup";
import XLSX from 'xlsx';
import { getEnv } from "../../../configs";


export const StartupInvestmentHistory = withStartupWraper(() => {
  const [params, setParams] = useState([] as any[]);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
  const [disableExport, setDisableExport] = useState<boolean>(true);

  const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

  const structure: ITableStructureItem[] = [
    {
      name: "Project's name",
      key: "projectName",
      render: (item) => (!!item.projectName ? item.projectName : "--"),
    },
    {
      name: "Investor's email",
      key: "email",
    },
    {
      name: "Invested amount",
      key: "amount",
      render: (item) => NumberUtils.toFormatNumber(+item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"))
    },
    {
      name: "Created date",
      key: "created",
      render: (item) => {
        return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await StartupService.getStartupInvestmentHistory({
          ...params,
          page: 1,
          pageSize: 10000,
        });

        const data = response.data;

        let fileHead:any = structure.map((v) => v.name);
        // fileHead.pop(); //remove last column on table
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            structure.map((column, index) => {
              if (column.key === "created") return moment(item[column.key]).format("DD/MM/y HH:mm:ss");
              if (column.key === "projectName") return !!item.projectName ? item.projectName : "--";
              if (column.key === "amount") return  NumberUtils.toFormatNumber(+item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              if (!column.key) return "";
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(
          wb,
          `Investment Hisotry List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
            .toLocaleTimeString()
            .replace(/:/g, "-")}.xlsx`,
          { type: "binary" }
        );

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error:any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <div className="StartupManagement">
      <Button
          className="mb20"
          label="Export to Excel"
          buttonType="primary"
          disabled={disableExport}
          onClick={handleExportExcel}
        />

      <Table
        hasOrderColumn
        filters={[
          {
            name: "Project name, investor's name",
            key: "searchString",
            input: TableFilterInputText,
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth, //new Date(last1MothDate),
              toDate: endOfDay, //new Date(),
            },
          },
        ]}
        structure={structure}
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          setParams(params);
          return StartupService.getStartupInvestmentHistory({
            page: params.pageNumber,
            pageSize: params.limit,
            ...params,
          }).then((res) => {
            setDisableExport(!(res?.data?.length > 0))
            return res;
          });
        }}
        forceUpdateTable={forceUpdateTable}
      />
    </div>
  );
});
