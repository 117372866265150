export enum ELocale {
  ENGLISH = "en-US",
  VIETNAM = "vi-VN",
  JAPAN = "ja-JP",
  THAILAN = "th-TH",
  INDONESIA = "id-ID",
  MALAYSIA = "ms-MY",
  CHINA = "zh-CN",
}

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export enum EDeviceType {
  DESKTOP = "Desktop",
  MOBILE = "Mobile",
}

export enum EOrderStatus {
  OPEN = "OPEN",
  WIN = "WIN",
  LOSE = "LOSE",
  DRAW = "DRAW",
}

export enum EOrderOption {
  HIGHER = "HIGHER",
  LOWER = "LOWER",
}

export interface IOrder {
  orderId: number;
  userId: number;
  symbolId: number;
  coinId: number;
  amount: number;
  profit: number;
  status: EOrderStatus;
  option: EOrderOption;
  created: Date;
  modified: Date;
}

export enum ETypeApplication {
  DOC = "doc",
  DOCX = "docx",
  PDF = "pdf",
  JPG = "jpg",
  PNG = "png",
  JPEG = "jpeg",
}

export enum ENetWork {
  BEP20 = "BEP20",
  TRC20 = "TRC20",
  ERC20 = "ERC20",
}

export enum ECoinCode {
  MAIN = 'USDT',
  GTC = 'GTC',
  GTM = 'GTM',
  GTV = 'GTV',
  KTGT = 'KTGT',
  GTTAX = 'GTTAX'
}

export enum ETransactionType {
  INIT = 0,
  DEPOSIT = 1,
  WITHDRAW = 2,
  RECEIVE_TRANSFER = 3,
  SEND_TRANSFER = 4,
  DEPOSIT_FEE = 5,
  WITHDRAW_FEE = 6,
  SEND_TRANSFER_FEE = 7,
  RECEIVE_TRANSFER_FEE = 8,
  TRADE_FEE = 9,
  OTHER_INCOME = 10,
  OTHER_FEE = 11,
  BUY_PRESALE_COIN = 12,
  REFUND_WITHDRAW_FEE = 13,
  DIRECT_COMMISSION = 14,
  BUY_AGENCY_LICENSE = 15,
  UPGRADE_MINER = 16,
  BANK_RECEIVE_FROM_USER = 17,
  BANK_SEND_TO_USER = 18,
  CONVERT_SENT = 19,
  CONVERT_RECEIVED = 20,
  SEND_EXCHANGE_FEE = 21,
  CASH_BACK = 25,
  TRADING_COMMISSION = 26,
  REFUND_WITHDRAW = 27,
  REFILL_DEMO_ACCOUNT = 28,
  RETURN_MINER_FEE = 29,
  PLACE_GAME = 46,
  AIRDROP_TOKEN = 48,
  AIRDROP_TOKEN_FROM_USER = 49,
  WITHDRAW_REFUND = 51,
  CANCEL_GAME = 52,
  EXCHANGE_OUT = 53,
  EXCHANGE_OUT_FEE = 54,
  EXCHANGE_IN = 55,
  CIC_AIRDROP = 63,
  CIC_AIRDROP_FROM_F1 = 64,
  AIRDROP_TOKEN_TRADE = 65,
  ADMIN_SEND_TRANSFER = 67,
  TRANSFER_TO_POOL = 68,
  POOL_SEND_TO_USER = 69,
  POOL_REWARD = 70,
  POOL_RECEIVE_FROM_CANDLE = 71,
  BANK_TRANSFER_TO_POOL = 43,
  CANDLE_TO_POOL = 72,
  KYC_FEE = 75,
  BUY_PACKAGE = 76,
  TOKEN_BONUS = 77,
  AGENCY_LOCKED = 78,
  AGENCY_UNLOCKED = 79,
  PAYMENT_OF_GOODS = 80, // Trừ tiền từ ID người mua hàng
  PAYMENT_FROM_CUSTOMER = 81, // Cộng tiền giao dịch từ khách hàng vào superadmin
  RECEIVE_FROM_CUSTOMER = 82, // Superadmin chuyển 85% tiền giao dịch cho đại lý
  CAPITAL_CONTRIBUTION_PROFIT = 83, // Superadmin chia % cho cổ đông
  SHAREHOLDER_FEES = 84, // Trừ 15% giá trị đơn hàng từ ID đại lý trong trường hợp không có ID người mua hàng
  SHAREHOLDER_FEES_FROM_AGENT = 85, // Cộng 15% giá trị đơn hàng từ đại lý khi không có ID người đại diện
  INVESTMENT = 86,
  RECEIVE_DIVIDENDS = 87,
  RECEIVE_TRANSFER_GTMART = 89,
  RECEIVE_TAX_GTMART = 90,
  RECEIVE_PROFIT_STORE_OWNER_GTMART = 91,
  RECEIVE_REINVESTMENT_GTMART = 92,
  RECEIVE_ORIGINAL_PRICE_GTMART = 95,
  RECEIVE_PROFIT_VENDOR = 96,
  SEND_QUALIFIED_REFERRER_REWARD = 97,
  QUALIFIED_REFERRER_REWARD = 98,
  GT_MOVE_DRIVER_PAY = 99,
  GT_MOVE_USER_PAY = 100,
  RECEIVE_PROFIT_GT_MOVE = 101,
  RECEIVE_TAX_GT_MOVE = 102,
  DRIVER_RECEIVE_PROFIT_GT_MOVE = 103,
  RECEIVE_PROFIT_BUYER_GTMART = 105,
  RECEIVE_PROFIT_BUYER_VENDING_MACHINE = 106,

  MINED_GT = 112,
  MINED_DEPOSIT = 113,
  MINED_WITHDRAW = 114,
  MINED_PROFIT = 115, // Nhận lợi nhuận KT-GT
  MINED_TAX = 116,  // Nhận tiền thuế KTGT
  MINED_CAPITAL = 117, // Nhận tiền vốn KT-GT
  MINED_SYSTEM_TRANSFER = 118,  // Hệ Thống chuyển khoản KT-GT
}