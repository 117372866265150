import React, { FC } from "react";
import { RouteComponentProps, NavLink } from "react-router-dom";
import { Routes } from "../../AppRoutes";
import { Icon } from "../../components";

export const withStakingWraper = (Component:any) => (props:any) => {
    return (
        <div className="withTransactionWraper">
            <div className="TabLinks">
                <NavLink
                    to={Routes.staking.path}
                    exact={true}
                >
                    <Icon.StakingIcon />
                    Staking GT
                </NavLink>
            </div>

            <Component {...props} />
        </div>
    );
};
