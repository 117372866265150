import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Routes } from "../../AppRoutes";
import { PopupWraper } from "../../components/popup";
import { TableFilterRangeTimeInput } from "../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";
import { getEnv } from "../../configs";
import { translate } from "../../languages";
import { Button, CreateAlert, ITableStructureItem, NumberUtils, Table } from "../../modules";
import { DividendsService } from "../../services/dividends";
import { useSelector } from "../../store";
import { DividendDistributionForm } from "./dividend-distribution-form";
import XLSX from 'xlsx';


export const PageDividend = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const [disableExport, setDisableExport] = useState<boolean>(true)
  const [isOpenDividendDistributionPopup, setOpenDividendDistributionPopup] = useState<boolean>(false);
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
  const [paramsExport, setParamsExport] = useState<any>({});


  useEffect(() => {
    user?.userRole !== "SUPER_ADMIN" && history.push(Routes.userList.path);
  }, [history, user]);

  let tableStructure: ITableStructureItem[] = [
    {
      name: translate("time"),
      key: "created",
      render: (item: any) => moment(item?.created).format("DD/MM/y HH:mm:ss"),
    },
    {
      name: translate("amount"),
      key: "amount",
      render: (item) => NumberUtils.toFormatNumber(+item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")),
    },
    {
      name: "Status",
      key: "status",
      render: (item) => <span className={`status status--${item?.status?.toLowerCase()}`}>{translate(item?.status)}</span>,
    },
  ];

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await DividendsService.getTableDividends({
          ...paramsExport,
          pageSize: 10000,
          page: 1,
        });

        const data = response.data;

        let fileHead: any = tableStructure.map((v) => v.name);
        // fileHead.pop(); //remove last column on table
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            tableStructure.map((column, index) => {
              if (column.key === 'created')
                return moment(item[column.key]).format("DD/MM/y HH:mm:ss");
              if (column.key === 'amount') return NumberUtils.toFormatNumber(+item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              if (column.key === 'status') return translate(item?.status);
              if (!column.key) return '';
              return item[column.key];
            })
          )
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

        const now = new Date();
        XLSX.writeFile(
          wb,
          `Dividends List ${now.toLocaleDateString().replace(/\//g, '-')} ${now
            .toLocaleTimeString()
            .replace(/:/g, '-')}.xlsx`,
          { type: 'binary' }
        );

        resolve(
          CreateAlert({
            type: 'success',
            message: 'Export data success.'
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: 'danger', message: error.message }));
      }
    });
  };

  return (
    <div className="Dividend">
      <Button label={"Dividends"} className="mb20" buttonType="primary" onClick={() => setOpenDividendDistributionPopup(true)} />
      <Button
        className="mb20 ml16"
        label="Export to Excel"
        buttonType="primary"
        disabled={disableExport}
        onClick={handleExportExcel}
      />
      <Table
        hasOrderColumn
        filters={[
          {
            name: "Date",
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" format={"MM/dd/y"} />,
          },
          {
            name: translate("type"),
            key: "status",
            input: (s) => (
              <TableFilterInputSelect {...s} options={DividendsService.getListDividendStatus()} isClearable={true} isSearchable={false} />
            ),
          },
        ]}
        structure={tableStructure}
        forceUpdateTable={forceUpdateTable}
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          setParamsExport({...params})
          return DividendsService.getTableDividends({
            ...params,
            pageSize: params.limit,
            page: params.pageNumber,
          }).then((res) => {
            setDisableExport(!(res?.data?.length > 0))
            return res
          });
        }}
      />

      {isOpenDividendDistributionPopup && (
        <PopupWraper onClose={() => setOpenDividendDistributionPopup(false)} title={"Dividends"}>
          <DividendDistributionForm
            onClose={() => {
              setForceUpdateTable((state) => !state);
              setOpenDividendDistributionPopup(false);
            }}
          />
        </PopupWraper>
      )}
    </div>
  );
};
