import moment from "moment";
import React, { FC } from "react";
import * as Yup from "yup";
import { InputDateTime, InputNumber, InputTextArea } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";

const CreateAnnouncement: FC<{ onFinishEdit: () => void }> = (props) => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "content",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "expirationDate",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const { content, expirationDate } = values;
      return ConfigService.createAnnouncement({
        content,
        expirationDate: moment(expirationDate).toISOString(),
      })
        .then(() => {
          CreateAlert({
            message: "Create success.",
            type: "success",
          });
          props.onFinishEdit();
        })
        .catch((error: any) => {
          CreateAlert({
            message: error.message,
            type: "danger",
          });
        });
    },
  });

  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper
        label={"Content"}
        inputProps={getInputProps("content")}
        component={InputTextArea}
        className="mr5"
      />
      <InputWraper
        label={translate("Expiration Date")}
        inputProps={getInputProps("expirationDate")}
        component={InputDateTime}
        className="ml5"
      />
      <div>
        <Button label="Create" isMiddle buttonType="success" className="btnBan" type="submit" isLoading={isSubmitting} />
      </div>
    </form>
  );
};

export default CreateAnnouncement;
