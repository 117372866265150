import React, { FC, memo, useEffect, useState } from 'react';
import { AffiliatedAgentLayout } from '../components';
import { AdminService } from '../../../services/admin';
import {
  Button,
  CreateAlert,
  ITableStructureItem,
  Icon,
  Table
} from '../../../modules';
import { getLocaleKey, translate } from '../../../languages';
import {
  TableFilterInputText,
  TableFilterInputTextCustom
} from '../../../components/table-filter-inputs';
import XLSX from 'xlsx';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../AppRoutes';

export const AgencyRevenue = AffiliatedAgentLayout(
  memo(() => {
    const [disableExport, setDisableExport] = useState<boolean>(true);
    const [params, setParams] = useState([] as any[]);

    useEffect(() => {
      AdminService.getListAgency();
    }, []);

    const structure: ITableStructureItem[] = [
      {
        name: 'No',
        key: 'no',
        render: (item) => {
          return <p>{item._order}</p>;
        }
      },
      {
        name: translate('agency-label'),
        key: 'agencyName',
        render: (item) => {
          return <p>{item.agencyName}</p>;
        }
      },
      {
        name: "Profits",
        key: 'totalprofits',
        render: (item) => {
          return (
            <p>
              {item?.totalprofits?.toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })}
            </p>
          );
        }
      },
      {
        name: translate('revenue-label'),
        key: 'totalrevenues',
        render: (item) => {
          return (
            <p>
              {item?.totalrevenues?.toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })}
            </p>
          );
        }
      },
      {
        name: translate('actions-label'),
        key: 'actions',
        render: (item) => {
          return (
            <div className="action">
              <div className="action__edit">
                <NavLink
                  to={Routes.detailRevenueAgency.renderPath(
                    item.agencyId,
                    item.agencyName
                  )}
                  exact={true}
                >
                  <div style={{ color: 'white' }}>
                    <Icon.View />
                  </div>
                </NavLink>
              </div>
            </div>
          );
        }
      }
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListAgency({
            ...params,
            page: 1,
            pageSize: 10000
          });
          let data = response.data;
          data = data.map((item: any, index: number) => {
            return {
              no: index + 1,
              ...item
            };
          });
          let fileHead: any = structure.map((v) => v.name);
          fileHead.pop(); //remove last column on table
          const dataExport = [
            fileHead,
            ...data.map((item: any, _index: number) => {
              return structure.map((column, index) => {
                if (!column.key) return '';
                return item[column.key];
              });
            })
          ];
          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
          const now = new Date();
          XLSX.writeFile(
            wb,
            `Order List ${now.toLocaleDateString().replace(/\//g, '-')} ${now
              .toLocaleTimeString()
              .replace(/:/g, '-')}.xlsx`,
            { type: 'binary' }
          );
          resolve(
            CreateAlert({
              type: 'success',
              message: 'Export data success.'
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: 'danger', message: error.message }));
        }
      });
    };

    return (
      <>
        <div className="revenue-container">
          <Button
            className="mb20"
            label="Export to Excel"
            buttonType="primary"
            disabled={disableExport}
            onClick={handleExportExcel}
          />

          <Table
            // doubleScroll
            // hasOrderColumn
            itemPerPages={10}
            className="overload"
            structure={structure}
            filters={[
              {
                name: translate('search'),
                key: 'keyword',
                input: (e: any) => {
                  return (
                    <TableFilterInputTextCustom
                      {...e}
                      placeholder={translate('search-by-agency-name')}
                    />
                  );
                }
              }
            ]}
            fetchData={async (params) => {
              setParams(params);
              return AdminService.getListAgency({
                page: params.pageNumber,
                pageSize: params.limit,
                ...params
              }).then((res) => {
                setDisableExport(!(res?.data?.length > 0))
                return res;
              });
            }}
          />
        </div>
      </>
    );
  })
);
