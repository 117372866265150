import { RequestMainService } from "../request";

export class DividendsService {
  static getListDividendStatus() {
    return [
      { label: "Just Created", value: "JUST_CREATED" },
      { label: "Processing", value: "PROCESSING" },
      { label: "Completed", value: "COMPLETED" },
    ];
  }

  static async getTableDividends(payload: any) {
    return RequestMainService.get("/admin/get-devidend-history", payload).then(({ result }) => ({
      count: result.total,
      data: result.data,
    }));
  }

  static async dividend(amount: number) {
    return RequestMainService.post("/admin/dividend", { amount });
  }
}
