import React, { useState } from 'react';
import moment from 'moment';
import XLSX from 'xlsx';

import { withProductManagementWrapper } from '../wrapper';
import {
  Button,
  CreateAlert,
  Icon,
  ITableStructureItem,
  Table,
} from '../../../modules';
import {
  TableFilterInputText,
  TableFilterRangeTimeInput
} from '../../../components/table-filter-inputs';
import { translate } from '../../../languages';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../AppRoutes';
import { TableFilterInputSelect } from '../../../components/table-filter-inputs/select';
import { ProductManagementService } from '../../../services/product-management/productManagement.service';

export const RejectedProducts = withProductManagementWrapper(() => {
  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY/MM/DD HH:mm:ss');
  const endOfDay = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss');
  

  const productTypeFilter = [
    { value: 'PRODUCT', label: 'Product' },
    { value: 'SERVICE', label: 'Service' },
  ]
  const [params, setParams] = useState([] as any[]);
  const [disableExport, setDisableExport] = useState<boolean>(true);


  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await ProductManagementService.getProductList({
          ...params,
          page: 1,
          pageSize: 10000,
          status: "REJECTED",
        });
        const data = response.data;

        let fileHead: any = structure.map((v) => v.name);
        fileHead.pop(); //remove last column on table
        const dataExport = [
          fileHead,
          ...data.map((item: any) => {
            return structure.map((column, index) => {
              // if (column.key === 'deliverType') {
              //   item[column.key] =
              //     item[column.key] === 'ONLINE' ? 'NO' : 'YES';
              // }
              if (column.key === 'created')
                return moment(item[column.key]).format('L HH:mm:ss');
              if (column.key === 'status')
                return translate(item[column.key]);
              if (column.key === 'price')
                return item.details[0].price || ""
              if (column.key === 'historyDate'){ 
                const filteredHistory = item.histories?.filter((el: any) => el.status === item.status);
                return filteredHistory.length > 0 ? moment(filteredHistory[filteredHistory.length-1]?.created).format('HH:mm, ddd DD/MM/YYYY') : ""
              }
              if (column.key === 'reason'){ 
                const filteredHistory = item.histories?.filter((el: any) => el.status === item.status);
                return filteredHistory.length > 0 ? filteredHistory[filteredHistory.length-1]?.reason : ""
              }
              if (!column.key) return '';
              return item[column.key];
            });
          })
        ];
        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
        const now = new Date();
        XLSX.writeFile(
          wb,
          `Rejected Product List ${now.toLocaleDateString().replace(/\//g, '-')} ${now
            .toLocaleTimeString()
            .replace(/:/g, '-')}.xlsx`,
          { type: 'binary' }
        );
        resolve(
          CreateAlert({
            type: 'success',
            message: 'Export data success.'
          })
        );
      } catch (error: any) {
        resolve(CreateAlert({ type: 'danger', message: error.message }));
      }
    });
  };

  const filter: any = [
    {
      name: translate('search'),
      key: 'keyword',
      input: (e: any) => <TableFilterInputText {...e}  placeholder={"Enter product name or agency name"} />
    },
    {
      name: translate('time'),
      key: 'created',
      input: (e: any) => (
        <TableFilterRangeTimeInput
          {...e}
          fromKey="fromDate"
          toKey="toDate"
        />
      ),
      defaultValue: {
        fromDate: startOfMonth, //new Date(last1MothDate),
        toDate: endOfDay //new Date(),
      }
    },
    {
      name: "Product Type",
      key: 'productType',
      input: (s: any) => (
        <TableFilterInputSelect {...s} options={productTypeFilter} />
      )
    }
  ]

  const structure: ITableStructureItem[] = [
    {
      name: 'Name',
      key: 'productName',
      render: (item) => {
        return <p className="product-name">{item?.productName || "--"}</p>;
      }
    },
    {
      name: 'Product Type',
      key: 'productType',
      render: (item) => {
        return <p>{item?.productType || '--'}</p>;
      }
    },
    {
      name: 'Agency',
      key: 'agencyName',
      render: (item) => {
        return <p>{item?.agencyName || "--"}</p>;
      }
    },
    {
      name: 'Address',
      key: 'address',
      render: (item) => {
        return <p className="address">{item?.address || "--"}</p>;
      }
    },
    {
      name: 'Cost Price (GT)',
      key: 'originalPrice',
      render: (item) => item?.details[0]?.originalPrice || 0
    },
    {
      name: 'Price (GT)',
      key: 'price',
      render: (item) => item?.details[0]?.price || 0
    },
    {
      name: 'Date Created',
      key: 'created',
      render: (item) => {
        // return <p>{moment(item?.date).format('DD/MM/y HH:mm:ss')}</p>;
        return (
          <p className="date">
            {moment(item?.created).format('HH:mm, ddd DD/MM/YYYY')}
          </p>
        );
      }
    },
    {
      name: 'Date Rejected',
      key: 'historyDate',
      render: (item) => {
        const filteredHistory = item?.histories?.filter((el: any) => el?.status === item?.status);
        // return <p>{moment(item?.date).format('DD/MM/y HH:mm:ss')}</p>;
        return (
          <p className="date">
            { filteredHistory?.length > 0 ? moment(filteredHistory[filteredHistory.length-1].created).format('HH:mm, ddd DD/MM/YYYY') : "--"}
          </p>
        );
      }
    },
    {
      name: 'Reason to reject',
      key: 'reason',
      render: (item) => {
        const filteredHistory = item.histories?.filter((el: any) => el.status === item.status);
        // return <p>{moment(item?.date).format('DD/MM/y HH:mm:ss')}</p>;
        return (
          <p className="date">
            {filteredHistory.length > 0 ? filteredHistory[filteredHistory.length-1].reason : ""}
          </p>
        );
      }
    },
    {
      name: 'Actions',
      key: 'actions',
      render: (item) => {
        return (
          <div className="action">
            <div
              className="action__edit"
              // onClick={() => setEditData({ ...item })}
            >
              <NavLink
                to={Routes.ProductDetail.renderPath(item.productId)}
                exact={true}
              >
                <div style={{ color: 'white' }}>
                  <Icon.View />
                  {/* <Icon.CheckboxChecked /> */}
                  {/* <div style={{ display: 'none' }}>
                  </div> */}
                </div>
              </NavLink>
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <div className="ProductList">
      <Button
        className="mb20"
        label="Export to Excel"
        buttonType="primary"
        disabled={disableExport}
        onClick={handleExportExcel}
      />
      <Table
        hasOrderColumn
        itemPerPages={10}
        className="overload"
        structure={structure}
        filters={filter}
        fetchData={async (params) => {
          if (params['fromDate'])
            params['fromDate'] = new Date(params['fromDate']);
          if (params['toDate'])
            params['toDate'] = new Date(params['toDate']);
          setParams(params);
          return ProductManagementService.getProductList({
            status: "REJECTED",
            page: params.pageNumber,
            pageSize: params.limit,
            ...params
          }).then((res) => {
            setDisableExport(!(res?.data?.length > 0));

            return res;
          });;
        }}
      />
    </div>
    )
   
})
