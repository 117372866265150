import React, { FC, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import "./index.scss";
import { Button, CreateAlert, Icon, InputWraper, Table, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { InputDateTime, InputSelectAsyncData, InputText } from "../../../components";
import { InputImageSingle } from "../../../components/single-image";
import { AffiliatedAgentLayout } from "../components";
import { Routes } from "../../../AppRoutes";
import { PopupWraper } from "../../../components/popup";
import { translate } from "../../../languages";
import validator from "validator";
import levenshtein from "js-levenshtein";
import { EditorForm } from "../../../components/ckeditor-compnt";
import { useDeviceType } from "../../../hook";
import { PageHead } from "../../../components/page-head";

export const AffiliatedAgentEditPage = (props: any) => {
  const deviceType = useDeviceType();
  const { params }: any = useRouteMatch();
  const agencyIdParam = params?.id;
  const history = useHistory();
  const [dataAgencyDetail, setDataAgencyDetail] = useState<any>();
  const [dataShareholder, setDataShareholder] = useState<any>([]);
  const [dataShareholderEdit, setDataShareholderEdit] = useState<any>();
  const [isShowShareholdersCreatePopup, setIsShowShareholdersCreatePopup] = useState<boolean>(false);
  const [imageArray, setImageArray] = useState<any>([]);

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "projectName",
        defaultValue: dataAgencyDetail?.projectName,
        validate: Yup.string().required("Must be provided"),
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
      },
      {
        name: "minBalanceUserRequire",
        defaultValue: dataAgencyDetail?.minBalanceUserRequire,
        isDisabled: true,
      },
      {
        name: "representativeName",
        defaultValue: dataAgencyDetail?.representativeName,
        isDisabled: true,
      },
      {
        name: "representativeEmail",
        defaultValue: dataAgencyDetail?.representativeEmail,
        isDisabled: true,
      },
      {
        name: "address",
        // validate: Yup.string().required("Must be provided"),
        defaultValue: dataAgencyDetail?.address,
      },
      {
        name: "phoneNumber",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataAgencyDetail?.phoneNumber,
        isDisabled: true,
      },
      {
        name: "personalId",
        defaultValue: dataAgencyDetail?.personalNumberId,
        isDisabled: true,
      },
      {
        name: "kindOfBussiness",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataAgencyDetail?.kindOfBussiness,
      },
      {
        name: "website",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataAgencyDetail?.website,
      },
      {
        name: "totalAmountContribution",
        defaultValue: dataAgencyDetail?.totalAmountContribution,
        // validate: Yup.number().required("Must be provided").moreThan(0, "Must be greater than 0"),
        validate: Yup.number().required("Must be provided"),
        isDisabled: true,
      },
      {
        name: "representativeEmail",
        validate: Yup.string()
          .required("Must be provided")
          .test("is-email", translate("INVALID_EMAIL"), (value: any) => {
            // Check if it's a valid email
            if (value && !validator.isEmail(value)) {
              return false;
            }

            // Custom regular expression for more rigorous email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(value)) {
              return false;
            }

            // List of valid domains
            const validDomains = [
              "gmail.com",
              "yahoo.com",
              "hotmail.com",
              "outlook.com",
              "aol.com",
              "icloud.com",
              "msn.com",
              "live.com",
              "protonmail.com",
              "zoho.com",
              "mail.com",
              "yandex.com",
            ];

            // Check if the email's domain is similar to any of the valid domains
            const domain = value?.split("@")[1];
            const isSimilarToValidDomain = validDomains.some((validDomain) => levenshtein(validDomain, domain) <= 2);

            // If the domain is similar to a valid domain but not exactly the same, fail validation
            if (isSimilarToValidDomain && !validDomains.includes(domain)) {
              return false;
            }

            return true;
          }),
        defaultValue: dataAgencyDetail?.representativeEmail,
        isDisabled: true,
      },
      {
        name: "projectDescription",
        defaultValue: dataAgencyDetail?.projectDescription ?? "<p></p>",
      },
      {
        name: "photoUrl",
        defaultValue: {
          file: { name: JSON.parse(dataAgencyDetail?.photoUrl ?? "[{}]")?.[0]?.url },
          src: JSON.parse(dataAgencyDetail?.photoUrl ?? "[{}]")?.[0]?.url,
        },
      },
      {
        name: "licenseFrontUrl",
        defaultValue: JSON.parse(dataAgencyDetail?.licenseUrl ?? "[]")?.length >= 1 ? { file: { name: JSON.parse(dataAgencyDetail?.licenseUrl ?? "[]")?.[0]?.url }, src: JSON.parse(dataAgencyDetail?.licenseUrl ?? "[]")?.[0]?.url } : null
      },
      {
        name: "licenseBackUrl",
        defaultValue: JSON.parse(dataAgencyDetail?.licenseUrl ?? "[]")?.length >= 2 ? { file: { name: JSON.parse(dataAgencyDetail?.licenseUrl ?? "[]")?.[1]?.url }, src: JSON.parse(dataAgencyDetail?.licenseUrl ?? "[]")?.[1]?.url } : null
      },
      {
        name: "mapDetail",
        defaultValue: dataAgencyDetail?.mapDetail?.rawAddress,
      },
      {
        name: "startStartupDate",
        defaultValue: dataAgencyDetail?.startStartupDate,
        isDisabled: true,
      },
    ],
    onSubmit: async (values) => {
      console.log("submit values :>>1 ", values);

      let payload = {
        projectName: values.projectName,
        minBalanceUserRequire: +values.minBalanceUserRequire,
        representativeName: values.representativeName,
        representativeEmail: values.representativeEmail,
        agencyId: +agencyIdParam,
        address: values?.address,
        kindOfBussiness: values?.kindOfBussiness,
        website: values?.website,
        totalAmountContribution: +values?.totalAmountContribution,
        numberOfShareholder: dataShareholder?.length,
        projectDescription: values?.projectDescription,
        photoUrl: imageArray,
        licenseUrl: [values?.licenseFrontUrl.src || "", values?.licenseBackUrl?.src || ""],
        mapDetail: { raw_address: values?.mapDetail },
        startStartupDate: values?.startStartupDate,
        phoneNumber: values?.phoneNumber,
        businessType: values?.kindOfBussiness,
        representativeMinAmount: +values?.minBalanceUserRequire
      };
      console.log("submit values :>>2 ", values);

      return AdminService.editAgency(payload)
        .then(() => {
          history.push(Routes.affiliatedAgent.path);
          CreateAlert({
            message: "edited successfully",
            type: "success",
          });
        })
        .catch((error: any) => {
          if (error.message.includes("EMAIL_SHAREHOLDERS_NOT_FOUND")) {
            CreateAlert({
              message: translate("{email} EMAIL_SHAREHOLDERS_NOT_FOUND", { email: error.message.split(" ")[0] }),
              type: "danger",
            });
            return;
          }
          CreateAlert({
            message: translate(error.message),
            type: "danger",
          });
        });
    },
  });

  const {
    handleSubmit: handleSubmit2,
    getInputProps: getInputProps2,
    isSubmitting: isSubmitting2,
  } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "email",
        validate: Yup.string()
          .required("Must be provided")
          .test("is-email", translate("INVALID_EMAIL"), (value: any) => {
            // Check if it's a valid email
            if (value && !validator.isEmail(value)) {
              return false;
            }

            // Custom regular expression for more rigorous email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(value)) {
              return false;
            }

            // List of valid domains
            const validDomains = [
              "gmail.com",
              "yahoo.com",
              "hotmail.com",
              "outlook.com",
              "aol.com",
              "icloud.com",
              "msn.com",
              "live.com",
              "protonmail.com",
              "zoho.com",
              "mail.com",
              "yandex.com",
            ];

            // Check if the email's domain is similar to any of the valid domains
            const domain = value?.split("@")[1];
            const isSimilarToValidDomain = validDomains.some((validDomain) => levenshtein(validDomain, domain) <= 2);

            // If the domain is similar to a valid domain but not exactly the same, fail validation
            if (isSimilarToValidDomain && !validDomains.includes(domain)) {
              return false;
            }

            return true;
          }),
        defaultValue: dataShareholderEdit?.email,
      },
      {
        name: "projectPercent",
        validate: Yup.string().required("Must be provided"),
        defaultValue: dataShareholderEdit?.percent,
      },
    ],
    onSubmit: async (values) => {},
  });

  let structure = [
    {
      name: "Shareholders Email",
      key: "email",
    },
    {
      name: "% Project",
      key: "percent",
    },
    {
      name: "Actions",
      render: (item: any) => (
        <div className={`action`}>
          <div className="action--edit" onClick={() => setDataShareholderEdit(item)}>
            <Icon.ActionEditIcon />
          </div>
          <div className="action--delete" onClick={() => setDataShareholder(dataShareholder.filter((i: any) => i.email !== item.email))}>
            <Icon.ActionDeleteIcon />
          </div>
        </div>
      ),
    },
  ];

  let handleOnClickCreateShareholders = () => {
    let existOne = dataShareholder.find((i: any) => i.email === getInputProps2("email").value);
    if (existOne) {
      CreateAlert({
        message: "Shareholders ID is exist.",
        type: "danger",
      });
      setIsShowShareholdersCreatePopup(false);
      return;
    }
    setDataShareholder([...dataShareholder, { email: getInputProps2("email").value, percent: +getInputProps2("projectPercent").value }]);
    getInputProps2("email").onChange("");
    getInputProps2("projectPercent").onChange("");
    setIsShowShareholdersCreatePopup(false);
  };

  let handleOnClickEditShareholders = (data: any) => {
    let existOne = dataShareholder.find((i: any) => i.email === getInputProps2("email").value);
    if (existOne && data.email !== getInputProps2("email").value) {
      CreateAlert({
        message: "Shareholders Email already exists in the list.",
        type: "danger",
      });
      setDataShareholderEdit(null);
      return;
    }
    setDataShareholder(
      dataShareholder.map((i: any) =>
        i.email === data.email ? { ...i, email: getInputProps2("email").value, percent: +getInputProps2("projectPercent").value } : i
      )
    );
    setDataShareholderEdit(null);
  };

  let handleOnClickDeleteImages = (element: any, idx: number) => {
      setImageArray((state: any) => {
          const newState = [...state];
          newState.splice(idx, 1);
          return newState;
      });
  }

  useEffect(() => {
    AdminService.getAgencyDetail(+agencyIdParam).then((res: any) => {
      setDataAgencyDetail(res?.result);
      setImageArray(JSON.parse(res?.result?.photoUrl ?? "[{}]")?.map((element: any) => element.url) ?? []);
      setDataShareholder(res?.result?.shareHolders);
    });
  }, [agencyIdParam]);

  if (!dataAgencyDetail)
    return (
      <div className="affiliated-agent-edit-page container-fluid">
        <div className="d-flex justify-content-center p40">
          <Icon.Loading />
        </div>
      </div>
    );

  return (
    <div className="affiliated-agent-edit-page container-fluid">
      <PageHead urlPath={Routes.startupList.path} title="Agency list" />
      <div className="affiliated-agent-edit-page__content">
        <div className="title">Edit Agency</div>
        <div className="form">
          <div className="row">
            <div className="col-12 input-container">
              <InputWraper label={"Project Name"} inputProps={getInputProps("projectName")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Total Capital Call"} inputProps={getInputProps("totalAmountContribution")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Min User Balance"} inputProps={getInputProps("minBalanceUserRequire")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Representative Name"} inputProps={getInputProps("representativeName")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Representative Email"} inputProps={getInputProps("representativeEmail")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Phone Number"} inputProps={getInputProps("phoneNumber")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Personal ID"} inputProps={getInputProps("personalId")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Website"} inputProps={getInputProps("website")} component={InputText} />
            </div>
            <div className="col-6 input-container">
              <InputWraper label={"Business Type"} inputProps={getInputProps("kindOfBussiness")} component={InputText} />
            </div>
            <div className="col-12 input-container">
              <InputWraper label={"Capital Call Date"} inputProps={getInputProps("startStartupDate")} component={InputDateTime} />
            </div>
            <div className="col-12 input-container">
              <InputWraper label={"Description"} inputProps={getInputProps("projectDescription")} component={EditorForm} />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-3 col-6">
                  <InputWraper
                    label={translate("Images")}
                    inputProps={getInputProps("photoUrl")}
                    renderInput={(props) => (
                      <InputImageSingle
                        {...props}
                        isShowImage={false}
                        onChange={(value: any) => {
                          setImageArray((state: any) => [...state, value.src]);
                        }}
                      />
                    )}
                  />
                </div>
                {imageArray.map((element: any, idx: number) => {
                  return (
                    <div key={idx} className="col-md-3 col-6 image__items">
                      <div className="image__items__delete-indicator" onClick={() => handleOnClickDeleteImages(element, idx)}><Icon.TrashIcon /></div>
                      <img
                        src={element ?? "/assets/images/no-image.jpg"}
                        alt=""
                        onError={(e: any) => {
                          e.target.src = "/assets/images/no-image.jpg";
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-6">
              <InputWraper label={"Front Of License"} inputProps={getInputProps("licenseFrontUrl")} component={InputImageSingle} />
            </div>
            <div className="col-6">
              <InputWraper label={"Back Of License"} inputProps={getInputProps("licenseBackUrl")} component={InputImageSingle} />
            </div>
            <div className="col-12">
              <InputWraper className="mb20" label={translate("Address")} inputProps={getInputProps("mapDetail")} component={InputText} />

              <iframe
                style={{ borderRadius: "8px" }}
                src={`https://www.google.com/maps?q=${encodeURIComponent(getInputProps("mapDetail").value)}&output=embed`}
                width={deviceType === "Desktop" ? "600px" : "100%"}
                height="450"
              ></iframe>
            </div>
          </div>
          {/* <div className="sub-title d-flex justify-content-between mt40">
                                <span>Shareholders</span>
                                <Button
                                    label="Add Shareholders"
                                    buttonType="success"
                                    icon={Icon.AddNewIcon}
                                    onClick={() => setIsShowShareholdersCreatePopup(true)}
                                />
                            </div>
                            <Table
                                enableReinitialize
                                hasOrderColumn
                                structure={structure}
                                data={{data: dataShareholder, count: dataShareholder.length}}
                            /> */}
          <div className="col-12 d-flex justify-content-center mt20">
            <Button label="Edit Agency" buttonType="success" onClick={handleSubmit} isLoading={isSubmitting} disabled={isSubmitting} />
          </div>
        </div>
      </div>
      {isShowShareholdersCreatePopup && (
        <PopupWraper center title="Add Shareholders" onClose={() => setIsShowShareholdersCreatePopup(false)}>
          <InputWraper label={"Shareholders Email"} inputProps={getInputProps2("email")} component={InputText} />
          <InputWraper label={"% Project"} inputProps={getInputProps2("projectPercent")} component={InputText} />
          <div className="d-flex justify-content-center">
            <Button
              label="Create Shareholders"
              buttonType="success"
              onClick={() => handleOnClickCreateShareholders()}
              isLoading={isSubmitting2}
              disabled={isSubmitting2}
            />
          </div>
        </PopupWraper>
      )}
      {dataShareholderEdit && (
        <PopupWraper center title="Edit Shareholders" onClose={() => setDataShareholderEdit(null)}>
          <InputWraper label={"Shareholders Email"} inputProps={getInputProps2("email")} component={InputText} />
          <InputWraper label={"% Project"} inputProps={getInputProps2("projectPercent")} component={InputText} />
          <div className="d-flex justify-content-center">
            <Button
              label="Edit Shareholders"
              buttonType="success"
              onClick={() => handleOnClickEditShareholders(dataShareholderEdit)}
              isLoading={isSubmitting2}
              disabled={isSubmitting2}
            />
          </div>
        </PopupWraper>
      )}
    </div>
  );
};
