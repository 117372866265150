import Axios from "axios";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getEnv } from "../../../src/configs";
import { translate } from "../../languages";
import { ClassNames, CreateAlert, IInputProps } from "../../modules";
import { ETypeApplication } from "../../types";
import { Icon } from "../icon";

export interface IInputPropsImage extends IInputProps {
  isShowImage?: boolean;
}

const maxFileSizeUpload = 50;

export const InputImageSingle: FC<IInputPropsImage> = ({ isShowImage = true, ...props }:any) => {
  const [data, setData] = useState(props?.defaultValue) as any;

  useEffect(() => {
    if(!!props.defaultValue?.src) {
      setData(props.defaultValue)
    }
  },[props.defaultValue?.src])
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const inputFile = acceptedFiles[0];

    if (acceptedFiles.find((file) => file.size > maxFileSizeUpload * 1024 * 1024))
      return CreateAlert({
        type: "danger",
        message: translate("File exceeds the allowed size of {limit}", {
          limit: `${maxFileSizeUpload} MB`,
        }),
      });

    if (inputFile) {
      const formData = new FormData();
      formData.append("file", inputFile);
      Axios.post(`${getEnv("URL_API_MAIN_STORAGE_SIDE")}/upload/single`, formData, {
        timeout: 50000,
        headers: { channelid: "gpc" },
      })
        .then(function (response) {
          const inputData = {
            file: inputFile,
            src: response.data.result.url,
          };
          setData(inputData);
          props.onChange(inputData);
          //props.onChangeEvent ? props.onChangeEvent(inputData) : null
        })
        .catch(function (response) {
          return CreateAlert({
            type: "danger",
            message: response,
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    // rootRef, // Ref to the `<div>`
    // inputRef // Ref to the `<input>`
  } = useDropzone({
    multiple: false,
    onDrop,
    accept: props.customTypeAccept
      ? props.customTypeAccept
      : ["image/jpeg", "image/png", "image/svg+xml"],
  });

  const isHasImage = isShowImage && !!data
  let typeFile1 = data?.file?.name?.slice(-4)?.toLowerCase();
  let typeFile2 = data?.file?.name?.slice(-3)?.toLowerCase();
  let isImage = false
  if (
    typeFile1 === ETypeApplication.PNG ||
    typeFile1 === ETypeApplication.JPEG ||
    typeFile1 === ETypeApplication.JPG ||
    typeFile2 === ETypeApplication.PNG ||
    typeFile2 === ETypeApplication.JPEG ||
    typeFile2 === ETypeApplication.JPG
  ) {
    isImage = true;
  }
  return (
    <div
      {...getRootProps()}
      className={ClassNames({
          InputImage: true,
          hasImage: !!isHasImage,
          hasFile: !isImage,
          isDragActive
      })}
    >
      {(isImage) && ((isShowImage && isHasImage) ? <img src={data.src} alt="" /> : null)}
      {!props.isDisabled ? <Fragment>
          {!isHasImage ? <div className="mesage">
              <Icon.Image />
              {(isShowImage && isDragActive) ? 'Drop the files here ' : translate('Drop the files here, or click to select files')}
          </div> : null}
          <input {...getInputProps()} />
      </Fragment> : null}
    </div>
  );
};


//
// import React, { FC, useState, useCallback, Fragment, useEffect } from 'react'
// import { useDropzone } from 'react-dropzone'
// import Axios from 'axios';
// import { getEnv } from '../../configs';
// import { translate } from '../../languages';
// import { IInputProps, CreateAlert, ClassNames } from '../../modules';
// import { ETypeApplication } from '../../types';
// import { Icon } from '../icon';

// const maxFileSizeUpload = 50;
// export interface IInputPropsImage extends IInputProps {
//   isShowImage?: boolean;
// }

// export const InputImageSingle: FC<IInputPropsImage> = ({ isShowImage = true, ...props }:any) => {
//     const [data, setData] = useState(props.defaultValue) as any

//     const onDrop = useCallback((acceptedFiles: File[]) => {
//         const inputFile = acceptedFiles[0];

//         if (acceptedFiles.find(file => file.size > maxFileSizeUpload * 1024 * 1024)) return CreateAlert({
//             type: "warning",
//             message: translate('File exceeds the allowed size of {limit}', { limit: `${maxFileSizeUpload} MB` })
//         });

//         if (inputFile) {
//             const formData = new FormData();
//             formData.append('file', inputFile)
//             const result = Axios.post(`${getEnv('URL_API_MAIN_STORAGE_SIDE')}/upload/single`, formData, {
//                 timeout: 50000,
//                 headers: { "channelid": getEnv('STORAGE_UPLOAD_CHANNELID'), "Access-Control-Allow-Origin": "*" }
//             }).then(function (response) {
//                 const inputData = {
//                     file: inputFile,
//                     src: response.data.result.url
//                 }
//                 setData(inputData)
//                 props.onChange(inputData)
//                 props.onChangeEvent ? props.onChangeEvent(inputData) : null
//             })
//                 .catch(function (response) {
//                     return CreateAlert({
//                         type: EAlertType.ERROR,
//                         message: response
//                     });

//                 });
//         }

//     }, [])

//     const {
//         getRootProps,
//         getInputProps,
//         isDragActive,
//         // rootRef, // Ref to the `<div>`
//         // inputRef // Ref to the `<input>`
//     } = useDropzone({
//         multiple: false,
//         onDrop,
//         accept: props.customTypeAccept ? props.customTypeAccept : ['image/jpeg', 'image/png', 'image/svg+xml'],
//     });

//     const isHasImage = isShowImage && !!data
//     let typeFile1 = data?.file?.name?.slice(-4)?.toLowerCase();
//     let typeFile2 = data?.file?.name?.slice(-3)?.toLowerCase();
//     let isImage = false
//     if (typeFile1 === ETypeApplication.PNG || typeFile1 === ETypeApplication.JPEG || typeFile1 === ETypeApplication.JPG || typeFile2 === ETypeApplication.PNG || typeFile2 === ETypeApplication.JPEG || typeFile2 === ETypeApplication.JPG) {
//         isImage = true
//     }

//     return (
//         <div
//             {...getRootProps()}
//             className={ClassNames({
//                 InputImage: true,
//                 hasImage: !!isHasImage,
//                 hasFile: !isImage,
//                 isDragActive
//             })}
//         >
//             {(isImage) && ((isShowImage && isHasImage) ? <img src={data.src} alt="" /> : null)}
//             {!props.isDisabled ? <Fragment>
//                 {!isHasImage ? <div className="mesage">
//                     <Icon.Image />
//                     {(isShowImage && isDragActive) ? 'Drop the files here ' : translate('Drop the files here, or click to select files')}
//                 </div> : null}
//                 <input {...getInputProps()} />
//             </Fragment> : null}
//         </div>
//     )
// }