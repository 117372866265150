import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Box, InputText } from "../../../components";
import { useForm, CreateAlert, InputWraper, Button, Icon } from "../../../modules";
import { EditorForm } from "../../../components/ckeditor-compnt";
import { Routes } from "../../../AppRoutes";
import { useHistory } from "react-router-dom";
import { ReasonForm } from "../components/reason";
import { PageHead } from "../../../components/page-head";
import { ProductManagementService } from "../../../services/product-management/productManagement.service";
import moment from "moment";
import { ConfirmPopup } from "../components";

export interface IUserWraperProps extends RouteComponentProps {}

export const ProductDetail: FC = () => {
  const router: any = useRouteMatch();
  const navigate = useHistory();
  const [productId, setProductId] = useState(router.params.id);
  const [description, setDescription] = useState<any>("<p></p>");
  const [productStatus, setProductStatus] = useState<any>("");
  const [openReasonForm, setOpenReasonForm] = useState(false);
  const [openConfirmApprovePopup, setOpenConfirmApprovePopup] = useState(false);
  
  const [dataProductDetail, setDataProductDetail] = useState<any>();

  // const deviceType = useDeviceType();
  const onApprove = () => {
    ProductManagementService.changeProductStatus({ productId, status: "APPROVED" }).then(() => {
      CreateAlert({ message: "Approve product successfully", type: "success" });
      navigate.push(Routes.ApprovedProducts.path);
    });
  };

  const { getInputProps, handleSubmit, isSubmitting } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "productName",
        defaultValue: dataProductDetail?.productName || "--",
        isDisabled: true,
      },
      {
        name: "productType",
        defaultValue: dataProductDetail?.productType || "--",
        isDisabled: true,
      },
      {
        name: "agencyName",
        defaultValue: dataProductDetail?.agencyName || "--",
        isDisabled: true,
      },
      {
        name: "created",
        defaultValue: dataProductDetail?.created ? moment(dataProductDetail?.created).format('HH:mm, ddd DD/MM/YYYY') : "--",
        isDisabled: true,
      },
      {
        name: "historyDate",
        defaultValue: !!dataProductDetail?.historyDate ? moment(dataProductDetail?.historyDate ).format('HH:mm, ddd DD/MM/YYYY') : "--",
        isDisabled: true,
      },
      {
        name: "price",
        defaultValue: dataProductDetail?.details[0]?.price || 0,
        isDisabled: true,
      },
      {
        name: "originalPrice",
        defaultValue: dataProductDetail?.details[0]?.originalPrice || 0,
        isDisabled: true,
      },
      {
        name: "address",
        defaultValue: dataProductDetail?.address || "--",
        isDisabled: true,
      },
      {
        name: "description",
        defaultValue: description,
        isDisabled: true,
      },
      {
        name: "reasonReject",
        defaultValue: dataProductDetail?.histories[dataProductDetail?.histories?.length-1]?.reason || "",
        isDisabled: true,
      },
    ],
    onSubmit: async (values) => {
     
    },
  });

  useEffect(() => {
    ProductManagementService.getProductDetail(Number(productId)).then((res: any) => {
      const filteredHistory = res.histories?.filter((item: any) => item.status === res.status);
      setDataProductDetail({
        ...res,
        historyDate: filteredHistory.length > 0 ? filteredHistory[filteredHistory.length-1]?.created : ""
      });
      setDescription(!!res.description ? res.description  : "<p><p/>");
      setProductStatus(res.status);
      // setImageArray(JSON.parse(res?.data?.[0]?.photoUrl ?? "[{}]")?.map((element: any) => element.url) ?? []);
    });
    // eslint-disable-next-line
  }, []);

  if (!dataProductDetail)
    return (
      <div className="affiliated-agent-edit-page container-fluid">
        <div className="d-flex justify-content-center p40">
          <Icon.Loading />
        </div>
      </div>
    );

  return (
    <div className="ProductDetail">
      <PageHead
        urlPath={
          !!dataProductDetail?.isDelete ?
          Routes.DeletedProducts.path :
          productStatus === "PENDING" ?
          Routes.WaitingApprovalProducts.path :
          productStatus === "REJECTED" ?
          Routes.RejectedProducts.path :
          Routes.ApprovedProducts.path
        }
        title="Product list" 
      />
      <div className="product-detail">
        <Box title={"Product Detail"}>
          <div className="d-flex flex-column">
            <div className="d-flex col-12">
              <div className="col-6 pd0">
                <InputWraper label={"Product Name"} inputProps={getInputProps("productName")} component={InputText} />
                <InputWraper label={"Product Type"} inputProps={getInputProps("productType")} component={InputText} />
                <InputWraper label={"Agency"} inputProps={getInputProps("agencyName")} component={InputText} />
                <InputWraper label={"Date Created"} inputProps={getInputProps("created")} component={InputText} />
                <InputWraper 
                  className={productStatus === "PENDING" ? "d-none" : ""} 
                  label={`Date ${productStatus === "REJECTED" ? "Rejected" : "Apporved" }`} inputProps={getInputProps("historyDate")} 
                  component={InputText} 
                />
                <InputWraper label={"Cost Price"} inputProps={getInputProps("originalPrice")} component={InputText} />
                <InputWraper label={"Price"} inputProps={getInputProps("price")} component={InputText} />
              </div>
              
              <div className="col-6 product-img">
                <img src={`${dataProductDetail.thumbnailUrl || ""}`} alt="" />
              </div>
            </div>
            
            <div className="col-12">
             
              <InputWraper className={productStatus==="REJECTED" ? "" : "d-none"} label={"Reason to reject"} inputProps={getInputProps("reasonReject")} component={InputText} />
              <InputWraper label={"Address"} inputProps={getInputProps("address")} component={InputText} />
              <InputWraper
                label={"Description"}
                inputProps={getInputProps("description")}
                // component={EditorForm}
                renderInput={(state) => (
                  <EditorForm
                    // {...state}
                    value={description}
                    onChange={(e: any) => {
                      state.onChange(e);
                      setDescription(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="col-12 d-flex justify-content-center">
              {(productStatus ==="PENDING" && !dataProductDetail?.isDelete) && (
                <div className="col-6 d-flex justify-content-between">
                  <Button label={"Reject"} onClick={() => setOpenReasonForm(true)} buttonType="grey" />
                  <Button label={"Approve"} onClick={()=>setOpenConfirmApprovePopup(true)} isLoading={isSubmitting} disabled={isSubmitting} />
                </div>
              )}
            </div>
          </div>
        </Box>
      </div>
      {openReasonForm && (
        <ReasonForm
          onClose={() => {
            setOpenReasonForm(false);
          }}
          payload={{ productId: productId, status: "REJECTED" }}
        />
      )}
      {openConfirmApprovePopup && (
        <ConfirmPopup
          onClose={() => {
            setOpenConfirmApprovePopup(false);
          }}
          message="Do you want to approve this product?"
          onConfirm={onApprove}
        />
      )}
    </div>
  );
};
