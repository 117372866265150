import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import XLSX from "xlsx";
import { GeneralPopupCompnt } from "../../../../components/general-popup-compnt";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../../components/table-filter-inputs/select";
import { InputToggleSwitch } from "../../../../components/toggle-switch";
import { translate } from "../../../../languages";
import { Button, CreateAlert, Icon, Table } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import { GameManagementLayout } from "../../components";

export const GameListPage: FC = GameManagementLayout((props: any) => {
  const ActiveOptions = [
    { label: "ALL", value: "ALL" },
    { label: "ACTIVE", value: "true" },
    { label: "INACTIVE", value: "false" },
  ];

  const [currentData, setCurrentData] = useState(null as any);
  const [isShowTable, setIsShowTable] = useState<boolean>(true);
  const [params, setParams] = useState<any>();
  const [isShowPopupSwap, setIsShowPopupSwap] = useState<boolean>(false);
  const [dataItemSwap, setDataItemSwap] = useState<any>();
  const [isShowPopupActive, setIsShowPopupActive] = useState<boolean>(false);
  const [dataItemActive, setDataItemActive] = useState<any>();
  const [dataSupplier, setDataSupplier] = useState<any>();
  const [dataGameCategory, setDataGameCategory] = useState<any>();
  // const [forceUpdateTable, setForceUpdateTable] = useState<boolean>();
  const history = useHistory();

  // const startOfMonth = moment().subtract(1, "months").startOf("month");
  // const endOfDay = moment().endOf("day");

  useEffect(() => {
    (async () => {
      setIsShowTable(false);
      await AdminService.getSupplierList().then((res) => {
        setDataSupplier(res?.result);
      });
      await AdminService.getCategoryList().then((res) => {
        setDataGameCategory(res?.result);
      });
      setIsShowTable(true);
    })();
  }, []);

  const handleOnClickAction = (item: any, type: any) => {
    if (item?.isActive === false) return;
    setDataItemSwap({ item, type });
    setIsShowPopupSwap(true);
  };

  const handleOnClickConfirmSwap = () => {
    AdminService.SwapGame(dataItemSwap?.item?.gameId, dataItemSwap?.type)
      .then((res) => {
        // setForceUpdateTable((state) => !state);
        CreateAlert({ message: "Swap game successfully.", type: "success" });
      })
      .catch((err) => {
        CreateAlert({ message: err?.message, type: "danger" });
      })
      .finally(() => {
        setIsShowPopupSwap(false);
      });
  };

  const handleOnClickSwitchActive = (item: any, value: any) => {
    setDataItemActive({ item, value });
    setIsShowPopupActive(true);
  };

  const handleOnClickConfirmActive = () => {
    AdminService.ActiveGame(dataItemActive?.item?.gameId, dataItemActive?.value)
      .then((res) => {
        // setForceUpdateTable((state) => !state);
        CreateAlert({ message: `successfully.`, type: "success" });
      })
      .catch((err) => {
        CreateAlert({ message: err?.message, type: "danger" });
      })
      .finally(() => {
        setIsShowPopupActive(false);
      });
  };

  return (
    <div className="game-page">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="primary"
          className="mb15"
          onClick={async () => {
            // const response = await AdminService.getListRequestClaim({
            //     numberOfTransactionsPerPage: 10000,
            //     page: 1,
            //     fromDate: startOfMonth, //'0',
            //     toDate: endOfDay //moment().toISOString()
            // });
            const response = await AdminService.getGameList({
              ...params,
              pageNumber: 1,
              limit: 100000,
            });
            const data = [
              ["Game", "Supplier", "Game Category", "Table Name", "Created Date", "Active"],
              ...response.data.map((item: any) => {
                let row: any[] = [];
                row.push(item?.gameName ?? item?.tableName);
                row.push(
                  dataSupplier?.find((element: any) => element?.supplierId === item?.supplierId)
                    ?.name
                );
                row.push(
                  dataGameCategory?.find((element: any) => element?.categoryId === item?.categoryId)
                    ?.name
                );
                row.push(item?.tableName);
                row.push(moment(item?.created).format("L LTS"));
                row.push(item?.isActive ? "active" : "inactive");
                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Game-List-${now.toLocaleDateString().replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="button-group">
          <Button
            label="Add Game"
            buttonType="success"
            icon={Icon.AddNewIcon}
            onClick={() => history.push("/game-management/game/create")}
          />
        </div>
      </div>

      {isShowTable && (
        <Table
          // enableReinitialize
          hasOrderColumn
          className="overload"
          filters={[
            {
              name: "Search",
              key: "searchString",
              input: TableFilterInputText,
            },
            {
              name: translate("time"),
              key: "created",
              input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
              // defaultValue: {
              //   //fromDate: moment().subtract(1,"months"),
              //   //toDate: moment(),
              //   fromDate: startOfMonth,
              //   toDate: endOfDay,
              // },
            },
            {
              name: "Activve",
              key: "isActive",
              input: (e) => (
                <TableFilterInputSelect isClearable={false} {...e} options={ActiveOptions} />
              ),
              defaultValue: "ALL",
            },
          ]}
          structure={[
            {
              name: "Game",
              key: "gameName",
              render: (item) => (
                <div className={`game ${!item?.isActive && "inactive"}`}>
                  <img
                    className="game__image"
                    src={item?.thumnail ?? "/assets/images/no-image.jpg"}
                    alt=""
                    onClick={() => {
                      if (item?.isActive === false) return;
                      window.open(item?.thumnail, "_blank");
                    }}
                  />
                  <div className="game__name">{item?.gameName ?? item?.tableName ?? "unknown"}</div>
                </div>
              ),
            },
            {
              name: "Supplier",
              key: "supplierId",
              render: (item) => (
                <div className={`supplier ${!item?.isActive && "inactive"}`}>
                  {item?.supplierId
                    ? dataSupplier?.find((element: any) => element?.supplierId === item?.supplierId)
                        ?.name
                    : "unknown"}
                </div>
              ),
            },
            {
              name: "Game Category",
              key: "categoryId",
              render: (item) => (
                <div className={`game-category ${!item?.isActive && "inactive"}`}>
                  {item?.categoryId
                    ? dataGameCategory?.find(
                        (element: any) => element?.categoryId === item?.categoryId
                      )?.name
                    : "unknown"}
                </div>
              ),
            },
            {
              name: "Table Name",
              key: "tableName",
              render: (item) => (
                <div className={`table-name ${!item?.isActive && "inactive"}`}>
                  {item?.tableName ?? "unknown"}
                </div>
              ),
            },
            {
              name: "Created Date",
              key: "created",
              render: (item) => (
                <div className={`created-date ${!item?.isActive && "inactive"}`}>
                  {moment(item?.created).format("L LTS")}
                </div>
              ),
            },
            {
              name: "Action",
              render: (item) => (
                <div className={`action ${!item?.isActive && "inactive"}`}>
                  <div className="action--up" onClick={() => handleOnClickAction(item, "UP")}>
                    <Icon.SwapUpIcon />
                  </div>
                  <div className="action--down" onClick={() => handleOnClickAction(item, "DOWN")}>
                    <Icon.SwapDownIcon />
                  </div>
                </div>
              ),
            },
            {
              name: "Edit",
              render: (item) => (
                <div
                  className={`edit`}
                  onClick={() => history.push(`/game-management/game/edit/${item?.gameId}`)}
                >
                  <Icon.ActionEditIcon />
                </div>
              ),
            },
            {
              name: "Active",
              render: (item) => (
                <div className="active">
                  <InputToggleSwitch
                    onChange={async (value) => {
                      handleOnClickSwitchActive(item, value);
                    }}
                    value={item?.isActive}
                    onTouched={() => false}
                    label=""
                    name=""
                  />
                </div>
              ),
            },
          ]}
          fetchData={async (state) => {
            let params = { ...state };
            setParams(params);

            if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
            if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

            return AdminService.getGameList({
              pageNumber: params.pageNumber,
              limit: params.limit,
              ...params,
            }).then((res: any) => {
              setCurrentData(res);
              return res;
            });
          }}
          // forceUpdateTable={forceUpdateTable}
        />
      )}

      {isShowPopupSwap ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupSwap(false);
          }}
          onClickConfirm={() => handleOnClickConfirmSwap()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={"Are you sure you want to swap?"}
        />
      ) : null}

      {isShowPopupActive ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupActive(false);
          }}
          onClickConfirm={() => handleOnClickConfirmActive()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={`Are you sure you want to ${
            dataItemActive?.value === true ? "active" : "deactivate"
          }?`}
        />
      ) : null}
    </div>
  );
});
