import React, { Fragment, memo, useState } from 'react';
import { withUserWraper } from '../../wraper';
import {
  Table,
  Button,
  Icon,
  CreateAlert
} from '../../../../modules';
import { AdminService } from '../../../../services/admin';
import { onImageGallery } from '../../../../components/image-gallery';
import _ from 'lodash';
import { translate } from '../../../../languages';
import {
  TableFilterInputText,
} from '../../../../components/table-filter-inputs';
import { TableFilterInputSelect } from '../../../../components/table-filter-inputs/select';
import { PopupWraper } from '../../../../components/popup';
import PopupDetailKYC from './popupDetailKYC';
import PopupDetailKYCRejected from './popupKYCRejected';
import XLSX from 'xlsx';


export const PageUserKYCList = withUserWraper(
  memo(() => {
    let defaultImage = '/assets/images/logo-symbol-white1.png';
    let StatusFilterOptions = [
      { label: translate('PENDING'), value: 'PENDING' },
      { label: translate('REJECTED'), value: 'REJECTED' },
      { label: translate('APPROVED'), value: 'APPROVED' }
    ];
    const [disableExport, setDisableExport] = useState<boolean>(true);
    const [dataDetailKYC, setDataDetailKYC] = useState<any>(null);
    const [isShowPopupVerify, setIsShowPopupVerify] = useState<boolean>(false);
    const [isShowPopupConfirm, setIsShowPopupConfirm] =
      useState<boolean>(false);
    const [isShowPopupKYCRejected, setIsShowPopupKYCRejected] =
      useState<boolean>(false);

    const [typeAction, setTypeAction] = useState<string>('');
    const [paramsExport, setParamsExport] = useState<any>({});
    const [reason, setReason] = useState<string>('');

    const structure = [
      {
        name: translate('Email'),
        key: 'email',
        className: 'email'
      },
      {
        name: translate('display-name-title'),
        key: 'firstName'
      },
      {
        name: translate('status'),
        key: 'status',
        render: (item: any) => (
          <span
            className={`status status--${item?.status?.toLowerCase()}`}
          >
            {_.startCase(_.toUpper(translate(item?.status)))}
          </span>
        )
      },
      // {
      //   name: 'Last Name',
      //   key: 'lastName'
      // },
      {
        name: translate('face-photo'),
        key:"avatarFileId",
        render: (item: any) => {
          const dataImage = [
            { src: item.avatarFileId || defaultImage },
            { src: item.backOfCardFileId || defaultImage },
            { src: item.frontOfCardFileId || defaultImage }
          ].map((v) => ({ ...v, thumbnail: v.src }));
          return (
            <img
              onClick={() => onImageGallery(dataImage, 0)}
              src={item.avatarFileId || defaultImage}
              style={{
                cursor: 'pointer',
                height: '100px',
                maxWidth: '150px',
                objectFit: 'contain'
              }}
              alt=""
            />
          );
        }
      },
      {
        name: translate('back-of-card'),
        key: "backOfCardFileId",
        render: (item: any) => {
          const dataImage = [
            { src: item.avatarFileId || defaultImage },
            { src: item.backOfCardFileId || defaultImage },
            { src: item.frontOfCardFileId || defaultImage }
          ].map((v) => ({ ...v, thumbnail: v.src }));
          return (
            <img
              onClick={() => onImageGallery(dataImage, 1)}
              src={item.backOfCardFileId || defaultImage}
              style={{
                cursor: 'pointer',
                height: '100px',
                maxWidth: '150px',
                objectFit: 'contain'
              }}
              alt=""
            />
          );
        }
      },
      {
        name: translate('front-of-card'),
        key: "frontOfCardFileId",
        render: (item: any) => {
          const dataImage = [
            { src: item.avatarFileId || defaultImage },
            { src: item.backOfCardFileId || defaultImage },
            { src: item.frontOfCardFileId || defaultImage }
          ].map((v) => ({ ...v, thumbnail: v.src }));
          return (
            <img
              onClick={() => onImageGallery(dataImage, 2)}
              src={item.frontOfCardFileId || defaultImage}
              style={{
                cursor: 'pointer',
                height: '100px',
                maxWidth: '150px',
                objectFit: 'contain'
              }}
              alt=""
            />
          );
        }
      },
      //   {
      //     name: 'Actions',
      //     render: (item, fetchData) => {
      //       return (
      //         <Fragment>
      //           <Button
      //             className="mr5"
      //             type="button"
      //             buttonType="success-outline"
      //             label="Approve"
      //             onClick={async () =>
      //               AdminService.kycApprove(item.kycId)
      //                 .then(() => fetchData())
      //                 .catch(onError)
      //             }
      //             style={{ minWidth: '9rem' }}
      //           />

      //           <Button
      //             type="button"
      //             buttonType="danger-outline"
      //             label="Reject"
      //             onClick={async () =>
      //               AdminService.kycReject(item.kycId)
      //                 .then(() => fetchData())
      //                 .catch(onError)
      //             }
      //             style={{ minWidth: '9rem', marginTop: '1rem' }}
      //           />
      //         </Fragment>
      //       );
      //     }
      //   }
      {
        name: translate('actions'),
        render: (item: any, fetchData: any) => {
          return (
            <>
              {item?.status === 'PENDING' ? (
                <div
                  className="action"
                  onClick={() => {
                    showDetailKYC(item);
                  }}
                >
                  <div className="action__edit">
                    <div style={{ color: 'white' }}>
                      <Icon.ActionEditIcon />
                    </div>
                  </div>
                </div>
              ) : item?.status === 'REJECTED' ? (
                <div
                  className="action"
                  onClick={() => {
                    handleShowKYCRejected(item);
                  }}
                >
                  <div className="action__edit">
                    <div style={{ color: 'white' }}>
                      <Icon.View />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          );
        }
      }
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getKYCList({
            page: 1,
            pageSize: 10000,
            ...paramsExport
          });

          const data = response.data;

          let fileHead: any = structure.map((v) => v.name);
          fileHead.pop(); //remove last column on table
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                // if (column.key === 'created')
                //   return moment(item[column.key]).format('L HH:mm:ss');
                if (column.key === 'status') return _.startCase(_.toUpper(translate(item?.status)));
                if(column.key === "avatarFileId") return item.avatarFileId || "";
                if(column.key === "backOfCardFileId") return item.backOfCardFileId || "";
                if(column.key === "frontOfCardFileId") return item.frontOfCardFileId || "";
                if (!column.key) return '';
                return item[column.key];
              })
            )
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

          const now = new Date();
          XLSX.writeFile(
            wb,
            `KYC List ${now.toLocaleDateString().replace(/\//g, '-')} ${now
              .toLocaleTimeString()
              .replace(/:/g, '-')}.xlsx`,
            { type: 'binary' }
          );

          resolve(
            CreateAlert({
              type: 'success',
              message: 'Export data success.'
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: 'danger', message: error.message }));
        }
      });
    };

    const mapDataDetail = (data: any) => {
      let obj: any = {};
      obj.kycId = data?.kycId;
      obj.email = data?.email;
      obj.displayName = data?.firstName;
      obj.avatarFileId = data?.avatarFileId;
      obj.backOfCardFileId = data?.backOfCardFileId;
      obj.frontOfCardFileId = data?.frontOfCardFileId;
      obj.details = data?.details;
      obj.status = data?.status;
      obj.dataImage = [
        { src: data?.avatarFileId },
        { src: data?.frontOfCardFileIdbackOfCardFileId },
        { src: data?.backOfCardFileId }
      ].map((v) => ({ ...v, thumbnail: v.src }));
      return obj;
    };
    const showDetailKYC = (data: any) => {
      setDataDetailKYC({ ...mapDataDetail(data) });
      setIsShowPopupVerify(true);
    };
    const hideDetailKYC = () => {
      setIsShowPopupVerify(false);
    };

    const handleShowPopupConfirmVerify = (
      typeAction: string = '',
      reason: string = ''
    ) => {
      setTypeAction(typeAction);
      setIsShowPopupConfirm(true);
      setReason(reason);
    };
    const handleSubmitVerify = async () => {
      let kycId = dataDetailKYC?.kycId;
      let _reason = reason;
      if (typeAction === 'approve') {
        AdminService.kycApprove(kycId, _reason)
          .then(async (res) => {
            setIsShowPopupConfirm(false);
            setIsShowPopupVerify(false);

            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return CreateAlert({
              type: 'success',
              message: translate('verify-kyc-successfull')
            });
          })
          .catch((error) => {
            return CreateAlert({
              type: 'danger',
              message: error?.message || 'Error'
            });
          });
      } else {
        AdminService.kycReject(kycId, reason)
          .then(async (res) => {
            setIsShowPopupConfirm(false);
            setIsShowPopupVerify(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return CreateAlert({
              type: 'success',
              message: translate('verify-kyc-successfull')
            });
          })
          .catch((error) => {
            return CreateAlert({
              type: 'danger',
              message: error?.message || 'Error'
            });
          });
      }
    };

    const handleShowKYCRejected = (data: any) => {
      setDataDetailKYC({ ...mapDataDetail(data) });
      setIsShowPopupKYCRejected(true);
    };
    const handleHideKYCRejected = () => {
      setIsShowPopupKYCRejected(false);
    };

    return (
      <Fragment>
        <div className="page-user-kyc-list">
          <Button
            className="mb20"
            label="Export to Excel"
            buttonType="primary"
            disabled={disableExport}
            onClick={handleExportExcel}
          />
          <Table
            hasOrderColumn
            filters={[
              {
                name: translate('search'),
                key: 'searchString',
                input: TableFilterInputText
              },
              {
                name: translate('status'),
                key: 'status',
                input: (e) => (
                  <div className="input-select-filter-status">
                    <TableFilterInputSelect
                      isClearable={true}
                      {...e}
                      options={StatusFilterOptions}
                      isSearchable={false}
                    />
                  </div>
                ),
                defaultValue: 'PENDING'
              }
            ]}
            structure={structure}
            fetchData={async (state) => {
              let params = { ...state };
              if (params['fromDate'])
                params['fromDate'] = new Date(params['fromDate']);
              if (params['toDate'])
                params['toDate'] = new Date(params['toDate']);
              setParamsExport({ ...params });

              return AdminService.getKYCList({
                page: params.pageNumber,
                pageSize: params.limit,
                ...params
              }).then((res) => {
                setDisableExport(!(res?.data?.length > 0))
                return res
              });
            }}
          />
        </div>

        <PopupDetailKYC
          hideDetailKYC={hideDetailKYC}
          handleShowPopupConfirmVerify={handleShowPopupConfirmVerify}
          isShow={isShowPopupVerify}
          dataDetailKYC={dataDetailKYC}
        />

        <PopupDetailKYCRejected
          hideDetailKYC={handleHideKYCRejected}
          isShow={isShowPopupKYCRejected}
          dataDetailKYC={dataDetailKYC}
        />

        {isShowPopupConfirm ? (
          <PopupWraper
            title={translate('confirm-update')}
            center
            onClose={() => setIsShowPopupConfirm(false)}
          >
            <p>
              {typeAction === 'approve'
                ? translate('are-you-sure-to-approve')
                : translate('are-you-sure-to-reject')}
            </p>
            <div className="btn-action-wrapper">
              <Button
                className=""
                label={translate('yes-btn')}
                buttonType="primary"
                onClick={() => handleSubmitVerify()}
              />

              <Button
                className=""
                label={translate('no-btn')}
                buttonType="grey"
                onClick={() => setIsShowPopupConfirm(false)}
              />
            </div>
          </PopupWraper>
        ) : (
          <></>
        )}
      </Fragment>
    );
  })
);
