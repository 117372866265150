import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert, Icon } from "../../../modules";
import { InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";

export const VendingMachineConfigPage = withConfigWraper(() => {
    const [dailyWithdrawalVendingMachine, setDailyWithdrawalVendingMachine] = useState<any>();
    const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
    const [dataPopup, setDataPopup] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleSubmit, getInputProps } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "dailyWithdrawalVendingMachine",
                defaultValue: dailyWithdrawalVendingMachine ?? 'N/A',
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .min(0, translate("must be greater than or equal to 0")),
                isDisabled: isDisableInput1,
            },
        ],
        onSubmit: async (values) => {
            let payload = {
                dailyWithdrawalVendingMachine: +values?.dailyWithdrawalVendingMachine,
            };
            setDataPopup(payload);
        },
    });

    let handleOnClickConfirmPopup = () => {
        if (isLoading === true) return;
        setIsDisableInput1(true);
        setIsLoading(true);

        const payload = {
            isActiveScan: true,
            shouldProcessWithdraw: false,
            value: JSON.stringify({DAILY_VENDING_MACHINE_WITHDRAWAL_LIMIT: +dataPopup?.dailyWithdrawalVendingMachine}),
            serverConfigName: "VENDING_MACHINE_CONFIG",
        };

        return ConfigService.setConfig(payload).then(async () => {
                ConfigService.getServerConfigByName('VENDING_MACHINE_CONFIG').then((res) => {
                    setDailyWithdrawalVendingMachine(res?.DAILY_VENDING_MACHINE_WITHDRAWAL_LIMIT);
                });
                // CreateAlert({
                //     type: 'success',
                //     message: 'Edit Total MEC Release Config Successful'
                // });
            }).catch((err) => {
                CreateAlert({
                    type: 'danger',
                    message: translate(err?.message)
                });
            }).finally(() => {
                setDataPopup(null);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        ConfigService.getServerConfigByName('VENDING_MACHINE_CONFIG').then((res) => {
            setDailyWithdrawalVendingMachine(res?.DAILY_VENDING_MACHINE_WITHDRAWAL_LIMIT);
        });
    }, []);

    return (
        <div className="row mb20 vending-machine-config-page">
            <div className="col-sm-5">
                <form
                    className="blockLists"
                    onSubmit={handleSubmit}
                >
                    <InputWraper
                        label={"Daily Vending Machine Withdrawal Limit"}
                        inputProps={getInputProps("dailyWithdrawalVendingMachine")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput1((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <div>
                        <Button
                            label="Submit"
                            isMiddle
                            buttonType="success"
                            type="submit"
                            disabled={dailyWithdrawalVendingMachine == null || isDisableInput1 || dataPopup != null || ((dailyWithdrawalVendingMachine === +getInputProps('dailyWithdrawalVendingMachine').value))}
                        />
                    </div>
                </form>
            </div>
            {dataPopup ? (
                <GeneralPopupCompnt
                    onClose={() => {
                        setDataPopup(null);
                    }}
                    onClickConfirm={() => handleOnClickConfirmPopup()}
                    textButton="OK"
                    titlePopup={"WARNING!"}
                    messagePopup={[
                        <>
                            {(dailyWithdrawalVendingMachine != dataPopup?.dailyWithdrawalVendingMachine) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">Daily Vending Machine Withdrawal Limit:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{dailyWithdrawalVendingMachine}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.dailyWithdrawalVendingMachine}</span></div>
                                    </div>
                                </div>
                            </>}
                            {(dailyWithdrawalVendingMachine == dataPopup?.dailyWithdrawalVendingMachine) && "No change"}
                        </>,
                    ]}
                />
            ) : null}
        </div>
    );
});
