import moment from "moment";
import React, { FC, useState } from "react";
import XLSX from "xlsx";
import {
  TableFilterInputText,
  TableFilterRangeTimeInput,
} from "../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";
import { translate } from "../../languages";
import { Button, Icon, Table } from "../../modules";
import { AdminService } from "../../services/admin";

export const PageExperienceGameList: FC = (props: any) => {
  const StatusFilterOptions = [
    { label: "ALL", value: "ALL" },
    { label: "AVAILABLE", value: "AVAILABLE" },
    { label: "EXPIRED", value: "EXPIRED" },
  ];

  const [currentData, setCurrentData] = useState(null as any);
  const [totalUser, setTotalUser] = useState<any>();
  const [totalPackage, setTotalPackage] = useState<any>();
  const [params, setParams] = useState<any>();

  const startOfMonth = moment().subtract(1, "months").startOf("month");
  const endOfDay = moment().endOf("day");

  return (
    <div className="page-experience-game-list">
      <div className="button-container">
        <Button
          disabled={!currentData}
          label="Export to Excel"
          buttonType="primary"
          className="mb15"
          onClick={async () => {
            const response = await AdminService.getListExperienceGame({
              ...params,
              page: 1,
              numberOfTransactionsPerPage: 100000,
            });
            const data = [
              ["Email", "Time shift", "Register time", "Experience pack", "Expire time", "Status"],
              ...response.data.map((item: any) => {
                let row: any[] = [];
                row.push(item.email);
                row.push((item?.regTime ?? "--") + ":00");
                row.push(moment(item?.created).format("YYYY/MM/DD HH:mm"));
                row.push("$" + new Intl.NumberFormat("en").format(item?.packageValue));
                row.push(moment(item?.exDate).format("YYYY/MM/DD HH:mm"));
                row.push(translate(item.status));

                return row;
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(
              wb,
              `Experience-Game-${now.toLocaleDateString().replace(/\//g, "-")} ${now
                .toLocaleTimeString()
                .replace(/:/g, "-")}.xlsx`,
              { type: "binary" }
            );
          }}
        />
        <div className="infor-container">
          <div className="user-participation">
            <div className="user-participation__icon">
              <Icon.UserParticipationIcon />
            </div>
            <div className="user-participation__content">
              {new Intl.NumberFormat("en").format(totalUser ?? 0)}
              <span className="sub-text">&nbsp;{`REGISTERED USER${totalUser > 1 ? "S" : ""}`}</span>
            </div>
          </div>
          <div className="total-package">
            <div className="total-package__icon">
              <Icon.TotalPackageIcon />
            </div>
            <div className="total-package__content">
              ${new Intl.NumberFormat("en").format(totalPackage ?? 0)}
              <span className="sub-text">&nbsp;TOTAL EXPERIENCE PACK</span>
            </div>
          </div>
        </div>
      </div>

      <Table
        hasOrderColumn
        className="overload"
        filters={[
          {
            name: "Email",
            key: "email",
            input: TableFilterInputText,
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              //fromDate: moment().subtract(1,"months"),
              //toDate: moment(),
              fromDate: startOfMonth,
              toDate: endOfDay,
            },
          },
          {
            name: "Status",
            key: "status",
            input: (e) => (
              <TableFilterInputSelect isClearable={false} {...e} options={StatusFilterOptions} />
            ),
            defaultValue: "ALL",
          },
        ]}
        structure={[
          {
            name: "Email",
            key: "email",
            className: "email",
          },
          {
            name: translate("Time shift"),
            key: "regTime",
            render: (item: any) => (
              <span className={`time-shift time-shift--${item?.regTime}`}>
                {item?.regTime + ":00"}
              </span>
            ),
          },
          {
            name: translate("Register time"),
            key: "created",
            render: (item: any) => moment(item?.created).format("YYYY/MM/DD HH:mm"),
          },
          {
            name: translate("Experience pack"),
            key: "packageValue",
            render: (item: any) => (
              <span className={`experience-pack experience-pack--${item?.packageValue}`}>
                {"$" + new Intl.NumberFormat("en").format(item?.packageValue)}
              </span>
            ),
          },
          {
            name: translate("Expire time"),
            key: "exDate",
            render: (item: any) => moment(item?.exDate).format("YYYY/MM/DD HH:mm"),
          },
          {
            name: translate("Status"),
            key: "status",
            render: (item: any) => (
              <span className={`status status--${item?.status?.toLowerCase()}`}>
                {translate(item.status)}
              </span>
            ),
          },
        ]}
        fetchData={async (state) => {
          let params = { ...state };
          setParams(params);
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);

          return AdminService.getListExperienceGame({
            page: params.pageNumber,
            numberOfTransactionsPerPage: params.limit,
            ...params,
          }).then((res) => {
            setCurrentData(res);
            setTotalUser(res?.totalUser);
            setTotalPackage(res?.totalPackage);
            return res;
          });
        }}
      />
    </div>
  );
};
