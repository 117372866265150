import React, { FC, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import { InputText } from "../../../../components";
import { InputImageSingle } from "../../../../components/single-image";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import { GameManagementLayout } from "../../components";
import "./index.scss";

export const SupplierEditPage: FC<any> = GameManagementLayout((props: any) => {
  const { params }: any = useRouteMatch();
  const supplierId = params?.id;
  const history = useHistory();
  const [dataSupplierDetail, setDataSupplierDetail] = useState<any>();

  useEffect(() => {
    (async () => {
      await AdminService.getSupplierDetail(supplierId).then((res: any) => {
        setDataSupplierDetail(res?.result);
      });
    })();
    // eslint-disable-next-line
  }, []);
  const structure = [
    {
      name: "code",
      defaultValue: dataSupplierDetail?.code,
      validate: Yup.string().required("Must be provided"),
      isDisabled: true,
    },
    {
      name: "name",
      defaultValue: dataSupplierDetail?.name,
      validate: Yup.string().required("Must be provided"),
    },
    {
      name: "image",
      validate: Yup.string().required("Must be provided"),
      defaultValue: { file: { name: dataSupplierDetail?.image }, src: dataSupplierDetail?.image },
    },
    {
      name: "thumnail",
      validate: Yup.string().required("Must be provided"),
      defaultValue: {
        file: { name: dataSupplierDetail?.thumnail },
        src: dataSupplierDetail?.thumnail,
      },
    },
  ];
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    enableReinitialize: true,
    structure: structure,
    onSubmit: async (values) => {
      const { thumnail, image } = values;
      // let thumbnailSrc = "";
      // let imageSrc = "";
      // try {
      //   if (thumnail) thumbnailSrc = JSON.parse(thumnail).src;
      //   if (image) imageSrc = JSON.parse(image).src;
      // } catch {}
      return AdminService.updateSupplier({
        ...values,
        thumnail: thumnail ? thumnail?.src : undefined,
        image: image ? image?.src : undefined,
        supplierId,
      })
        .then(() => {
          CreateAlert({
            message: "Update success.",
            type: "success",
          });
        })
        .catch((error) => {
          CreateAlert({
            message: error.message,
            type: "danger",
          });
        })
        .finally(() => {
          history.push("/game-management/supplier");
        });
    },
  });
  return (
    <>
      {dataSupplierDetail && (
        <div className="supplier-edit-page">
          <div className="row">
            <div className="col-6">
              <div className="supplier-edit-page__content">
                <div className="title">Edit Supplier</div>
                <div className="form container">
                  <div className="row">
                    <div className="col-6 input-container">
                      <InputWraper
                        label={"Name"}
                        inputProps={getInputProps("name")}
                        component={InputText}
                        className="mr5"
                      />
                    </div>
                    <div className="col-6 input-container">
                      <InputWraper
                        label={"Code"}
                        inputProps={getInputProps("code")}
                        component={InputText}
                      />
                    </div>
                    <div className="col-6 input-container">
                      <InputWraper
                        className={"image"}
                        label={"Image"}
                        inputProps={getInputProps("image")}
                        component={InputImageSingle}
                      />
                    </div>
                    <div className="col-6 input-container">
                      <InputWraper
                        className={"image-thumbnail"}
                        label={"Thumbnail"}
                        inputProps={getInputProps("thumnail")}
                        component={InputImageSingle}
                      />
                    </div>
                    <div style={{ textAlign: "center" }} className="col-12">
                      <Button
                        label="Save"
                        buttonType="success"
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="supplier-edit-page__cover">
                <img
                  className="cover-image"
                  src="/assets/images/edit-supplier-cover-image.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
