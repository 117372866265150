import React, { FC, useEffect } from "react";
import * as Yup from "yup";
import { InputPassword, InputText } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { UserService } from "../../../services";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";

const TokenBonusConfigPage: FC<any> = withConfigWraper(() => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "gtcBonus",
        validate: Yup.string()
          .required("Must be provided")
          .matches(/^(0|[1-9]\d*)$/, "Invalid Amount"),
      }
    ],
    onSubmit: async (values) => {
      let payload = {
        bonusTokenAmountConfig: values?.gtcBonus,
      };
      ConfigService.updateTokenBonusConfig(payload)
        .then((res) => {
          CreateAlert({ type: "success", message: "Update Token Bonus Successfully" });
        })
        .catch((err) => {
          CreateAlert({ type: "danger", message: translate(err.message) });
        })
        .finally(() => {});
    },
  });

  useEffect(() => {
    ConfigService.getTokenBonusConfig().then((res:any) => getInputProps("gtcBonus").onChange(+res?.result?.bonusTokenAmountConfig))
  }, [])

  return (
    <div className="row">
      <div className="col-sm-6">
        <div className="row">
          <div className="col-8 ">
            <InputWraper
              label={"GTC Bonus"}
              inputProps={getInputProps("gtcBonus")}
              component={InputText}
            />
          </div>
        </div>
        <Button
          label={"Update"}
          type="submit"
          buttonType="primary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
});

export default TokenBonusConfigPage;
