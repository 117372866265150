import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { InputCheckbox, InputSelect, InputText } from "../../../../components";
import { translate } from "../../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import { AdminService } from "../../../../services/admin";
import "./index.scss";
import { useSelector } from "../../../../store";
import { InputToggleSwitch } from "../../../../components/toggle-switch";

export enum EIdentificationTypeId {
  PersonalID = 1,
  Passport = 2
}

export const EditUser: FC<{ data: any; onFinishEdit: () => void }> = (props) => {
  const user = useSelector((state) => state.user);
  const { email, firstName, userRole, phoneNumber, personalId, status, id } = props.data;
  const [listRole, setListRole] = useState<Array<any>>([]);
  const [role, setRole] = useState<string>(userRole || "");
  const [identificationTypeId, setIdentificationTypeId] = useState(props?.data?.identificationTypeId);

  useEffect(() => {
    AdminService.getListRole().then((res) =>
      setListRole(() => {
        return res.map((item: any) => {
          return { label: item === "SUPER_ADMIN" ? "SUPER ADMIN" : item, value: item };
        });
      })
    );
  }, []);

  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "status",
        defaultValue: (status === "EMAIL_VERIFIED") ? true : false,
        isDisabled: (status === "EMAIL_VERIFIED") ? true : false
      },
      {
        name: "email",
        defaultValue: email,
        isDisabled: ((status !== "EMAIL_VERIFIED") || (user.userRole === "SUPER_ADMIN") || ((user.userRole === 'ADMIN') && (user.permissions.includes('EDIT_USER_FULL_PERMISSION')))) ? false : true,
        // validate: Yup.string().required(translate("must-be-provided")),
        // isDisabled: true,
      },
      {
        name: "id",
        defaultValue: id,
        validate: Yup.string().required(translate("must-be-provided"))
        .min(8, "ID has a minimum of 8 characters")
        .max(32, "ID has a maximum of 32 characters")
        .test("id validate", "ID includes only letters and numbers, no special characters, no accents, no spaces", (value: any) => {
            const regex = /^[a-zA-Z0-9]+$/
            return regex.test(value);
        }),
      },
      {
        name: "firstName",
        defaultValue: firstName,
        // validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "phoneNumber",
        validate: Yup.string().nullable().matches(/^\d{10,12}$/, translate("Invalid phone number, must be between 10 and 12 digits")),
        defaultValue: phoneNumber,
        isDisabled: ((status !== "EMAIL_VERIFIED") || (user.userRole === "SUPER_ADMIN") || ((user.userRole === 'ADMIN') && (user.permissions.includes('EDIT_USER_FULL_PERMISSION')))) ? false : true,
      },
      {
        name: "identification",
        validate: Yup.string()
          .required(translate("must-be-provided"))
          .max(50, translate(`{name} is too long - only {number} characters maximum`, {name: (identificationTypeId === EIdentificationTypeId.PersonalID) ? translate("Personal ID") : translate("Passport"), number: 50})),
        defaultValue: personalId,
        isDisabled: ((status !== "EMAIL_VERIFIED") || (user.userRole === "SUPER_ADMIN") || ((user.userRole === 'ADMIN') && (user.permissions.includes('EDIT_USER_FULL_PERMISSION')))) ? false : true,
      },
      // {
      //     name: "lastName",
      //     defaultValue: lastName,
      //     validate: Yup.string().required(translate("must-be-provided")),
      // },
      // {
      //     name: "phoneNumber",
      //     defaultValue: phoneNumber || "",
      //     validate: Yup.string().required(translate("must-be-provided")),
      // },
    ],
    // onSubmit: async (values) => {
    //     // console.log(values);
    //     return AdminService.updateProfile({ ...values, phoneCountryId: 1 })
    //         .then(() => {
    //             CreateAlert({
    //                 message: "Please check email to verify your account.",
    //                 type: "success",
    //             });
    //             props.onFinishEdit();
    //         })
    //         .catch((error) => {
    //             CreateAlert({
    //                 message: error.message,
    //                 type: "danger",
    //             });
    //         });
    // },
    onSubmit: async (values) => {
      const { userId, status } = props.data;
      if (role && role !== "")
        return AdminService.updateProfile({
          ...values,
          identificationTypeId: identificationTypeId,
          status: values.status ? "EMAIL_VERIFIED" : status,
          userRole: role,
          userId,
        })
          .then(() => {
            CreateAlert({
              message: "Update success.",
              type: "success",
            });
            props.onFinishEdit();
          })
          .catch((error:any) => {
            CreateAlert({
              message: translate(error.message),
              type: "danger",
            });
          });
      else
        CreateAlert({
          message: "Please choose a role!",
          type: "warning",
        });
    },
  });

  let handleOnChangeIdentificationTypeId = (identificationTypeId: EIdentificationTypeId) => {
    if (identificationTypeId === props?.data?.identificationTypeId) {
      getInputProps('identification').onChange(props?.data?.personalId);
    } else {
      getInputProps('identification').onChange('');
    }
    setIdentificationTypeId(identificationTypeId);
  };

  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <InputWraper
        inputProps={getInputProps("status")}
        renderInput={(state) => (
          <InputToggleSwitch {...state} label={translate("EMAIL_VERIFIED")} name="status" />
        )}
      />
      <InputWraper
        label={translate("email-address")}
        inputProps={getInputProps("email")}
        component={InputText}
      />
        <InputWraper
        label="ID"
        inputProps={getInputProps("id")}
        component={InputText}
      />
      <div className="group">
        <InputWraper
          label={"Display name"}
          inputProps={getInputProps("firstName")}
          component={InputText}
          className="mr5"
        />
        {/* <InputWraper
                    label={translate("last-name")}
                    inputProps={getInputProps("lastName")}
                    component={InputText}
                    className="ml5"
                /> */}
      </div>
      <InputWraper
        label={"Phone number"}
        inputProps={getInputProps("phoneNumber")}
        component={InputText}
        className="mr5"
      />

      <InputCheckbox
        type={"radio"}
        label={"Personal ID"}
        value={identificationTypeId === EIdentificationTypeId.PersonalID}
        onChange={() => handleOnChangeIdentificationTypeId(EIdentificationTypeId.PersonalID)}
        name="personalId"
        onTouched={() => null}
      />
      <InputCheckbox
          type={"radio"}
          label={"Passport"}
          value={identificationTypeId === EIdentificationTypeId.Passport}
          onChange={() => handleOnChangeIdentificationTypeId(EIdentificationTypeId.Passport)}
          name="passport"
          onTouched={() => null}
      />

      <InputWraper
        label={(identificationTypeId === EIdentificationTypeId.PersonalID) ? 'Personal ID' : 'Passport'}
        inputProps={getInputProps("identification")}
        component={InputText}
        className="mr5"
      />

      {/* <InputWraper
                label={translate("phone-number")}
                inputProps={getInputProps("phoneNumber")}
                component={InputText}
            /> */}
      {user.userRole === "SUPER_ADMIN" && <div className="item">
        <div className="label">Role</div>
        <InputSelect
          options={listRole}
          onChange={(e) => setRole(e)}
          onTouched={() => false}
          value={role}
          name=""
        />
      </div>}

      <div>
        <Button
          // className="mb20"
          label="Update"
          // onClick={props.onClick}
          isMiddle
          buttonType="primary"
          className="btnBan"
          type="submit"
        />
      </div>
    </form>
  );
};
