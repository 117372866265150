import React, { FC, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { AdminService } from '../../../services/admin';
import { getLocaleKey, translate } from '../../../languages';
import moment from 'moment';
import { NumberUtils } from '../../../modules';
import { getEnv } from '../../../configs';
import { useDeviceType } from '../../../hook';
import { PageHead } from '../../../components/page-head';
import { Routes } from '../../../AppRoutes';

export const NewOrderDetail: FC = () => {
  const DELIVERY_TYPE_VALUE = { ONLINE: 'ONLINE', OFFLINE: 'OFLINE' };
  const STATUS_VALUE_INITIAL = {
    COMPLETED: 'COMPLETED',
    DELIVERY_WAITING: 'DELIVERY_WAITING',
    CANCELLED: 'CANCELLED'
  };
  const router = useRouteMatch();
  const orderId = Object.values(router.params);
  const deviceType = useDeviceType();
  const [dataDetail, setDataDetail] = useState<any>([]);
  const [reason, setReason] = useState<string>('');
  const [isShowReason, setIsShowReason] = useState<boolean>(false);

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);

  const calTotalRevenue = (data: any) => {
    let total = 0;
    try {
      if (!data) return 0;
      data.forEach((item: any) => {
        total += item?.amount * item?.quantity;
      });
      return total;
    } catch (error) {
      console.log({ error });
      return total;
    }
  };

  const calTotalProfit = (data: any) => {
    let total = 0;
    try {
      if (!data) return 0;
      data.forEach((item: any) => {
        total += (item?.amount - item?.originalPrice) * item?.quantity;
      });
      return total;
    } catch (error) {
      console.log({ error });
      return total;
    }
  };

  useEffect(() => {
    AdminService.getOrderDetail(Number(orderId[0])).then((res: any) => {
      if (res?.data?.[0]?.status === STATUS_VALUE_INITIAL.CANCELLED) {
        let historiesStatusChange = res?.data?.[0]?.histories || [];
        let reason = [...historiesStatusChange]?.pop()?.reason;
        setReason(reason);
        setIsShowReason(true);
      }
      setDataDetail(res?.data);
      setTotalRevenue(calTotalRevenue(res?.data));
      setTotalProfit(calTotalProfit(res?.data));
    });
  }, []);
  return (
    <>
      <PageHead
        urlPath={Routes.orderManagement.path}
        title={dataDetail?.[0]?.orderCode}
      />
      <div className={`order-detail-container`}>
        {/* Thông tin người nhận */}

        <div className="left">
          <div className="row-1">
            <div className="wrapper">
              <div className="information-receiver">
                <p className="title">{translate('receiver-information')}</p>

                <div className="row-information">
                  <span className="title">{translate('receiver')}: </span>

                  <span>{dataDetail?.[0]?.email}</span>
                </div>

                <div className="row-information">
                  <span className="title">{translate('deliver-type')}: </span>
                  <span>
                    {dataDetail?.[0]?.deliverType === DELIVERY_TYPE_VALUE.ONLINE
                      ? translate('delivery-online')
                      : translate('delivery-offline')}
                  </span>
                </div>

                <div className="row-information">
                  <span className="title">{translate('address')}: </span>
                  <span>{dataDetail?.[0]?.address || ''}</span>
                </div>

                <div className="row-information">
                  <span className="title">{translate('phone-number')}: </span>
                  <span>{dataDetail?.[0]?.phone || ''}</span>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="information-order">
                <p className="title">{translate('order-information')} </p>
                <div className="row-information">
                  <span className="title">{translate('order-status')}: </span>
                  <span className={`${dataDetail?.[0]?.status}`}>
                    {translate(dataDetail?.[0]?.status)}
                  </span>
                </div>

                <div className="row-information">
                  <span className="title">{`${translate('time')}: `} </span>
                  <span>
                    {moment(dataDetail?.[0]?.created).format(
                      'HH:mm, ddd DD/MM/YYYY'
                    )}
                  </span>
                </div>

                <div className="row-information">
                  <span className="title">
                    {translate('order-detail-note-label')}:{' '}
                  </span>

                  <span>{dataDetail?.[0]?.note || ''}</span>
                </div>

                {isShowReason === true ? (
                  <div className="row-information">
                    <span className="title">
                      {translate('reason-cancelled-order-title')}:{' '}
                    </span>

                    <span>{reason || ''}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="row-2">
            <div className="wrapper">
              <p className="title">
                {translate('production-order-detail-titel')}
              </p>
              <div className="listProductions">
                {dataDetail &&
                  dataDetail.map((items: any, i: number) => {
                    let urlImg = JSON.parse(items?.thumbnailUrl)?.[0]?.url;
                    return (
                      <div className="itemProduction">
                        <div className="production-left">
                          <div
                            className="img-wrapper"
                            style={{
                              background: `url("${urlImg}")`
                            }}
                          ></div>
                          {/* <div className="img-product">
                            <img
                              src={JSON.parse(items?.thumbnailUrl)?.[0]?.url}
                              alt=""
                            />
                          </div> */}
                          <div className="information-production">
                            <p className="product-name">{items?.productName}</p>
                            <p className="product-provided">
                              {`${translate('provided-by')}: `}
                              <span>{items?.agencyName}</span>
                            </p>
                          </div>
                        </div>
                        <div className="production-right">
                          {/* {NumberUtils.toFormatNumber(
                            +items?.amount,
                            +getEnv('NUMBER_DECIMAL_DISPLAY')
                          )} */}
                          <div style={{width: "300px"}} className='d-flex justify-content-between'>
                            <p>Price</p>
                            <p>
                              {`${items?.amount.toLocaleString(getLocaleKey(), {
                                maximumFractionDigits: 3,
                                minimumFractionDigits: 3
                              })} GT`}
                            </p>
                          </div>
                          <div style={{width: "300px"}}  className='d-flex justify-content-between'>
                            <p>Cost Price</p>
                            <p>
                              {`${items?.originalPrice.toLocaleString(getLocaleKey(), {
                                maximumFractionDigits: 3,
                                minimumFractionDigits: 3
                              })} GT`}
                            </p>
                          </div>

                          {/* <p>{items?.amount} USDT</p> */}
                          <div style={{width: "300px"}}  className='d-flex justify-content-between'>
                           <p>Quantity</p>
                           <p>x{items?.quantity}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="row-3">
            <div className="wrapper">
              <div className="row-3-group">
                <p className="title">Total Revenue</p>
                <div className="price-unit">
                  <p className="price">
                    {/* {dataDetail?.[0]?.totalAmount} */}
                    {totalRevenue.toLocaleString(
                      getLocaleKey(),
                      {
                        maximumFractionDigits: 3,
                        minimumFractionDigits: 3
                      }
                    )}
                  </p>
                  <p className="unit">GT</p>
                </div>
              </div>

              <div className="row-3-group mt8">
                <p className="title">Total Profit</p>
                <div className="price-unit">
                  <p className="price">
                    {/* {dataDetail?.[0]?.totalAmount} */}
                    {totalProfit.toLocaleString(
                      getLocaleKey(),
                      {
                        maximumFractionDigits: 3,
                        minimumFractionDigits: 3
                      }
                    )}
                  </p>
                  <p className="unit">GT</p>
                </div>
              </div>
              <div className="row-3-group mt8">
                <p className="title">{translate('fee-shipping')}</p>
                <div className="price-unit">
                  <p className="price">
                    {/* {dataDetail?.[0]?.totalFee} */}
                    {dataDetail?.[0]?.totalFee?.toLocaleString(getLocaleKey(), {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })}
                  </p>

                  <p className="unit">GT</p>
                </div>
              </div>

              <div className="row-3-group mt8">
                <p className="title">Profit</p>
                <div className="price-unit">
                  <p className="price">
                    {/* {dataDetail?.[0]?.totalPayment} */}
                    {(totalProfit - dataDetail?.[0]?.totalFee)?.toLocaleString(
                      getLocaleKey(),
                      {
                        maximumFractionDigits: 3,
                        minimumFractionDigits: 3
                      }
                    )}
                  </p>
                  <p className="unit">GT</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="right">
          <div className="status-order">
            <p className="title">{translate('order-status')}</p>
            <ul>
              {STATUS_ORDER_VALUE.map((element: any, index: number) => {
                return (
                  <li className={element} key={index}>
                    {translate(element)}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="type-receive">
            <p className="title">{translate('deliver-type')}</p>
            <ul>
              <li>{translate('delivery-online')}</li>
              <li>{translate('delivery-offline')}</li>
            </ul>
          </div>
        </div> */}
      </div>
    </>
  );
};
