import React, { FC } from 'react';
import Select from 'react-select';

import { ITableFilterInputProps, ObjectUtils } from '../../../modules';
import { IInputSelectOption } from '../../../modules/types';

interface ITableFilterInputSelectProps extends ITableFilterInputProps {
  options: IInputSelectOption[];
  isClearable?: boolean;
  isSearchable?: boolean;
}

export const TableFilterInputSelect: FC<ITableFilterInputSelectProps> = (
  props
) => {
  const key = props.paramKey;
  return (
    <Select
      options={props.options.filter((v) => v.value !== props.params[key])}
      className="TableFilterInputSelect custom"
      classNamePrefix="TableFilterInputSelect"
      placeholder="-- Select --"
      onChange={(option) => {
        props.onChange({ [key]: ObjectUtils.getIn(option, 'value', '') });
      }}
      defaultValue={props.options.find((v) => v.value === props.params[key])}
      isClearable={props.isClearable}
      isSearchable={props?.isSearchable}
    />
  );
};

TableFilterInputSelect.defaultProps = {
  isClearable: true
};
