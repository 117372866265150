import React, { useState, FC } from "react";
import * as Yup from "yup";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { UserService } from "../../../services/user";
import { useForm, InputWraper, Button, CreateAlert } from "../../../modules";
import { InputText, InputPassword } from "../../../components";
import { AuthWraper } from "../authWraper";
import { translate } from "../../../languages";
import { useSelector } from "../../../store";
import _ from "lodash";

export const PageAuthLogin: FC = () => {
  const user = useSelector(state => state.user);
  // const [recaptcha, setRecaptcha] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha() as any;
  const [dataPuzzleImg, setDataPuzzleImg] = useState<any>();
  const [captchaUid, setCaptchaUid] = useState<any>();

  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "id",
        validate: Yup.string().required(translate("must-be-provided"))
        // .min(8, "ID has a minimum of 8 characters")
        // .max(32, "ID has a maximum of 32 characters")
        // .test("id validate", "ID includes only letters and numbers, no special characters, no accents, no spaces", (value: any) => {
        //     const regex = /^[a-zA-Z0-9]+$/
        //     return regex.test(value);
        // }),
    },
      {
        name: "password",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "puzzleAnswer",
        validate: dataPuzzleImg != null ? Yup.string().required(translate("Must be provided")) : null,
        isDisabled: !!user
      },
    ],
    onSubmit: async (values) => {
      let GoogleRecaptchaToken;
      try {
          GoogleRecaptchaToken = await executeRecaptcha("login"); //google recaptcha v3
      } catch (error) {
          console.log(error);
      }

      let payload = {
          id: values.id,
          password: values.password,
          recaptcha: GoogleRecaptchaToken,
          captchaUid: captchaUid,
          puzzleAnswer: values?.puzzleAnswer,
      };

      return UserService.login(payload).catch((err) => {
        // setRecaptcha("");

        if (_.isError(_.attempt(JSON.parse, err.message)) === false) {
            let messageJSON = JSON.parse(err.message);
            if (messageJSON?.message === "REQUIRED_SOLVE_PUZZLE") {
                setDataPuzzleImg(messageJSON?.puzzleImg);
                setCaptchaUid(messageJSON?.captchaUid);
                return;
            }
            if (messageJSON?.message === "SOLVE_PUZZLE_INCORRECTLY") {
                setDataPuzzleImg(messageJSON?.puzzleImg);
                setCaptchaUid(messageJSON?.captchaUid);
                CreateAlert({ message: translate(messageJSON.message), type: "danger" });
                return;
            }
            if (messageJSON?.detail?.includes("timeout-or-duplicate") === true) {
                //if token Google reCaptcha expired then get new token and call api
                handleSubmit();
                return;
            }
            if (_.isPlainObject(messageJSON)) {
                CreateAlert({ message: messageJSON.message, type: "danger" });
                return;
            }
        }

        if (dataPuzzleImg != null) {
            //if any info incorrect, also refresh puzzle
            UserService.getPuzzleCaptcha({
                captchaUid: captchaUid,
            }).then((res: any) => {
                setDataPuzzleImg(res?.result?.puzzleImg);
                setCaptchaUid(res?.result?.captchaUid);
            });
        }
        CreateAlert({ message: err.message, type: "danger" });
        throw err;
      });
    },
  });

  return (
    <AuthWraper>
      <div className="PageAuth login">
        <img src="/assets/images/maps.png" className="background" alt="" />

        <form onSubmit={handleSubmit}>
          <div className="head">
            <img className="logo" src="/assets/images/logo-symbol-white.png" alt="" />
            <div className="title">{translate("login-to-your-account")}</div>
          </div>

          {/* <GoogleReCaptcha onVerify={(token) => setRecaptcha(token)} /> */}

          <InputWraper
            label="ID"
            inputProps={getInputProps("id")}
            component={InputText}
          />

          <InputWraper
            label={translate("password")}
            inputProps={getInputProps("password")}
            component={InputPassword}
          />

          {dataPuzzleImg && (<>
            <div
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: dataPuzzleImg }}
            ></div>
            <InputWraper
                label={"Puzzle Answer"}
                inputProps={getInputProps("puzzleAnswer")}
                component={InputText}
            />
          </>)}

          <Button label={translate("login")} type="submit" isLoading={isSubmitting} />
        </form>
      </div>
    </AuthWraper>
  );
};
