import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Box, InputText } from "../../../components";
import { InputImageSingle } from "../../../components/single-image";
import { useForm, CreateAlert, InputWraper, Button, Icon } from "../../../modules";
import * as Yup from "yup";
import { StartupService } from "../../../services/startup";
import { EditorForm } from "../../../components/ckeditor-compnt";
import { Routes } from "../../../AppRoutes";
import { useHistory } from "react-router-dom";
import { ReasonForm } from "../components/reason";
import { PageHead } from "../../../components/page-head";
import { translate } from "../../../languages";
import levenshtein from "js-levenshtein";
import validator from "validator";

export interface IUserWraperProps extends RouteComponentProps {}

export const StartupDetail: FC = () => {
  const router = useRouteMatch();
  const navigate = useHistory();
  const startupId = Object.values(router.params);
  const [imageArray, setImageArray] = useState<any>([]);
  const [description, setDescription] = useState<any>("");
  const [approvalStatus, setApprovalStatus] = useState<any>("");
  const [openReasonForm, setOpenReasonForm] = useState(false);
  const [dataStartupDetail, setDataStartupDetail] = useState<any>({});
  const [investmentOptionList, setInvestmentOptionList] = useState<any>([]);

  // const deviceType = useDeviceType();
  const onApprove = () => {
    StartupService.updateStartupStatus({ agencyId: Number(startupId[0]), approvalStatus: 1 }).then(() => {
      CreateAlert({ message: "Approve startup successfully", type: "success" });
      navigate.push(Routes.startupList.path);
    });
  };

  const { getInputProps, handleSubmit, isSubmitting } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: "projectName",
        defaultValue: dataStartupDetail?.projectName,
        validate: Yup.string().required("Must be provided"),
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
      },
      {
        name: "totalAmountContribution",
        defaultValue: dataStartupDetail?.totalAmountContribution,
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        validate: Yup.number().typeError("Must be a number").required("Must be provided")
        // .moreThan(0, "Must be greater than 0"),
      },
      {
        name: "minBalanceUserRequire",
        defaultValue: dataStartupDetail?.minBalanceUserRequire,
        isDisabled: true,
      },
      {
        name: "representativeName",
        defaultValue: dataStartupDetail?.representativeName,
        isDisabled: true,
      },
      {
        name: "representativeEmail",
        validate: Yup.string()
          .required("Must be provided")
          .test("is-email", translate("INVALID_EMAIL"), (value: any) => {
            // Check if it's a valid email
            if (value && !validator.isEmail(value)) {
              return false;
            }

            // Custom regular expression for more rigorous email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(value)) {
              return false;
            }

            // List of valid domains
            const validDomains = [
              "gmail.com",
              "yahoo.com",
              "hotmail.com",
              "outlook.com",
              "aol.com",
              "icloud.com",
              "msn.com",
              "live.com",
              "protonmail.com",
              "zoho.com",
              "mail.com",
              "yandex.com",
            ];

            // Check if the email's domain is similar to any of the valid domains
            const domain = value?.split("@")[1];
            const isSimilarToValidDomain = validDomains.some((validDomain) => levenshtein(validDomain, domain) <= 2);

            // If the domain is similar to a valid domain but not exactly the same, fail validation
            if (isSimilarToValidDomain && !validDomains.includes(domain)) {
              return false;
            }

            return true;
          }),
        defaultValue: dataStartupDetail?.representativeEmail,
        isDisabled: true,
      },
      {
        name: "phoneNumber",
        defaultValue: dataStartupDetail?.phoneNumber,
        isDisabled: true,
      },
      {
        name: "personalId",
        defaultValue: dataStartupDetail?.personalNumberId,
        isDisabled: true,
      },
      {
        name: "website",
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        defaultValue: dataStartupDetail?.website,
      },
      {
        name: "kindOfBussiness",
        defaultValue: dataStartupDetail?.kindOfBussiness,
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "projectDescription",
        defaultValue: dataStartupDetail?.projectDescription || "<p><p/>",
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        validate: Yup.string().required("Must be provided"),
      },
      {
        name: "photoUrl",
        defaultValue: {
          file: { name: JSON.parse(dataStartupDetail?.photoUrl ?? "[{}]")?.[0]?.url },
          src: JSON.parse(dataStartupDetail?.photoUrl ?? "[{}]")?.[0]?.url,
        },
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        // validate: (imageArray.length === 0) ? Yup.string().required("Must be provided") : null
      },
      {
        name: "licenseFrontUrl",
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        defaultValue: JSON.parse(dataStartupDetail?.licenseUrl ?? "[]")?.length >= 1 ? { file: { name: JSON.parse(dataStartupDetail?.licenseUrl ?? "[]")?.[0]?.url }, src: JSON.parse(dataStartupDetail?.licenseUrl ?? "[]")?.[0]?.url } : null
      },
      {
        name: "licenseBackUrl",
        // isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        defaultValue: JSON.parse(dataStartupDetail?.licenseUrl ?? "[]")?.length >= 2 ? { file: { name: JSON.parse(dataStartupDetail?.licenseUrl ?? "[]")?.[1]?.url }, src: JSON.parse(dataStartupDetail?.licenseUrl ?? "[]")?.[1]?.url } : null
      },
      {
        name: "mapDetail",
        //   isDisabled: approvalStatus === 1 && approvalStatus !== 2,
        defaultValue: dataStartupDetail?.mapDetail?.rawAddress,
      },
    ],
    onSubmit: async (values) => {
      let payload = {
        agencyId: Number(startupId[0]),
        projectName: values.projectName,
        totalAmountContribution: +values.totalAmountContribution,
        minBalanceUserRequire: +values.minBalanceUserRequire,
        representativeName: values.representativeName,
        representativeEmail: values.representativeEmail,
        kindOfBussiness: values.kindOfBussiness,
        website: values.website,
        projectDescription: values.projectDescription,
        photoUrl: imageArray,
        licenseUrl: [
          values?.licenseFrontUrl?.src ? values.licenseFrontUrl.src : null,
          values?.licenseBackUrl?.src ? values.licenseBackUrl.src : null,
        ].filter(Boolean),
        mapDetail: { raw_address: values?.mapDetail },
      };
      return StartupService.updateStartup(payload)
        .then(() => {
          CreateAlert({ message: "Edit startup successfully", type: "success" });
          navigate.push(Routes.startupList.path);
        })
        .catch((err: any) => {
          const error = { ...err };
          CreateAlert({ message: error.message, type: "danger" });
        });
    },
  });

  let handleOnClickDeleteImages = (element: any, idx: number) => {
      setImageArray((state: any) => {
          const newState = [...state];
          newState.splice(idx, 1);
          return newState;
      });
  }

  useEffect(() => {
    StartupService.getStarupList({ page: 1, pageSize: 1, agencyId: Number(startupId[0]) }).then((res: any) => {
      setDataStartupDetail(res?.data?.[0]);
      setInvestmentOptionList(JSON.parse(res.data?.[0]?.investmentOptionValues) || [])
      setDescription(!!res?.data?.[0]?.projectDescription ? res?.data?.[0]?.projectDescription : "<p><p/>");
      setApprovalStatus(res?.data?.[0]?.approvalStatus);
      setImageArray(JSON.parse(res?.data?.[0]?.photoUrl ?? "[{}]")?.map((element: any) => element.url) ?? []);
    });
    // eslint-disable-next-line
  }, []);

  if (!dataStartupDetail)
    return (
      <div className="affiliated-agent-edit-page container-fluid">
        <div className="d-flex justify-content-center p40">
          <Icon.Loading />
        </div>
      </div>
    );

  return (
    <div className="StartupDetail">
      <PageHead urlPath={Routes.startupList.path} title="Startup list" />
      <div className="my-startup-add-page">
        <Box title={"Startup Detail"}>
          <div className="row">
            <div className="col-12">
              <InputWraper label={"Project Name"} inputProps={getInputProps("projectName")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Total Capital Call"} inputProps={getInputProps("totalAmountContribution")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Min User Balance"} inputProps={getInputProps("minBalanceUserRequire")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Representative Name"} inputProps={getInputProps("representativeName")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Representative Email"} inputProps={getInputProps("representativeEmail")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Phone Number"} inputProps={getInputProps("phoneNumber")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Personal ID"} inputProps={getInputProps("personalId")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Website"} inputProps={getInputProps("website")} component={InputText} />
            </div>
            <div className="col-6">
              <InputWraper label={"Business Type"} inputProps={getInputProps("kindOfBussiness")} component={InputText} />
            </div>
            <div className="col-6">
              <div className="InputWraper">
                <div className="label">Investment Limit</div>
               <div className="wraper">
                <div className="tag-list">
                  {investmentOptionList?.map((el: any, idx: number) => (<span className="tag-item" key={idx}>{el}</span>))}
                </div>
               </div>

              </div>
            </div>
            <div className="col-12">
              <InputWraper
                label={"Description"}
                inputProps={getInputProps("projectDescription")}
                // component={EditorForm}
                renderInput={(state) => (
                  <EditorForm
                    // {...state}
                    value={description}
                    onChange={(e: any) => {
                      state.onChange(e);
                      setDescription(e);
                    }}
                  />
                )}
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-2">
                  <InputWraper
                    label={"Images"}
                    inputProps={getInputProps("photoUrl")}
                    renderInput={(props) => (
                      <InputImageSingle
                        {...props}
                        isShowImage={false}
                        onChange={(value: any) => {
                          setImageArray((state: any) => [...state, value.src]);
                        }}
                      />
                    )}
                  />
                </div>
                {imageArray.map((element: any, idx: number) => {
                  return (
                    <div key={idx} className="col-2 image__items">
                      <div className="image__items__delete-indicator" onClick={() => handleOnClickDeleteImages(element, idx)}><Icon.TrashIcon /></div>
                      <img src={element} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-6">
              <InputWraper label={"Front Of License"} inputProps={getInputProps("licenseFrontUrl")} component={InputImageSingle} />
            </div>
            <div className="col-6">
              <InputWraper label={"Back Of License"} inputProps={getInputProps("licenseBackUrl")} component={InputImageSingle} />
            </div>
            <div className="col-12">
              <InputWraper className="mb20" label={"Address"} inputProps={getInputProps("mapDetail")} component={InputText} />

              <iframe
                title=" "
                style={{ borderRadius: "8px" }}
                src={`https://www.google.com/maps?q=${encodeURIComponent(getInputProps("mapDetail").value)}&output=embed`}
                width="600"
                height="450"
              ></iframe>
            </div>

            <div className="col-12 d-flex justify-content-center">
              {approvalStatus === 1 && approvalStatus !== 2 && (
                <Button label={"Submit"} className="mt20" onClick={handleSubmit} isLoading={isSubmitting} disabled={isSubmitting} />
              )}
              {approvalStatus !== 1 && approvalStatus !== 2 && (
                <div className="col-6 d-flex justify-content-between">
                  <Button label={"Reject"} className="mt20" onClick={() => setOpenReasonForm(true)} buttonType="grey" />
                  <Button label={"Approve"} className="mt20" onClick={onApprove} isLoading={isSubmitting} disabled={isSubmitting} />
                </div>
              )}
            </div>
          </div>
        </Box>
      </div>
      {openReasonForm && (
        <ReasonForm
          onClose={() => {
            setOpenReasonForm(false);
          }}
          payload={{ agencyId: Number(startupId[0]), approvalStatus: 2 }}
        />
      )}
    </div>
  );
};
