import React, { FC } from 'react'
import { translate } from '../../languages'
import { ClassNames } from '../../modules'

export interface IBoxProps {
    title: any,
    className?: string,
    type?: 'normal' | 'title-outside'
}

export const Box: FC<IBoxProps> = (props) => {
    return (
        <div className={ClassNames({
            Box: true,
            [props.className as string]: !!props.className,
            [props.type as string]: true,
        })}>
            <div className="head">
                <div className="title">
                    {translate(props.title)}
                </div>
            </div>

            <div className="content">
                {props.children}
            </div>
        </div>
    )
}

Box.defaultProps = {
    type: 'normal'
}