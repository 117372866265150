import React, { useState } from 'react';
import * as Yup from 'yup';
import { PopupWraper } from '../../../../../components/popup';
import {
  Button,
  CreateAlert,
  InputWraper,
  useForm
} from '../../../../../modules';
import { translate } from '../../../../../languages';
import { InputText } from '../../../../../components';

function PopupDetailKYC(props: any) {
  let defaultImage = '/assets/images/logo-symbol-white1.png';
  const [isShowPopupReason, setIsShowPopupReason] = useState<boolean>(false);
  const { handleSubmit, getInputProps, getValue } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: 'email',
        defaultValue: props?.dataDetailKYC?.email,
        isDisabled: true
      },
      {
        name: 'displayName',
        defaultValue: props?.dataDetailKYC?.displayName,
        isDisabled: true
      },
      {
        name: 'reason',
        defaultValue: ''
        // validate: Yup.string().trim().required('Must be provided')
      }
    ],
    onSubmit: (typeAction: any) => {
      props.handleShowPopupConfirmVerify(typeAction);
    }
  });

  const handleSubmitVerify = (typeAction: any) => {
    let reason = getValue('reason');
    if (!reason?.trim() && typeAction === 'reject') {
      return CreateAlert({
        type: 'danger',
        message: 'Vui lòng nhập reason'
      });
    } else {
      setIsShowPopupReason(false);
      return props.handleShowPopupConfirmVerify(typeAction, getValue('reason'));
    }
  };

  return (
    <>
      {props?.isShow === true ? (
        <PopupWraper
          title={translate('verify-kyc')}
          center
          onClose={props.hideDetailKYC}
        >
          <div className="group">
            <InputWraper
              label={translate('email-address')}
              inputProps={getInputProps('email')}
              component={InputText}
            />
          </div>
          <div className="group">
            <InputWraper
              label={translate('display-name-title')}
              inputProps={getInputProps('displayName')}
              component={InputText}
            />
          </div>

          <div className="group list-image">
            <div className="image-item">
              <p className="label">{translate('face-photo')}</p>
              <div className="image-wrapper">
                <img
                  // onClick={() => onImageGallery(dataDetailKYC?.dataImage, 0)}
                  src={props.dataDetailKYC?.avatarFileId || defaultImage}
                  alt=""
                />
              </div>
            </div>

            <div className="image-item">
              <p className="label">{translate('front-of-card')}</p>
              <div className="image-wrapper">
                <img
                  // onClick={() => onImageGallery(dataDetailKYC?.dataImage, 1)}
                  src={props.dataDetailKYC?.frontOfCardFileId || defaultImage}
                  alt=""
                />
              </div>
            </div>

            <div className="image-item">
              <p className="label">{translate('back-of-card')}</p>
              <div className="image-wrapper">
                <img
                  // onClick={() => onImageGallery(dataDetailKYC?.dataImage,2)}
                  src={props.dataDetailKYC?.backOfCardFileId || defaultImage}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="btn-action-wrapper">
            <Button
              className=""
              label={translate('APPROVED')}
              buttonType="primary"
              onClick={() => handleSubmitVerify('approve')}
            />

            <Button
              className=""
              label={translate('REJECTED')}
              buttonType="grey"
              onClick={() => setIsShowPopupReason(true)}
              // onClick={() => handleSubmitVerify('reject')}
              // () => props.handleShowPopupConfirmVerify('reject')
            />
          </div>
        </PopupWraper>
      ) : (
        <></>
      )}

      {isShowPopupReason === true ? (
        <PopupWraper
          title={'Reason for rejection'}
          onClose={() => setIsShowPopupReason(false)}
        >
          <div className="group">
            <InputWraper
              label={translate('reason')}
              inputProps={getInputProps('reason')}
              component={InputText}
            />
          </div>

          <div className="btn-action-wrapper">
            <Button
              className=""
              label={translate('confirm')}
              buttonType="primary"
              onClick={() => handleSubmitVerify('reject')}
            />

            <Button
              className=""
              label={translate('cancel-btn')}
              buttonType="grey"
              onClick={() => setIsShowPopupReason(false)}
            />
          </div>
        </PopupWraper>
      ) : (
        <></>
      )}
    </>
  );
}

export default PopupDetailKYC;
