import React, { useState, useEffect, FC, memo } from "react";
import XLSX from "xlsx";

import { moduleConfig } from "../../../modules/module.config";
import { withReportWraper } from "../wraper";
import { AdminService } from "../../../services/admin";
import { InputDateTimeRange } from "../../../components/table-filter-inputs";
import { Message, Table, Button, ObjectUtils, CreateAlert, ITableStructureItem, NumberUtils } from "../../../modules";
import { getLocaleKey } from "../../../languages";
import { Icon, InputSelect } from "../../../components";
import moment from "moment";
import { getEnv } from "../../../configs";

export const ReportSystem = withReportWraper(
  memo(
    () => {
      const [rangeTime, setRangeTime] = useState({
        startTime: moment().startOf("month").format("YYYY/MM/DD HH:mm:ss"), //Date.now() - 1000 * 60 * 60 * 24 * 30,
        endTime: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"), //Date.now(),
      } as any);

      const [reports, setReports] = useState(null as any);
      const [systemReportDetail, setSystemReportDetail] = useState(null as any);
      const [modeOption, setModeOption] = useState("");

      useEffect(() => {
        if (!rangeTime) {
          setReports(null);
        } else {
          setReports(null);

          AdminService.getSytemReport({
            fromDate: new Date(rangeTime.startTime),
            toDate: new Date(rangeTime.endTime),
          })
            .then((res:any) => {
              const sumReports = Object.keys(res[0]).reduce((ouput: any, key: string) => {
                //if (typeof res[0][key] === 'number') ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                ouput[key] = res.reduce((sum: number, item: any) => sum + item[key], 0);
                return ouput;
              }, {});

              setReports({
                count: res.length,
                data: res.sort((a: any, b: any) => b.start - a.start),
                sumReports,
              });
            })
            .catch((err) => setReports({ error: err.error }));
        }
      }, [rangeTime]);

      const ModeOptions = [
        { label: "Balance", value: "userBalance" },
        { label: "Hot Wallet", value: "hotWallet" },
        // { label: "Convert", value: "convert" },
        { label: "Withdraw", value: "withdraw" },
        { label: "Deposit", value: "deposit" },
        // { label: "Safe", value: "safe" },
      ];

      const sumMiddleware = (func: (item: any) => any) => (item: any, fetchData: any, column: ITableStructureItem) => {
        if (item.isSum) {
          if (column.key === "date") return "SUM";
          if (column.key === "userCount") return reports?.data[0]?.userCount;
          if (column.key === "userBalanceUsdt")
            return NumberUtils.toFormatNumber(reports?.data[0]?.userBalanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));

          if (column.key === "userBalanceGtm") return NumberUtils.toFormatNumber(reports?.data[0]?.userBalanceGtm, +getEnv("NUMBER_DECIMAL_DISPLAY"));

          if (column.key === "userBalanceGtc") return NumberUtils.toFormatNumber(reports?.data[0]?.userBalanceGtc, +getEnv("NUMBER_DECIMAL_DISPLAY"));

          if (column.key === "hotWalletUsdt") return NumberUtils.toFormatNumber(reports?.data[0]?.hotWalletUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));

          // if (column.key === "hotWalletBusd")
          //   return NumberUtils.toFormatNumber(
          //     reports?.data[0]?.hotWalletBusd,
          //     +getEnv("NUMBER_DECIMAL_DISPLAY")
          //   );

          // if (column.key === "hotWalletUsdc")
          //   return NumberUtils.toFormatNumber(
          //     reports?.data[0]?.hotWalletUsdc,
          //     +getEnv("NUMBER_DECIMAL_DISPLAY")
          //   );
          // if (column.key === "userBalanceGp2")
          //   return NumberUtils.toFormatNumber(
          //     reports?.data[0]?.userBalanceGp2,
          //     +getEnv("NUMBER_DECIMAL_DISPLAY")
          //   );
          // if (column.key === "hotWalletGp2")
          //   return NumberUtils.toFormatNumber(
          //     reports?.data[0]?.hotWalletGp2,
          //     +getEnv("NUMBER_DECIMAL_DISPLAY")
          //   );
          if (column.key && reports && reports.sumReports && typeof reports.sumReports[column.key] === "number") {
            if (Number.isNaN(reports.sumReports[column.key])) return "--";
            const value = NumberUtils.toFormatNumber(+reports.sumReports[column.key] || 0, +getEnv("NUMBER_DECIMAL_DISPLAY"));
            if (
              [
                "userCount",
                // "userOrderCount",
                // "orderCount",
                "loginCount",
              ].includes(column.key)
            )
              return `${(+reports.sumReports[column.key] || 0).toLocaleString(getLocaleKey(), {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}`;
            return `${value}`;
          }

          return "--";
        }

        return func(item);
      };

      const structure = [
        {
          name: "Date",
          key: "date",
          render: sumMiddleware((item) => {
            return <div>{moment(item?.start).format("DD/MM/y")}</div>;
          }),
          sort: {
            key: "date",
          },
        },
        // {
        //   name: "Total Volume",
        //   key: "orderVolume",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.orderVolume,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "orderVolume",
        //   },
        // },
        {
          name: "Users",
          key: "userCount",
          render: sumMiddleware((item: any) => {
            return (+item.userCount).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            });
          }),
          sort: {
            key: "userCount",
          },
        },
        // {
        //   name: "Orders",
        //   key: "userOrderCount",
        //   render: sumMiddleware((item: any) => {
        //     return (+item.userOrderCount).toLocaleString(getLocaleKey(), {
        //       maximumFractionDigits: 0,
        //       minimumFractionDigits: 0,
        //     });
        //   }),
        //   sort: {
        //     key: "userOrderCount",
        //   },
        // },
        // {
        //   name: "Profit Volume",
        //   key: "profitVolume",
        //   className: "profit-volume",
        //   render: sumMiddleware((item: any) => {
        //     return (
        //       <span
        //         className={item?.profitVolume >= 0 ? "profit-volume-green" : "profit-volume-red"}
        //       >
        //         {NumberUtils.toFormatNumber(+item?.profitVolume, +getEnv("NUMBER_DECIMAL_DISPLAY"))}
        //       </span>
        //     );
        //   }),
        //   sort: {
        //     key: "profitVolume",
        //   },
        // },
        {
          name: "User Balance GT",
          key: "userBalanceUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.userBalanceUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "userBalanceUsdt",
          },
        },
        {
          name: "Withdraw GT",
          key: "withdrawUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.withdrawUsdt, 4);
          }),
          sort: {
            key: "withdrawUsdt",
          },
        },
        // {
        //   name: "User Balance MEGADAO",
        //   key: "userBalanceGp2",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.userBalanceGp2,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "userBalanceGp2",
        //   },
        // },
        {
          name: "Deposit GT",
          key: "depositUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.depositUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "depositUsdt",
          },
        },
        // {
        //   name: "Deposit MEGADAO",
        //   key: "depositMega",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.depositMega,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "depositMega",
        //   },
        // },
        // {
        //   name: "USDT Converted",
        //   key: "convertRecieved",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.convertRecieved,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "convertRecieved",
        //   },
        // },
        // {
        //   name: "Exchange In",
        //   key: "exchangeIn",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(+item?.exchangeIn, +getEnv("NUMBER_DECIMAL_DISPLAY"));
        //   }),
        //   sort: {
        //     key: "exchangeIn",
        //   },
        // },
        // {
        //   name: "Exchange Out",
        //   key: "exchangeOut",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.exchangeOut,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "exchangeOut",
        //   },
        // },
        // {
        //   name: "Exchange Fee 1%",
        //   key: "exchangeOutFeeTotal",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.exchangeOutFeeTotal,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "exchangeOutFeeTotal",
        //   },
        // },
        // {
        //   name: "Hot Wallet GPO",
        //   key: "hotWalletGp2",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.hotWalletGp2,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "hotWalletGp2",
        //   },
        // },
        {
          name: "Hot Wallet GT",
          key: "hotWalletUsdt",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.hotWalletUsdt, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "hotWalletUsdt",
          },
        },
        {
          name: "User Balance GTM",
          key: "userBalanceGtm",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.userBalanceGtm, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "userBalanceGtm",
          },
        },
        {
          name: "User Balance GTC",
          key: "userBalanceGtc",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.userBalanceGtc, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "userBalanceGtc",
          },
        },
        {
          name: "Total Investment",
          key: "totalInvestment",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.totalInvestment, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "totalInvestment",
          },
        },
        {
          name: "Direct Commission USDT",
          key: "directCommission",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.directCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "directCommission",
          },
        },
        {
          name: "Trading Commission USDT",
          key: "tradingCommission",
          render: sumMiddleware((item: any) => {
            return NumberUtils.toFormatNumber(+item?.tradingCommission, +getEnv("NUMBER_DECIMAL_DISPLAY"));
          }),
          sort: {
            key: "tradingCommission",
          },
        },
        // {
        //   name: "Hot Wallet BUSD",
        //   key: "hotWalletBusd",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.hotWalletBusd,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "hotWalletBusd",
        //   },
        // },
        // {
        //   name: "Hot Wallet USDC",
        //   key: "hotWalletUsdc",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.hotWalletUsdc,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "hotWalletUsdc",
        //   },
        // },
        // {
        //   name: "Refund USDT",
        //   key: "refundValue",
        //   render: sumMiddleware((item: any) => {
        //     return NumberUtils.toFormatNumber(
        //       +item?.refundValue,
        //       +getEnv("NUMBER_DECIMAL_DISPLAY")
        //     );
        //   }),
        //   sort: {
        //     key: "hotWalletGp2",
        //   },
        // },
        // {
        //   name: "Total Orders",
        //   key: "orderCount",
        //   render: sumMiddleware((item: any) => {
        //     return (+item.orderCount).toLocaleString(getLocaleKey(), {
        //       maximumFractionDigits: 0,
        //       minimumFractionDigits: 0,
        //     });
        //   }),
        //   sort: {
        //     key: "orderCount",
        //   },
        // },
        {
          name: "Login Count",
          key: "loginCount",
          render: sumMiddleware((item: any) => {
            return (+item.loginCount).toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            });
          }),
          sort: {
            key: "loginCount",
          },
        },
      ].filter((v) => {
        if (modeOption === "hotWallet")
          return [
            "date",
            // "hotWalletGp2",
            // "hotWalletBusd",
            // "hotWalletUsdc",
            "hotWalletUsdt",
          ].includes(v.key);
        if (modeOption === "userBalance")
          return [
            "date",
            // "userBalanceGp2",
            "userBalanceUsdt",
            "userBalanceGtm",
            "userBalanceGtc",
          ].includes(v.key);
        // if (modeOption === "convert") return ["date", "convertRecieved"].includes(v.key);
        if (modeOption === "withdraw")
          return [
            "date",
            "withdrawUsdt",
            // "depositMega"
          ].includes(v.key);
        if (modeOption === "deposit")
          return [
            "date",
            "depositUsdt",
            // "depositMega"
          ].includes(v.key);
        return true;
      });

      const handleExportExcel = async () => {
        return new Promise((resolve) => {
          try {
            const reportData = ObjectUtils.getIn(reports, "data", []);
            const fileHead = structure.map((v) => v.name);
            const data = reportData.map((item: any) =>
              structure.map((column, index) => {
                if (index === 0) return moment(item?.start).format("DD/MM/y");
                if (
                  [
                    // "orderVolume",
                    // "profitVolume",
                    "userBalanceUsdt",
                    "userBalanceGtm",
                    "userBalanceGtc",
                    // "userBalanceGp2",
                    "withdrawUsdt",
                    "depositUsdt",
                    // "depositMega",
                    "hotWalletUsdt",
                    // "hotWalletUsdc",
                    // "hotWalletBusd",
                    // "hotWalletGp2",
                    "refundValue",
                    // "exchangeIn",
                    // "exchangeOut",
                    // "exchangeOutFeeTotal",
                    "totalInvestment",
                    "directCommission",
                    "tradingCommission",
                    // "convertRecieved",
                  ].includes(column?.key)
                )
                  return item[column.key] ? NumberUtils.toFormatNumber(+item[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : `0.00000000`;
                return item[column.key] || 0;
              })
            );

            const dataExport = [
              fileHead,
              ...data,
              structure.map((column, index) => {
                if (index === 0) return "SUM";
                if (column.key === "date") return "SUM";
                if (column.key === "userCount") return reports?.data[0]?.userCount;
                if (
                  [
                    "userBalanceUsdt",
                    "userBalanceGtm",
                    "userBalanceGtc",
                    // "userBalanceGp2",
                    // "hotWalletGp2",
                    "hotWalletUsdt",
                    // "hotWalletUsdc",
                    // "hotWalletBusd",
                  ].includes(column?.key)
                )
                  return reports?.data[0][column.key]
                    ? NumberUtils.toFormatNumber(+reports?.data[0][column.key], +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : `0.00000000`;
                if (
                  [
                    // "orderVolume",
                    // "profitVolume",
                    "refundValue",
                    "exchangeIn",
                    "exchangeOut",
                    "withdrawUsdt",
                    "depositUsdt",
                    // "depositMega",
                    "exchangeOutFeeTotal",
                    "totalInvestment",
                    "directCommission",
                    "tradingCommission",
                    // "convertRecieved",
                  ].includes(column?.key)
                )
                  return reports.sumReports[column.key]
                    ? NumberUtils.toFormatNumber(+reports.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY"))
                    : `0.00000000`;
                return reports.sumReports[column.key] || "0";
              }),
            ];

            const ws = XLSX.utils.aoa_to_sheet(dataExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

            const now = new Date();
            XLSX.writeFile(wb, `System report ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
              type: "binary",
            });

            resolve(
              CreateAlert({
                type: "success",
                message: "Export data success.",
              })
            );
          } catch (error: any) {
            resolve(
              CreateAlert({
                type: "danger",
                message: error.message,
              })
            );
          }
        });
      };

      return (
        <section className="box">
          <div className="title">System report</div>

          <SystemReportDetailPopup detail={systemReportDetail} onClose={() => setSystemReportDetail(null)} />

          <div className="SystemReport">
            <div className="SystemReport__Head">
              <div className="item">
                <div className="label">Range time</div>
                <InputDateTimeRange
                  onChange={(e) => setRangeTime(e)}
                  startTimeDefaultValue={rangeTime?.startTime}
                  endTimeDefaultValue={rangeTime?.endTime}
                  format={"dd/MM/y"}
                />
              </div>
              <div className="item">
                <div className="label">Mode</div>
                <InputSelect options={ModeOptions} onChange={(e) => setModeOption(e)} onTouched={() => false} value={modeOption} name="" />
              </div>

              <div className="item">
                <div className="label">Export</div>
                <Button
                  label="Excel"
                  buttonType="primary"
                  disabled={!reports || (reports.data && reports.data.length === 0)}
                  onClick={handleExportExcel}
                />
              </div>
            </div>

            {(() => {
              if (reports && !reports.data) return <Message type="emptyData" message={moduleConfig.translate("no-data")} />;
              if (!rangeTime) return <Message type="info" message="You have to select range time to export report" />;
              if (!reports) return <Message type="loading" />;
              if (reports.error) return <Message type="error" {...reports.error} />;

              return (
                <Table
                  doubleScroll
                  hasOrderColumn
                  className="overload hideFooter"
                  structure={structure}
                  fetchData={async (params) => {
                    let data = [...reports.data];

                    if (params["date"] === "increase") data = data.sort((a: any, b: any) => a.start - b.start);
                    if (params["date"] === "descrease") data = data.sort((a: any, b: any) => b.start - a.start);

                    // if (params["orderVolume"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.orderVolume - b.orderVolume);
                    // if (params["orderVolume"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.orderVolume - a.orderVolume);

                    if (params["totalInvestment"] === "increase") data = data.sort((a: any, b: any) => a.totalInvestment - b.totalInvestment);
                    if (params["totalInvestment"] === "descrease") data = data.sort((a: any, b: any) => b.totalInvestment - a.totalInvestment);
                    
                    if (params["directCommission"] === "increase") data = data.sort((a: any, b: any) => a.directCommission - b.directCommission);
                    if (params["directCommission"] === "descrease") data = data.sort((a: any, b: any) => b.directCommission - a.directCommission);
                    
                    if (params["tradingCommission"] === "increase") data = data.sort((a: any, b: any) => a.tradingCommission - b.tradingCommission);
                    if (params["tradingCommission"] === "descrease") data = data.sort((a: any, b: any) => b.tradingCommission - a.tradingCommission);

                    if (params["userCount"] === "increase") data = data.sort((a: any, b: any) => a.userCount - b.userCount);
                    if (params["userCount"] === "descrease") data = data.sort((a: any, b: any) => b.userCount - a.userCount);

                    // if (params["userOrderCount"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.userOrderCount - b.userOrderCount);
                    // if (params["userOrderCount"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.userOrderCount - a.userOrderCount);

                    // if (params["profitVolume"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.profitVolume - b.profitVolume);
                    // if (params["profitVolume"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.profitVolume - a.profitVolume);

                    if (params["userBalanceUsdt"] === "increase") data = data.sort((a: any, b: any) => a.userBalanceUsdt - b.userBalanceUsdt);
                    if (params["userBalanceUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.userBalanceUsdt - a.userBalanceUsdt);

                    if (params["userBalanceGtm"] === "increase") data = data.sort((a: any, b: any) => a.userBalanceGtm - b.userBalanceGtm);
                    if (params["userBalanceGtm"] === "descrease") data = data.sort((a: any, b: any) => b.userBalanceGtm - a.userBalanceGtm);

                    if (params["userBalanceGtc"] === "increase") data = data.sort((a: any, b: any) => a.userBalanceGtc - b.userBalanceGtc);
                    if (params["userBalanceGtc"] === "descrease") data = data.sort((a: any, b: any) => b.userBalanceGtc - a.userBalanceGtc);

                    // if (params["userBalanceGp2"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.userBalanceGp2 - b.userBalanceGp2);
                    // if (params["userBalanceGp2"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.userBalanceGp2 - a.userBalanceGp2);

                    if (params["exchangeIn"] === "increase") data = data.sort((a: any, b: any) => a.exchangeIn - b.exchangeIn);
                    if (params["exchangeIn"] === "descrease") data = data.sort((a: any, b: any) => b.exchangeIn - a.exchangeIn);

                    if (params["exchangeOut"] === "increase") data = data.sort((a: any, b: any) => a.exchangeOut - b.exchangeOut);
                    if (params["exchangeOutFeeTotal"] === "descrease") data = data.sort((a: any, b: any) => b.exchangeOut - a.exchangeOut);

                    if (params["exchangeOutFeeTotal"] === "increase")
                      data = data.sort((a: any, b: any) => a.exchangeOutFeeTotal - b.exchangeOutFeeTotal);
                    if (params["exchangeOutFeeTotal"] === "descrease")
                      data = data.sort((a: any, b: any) => b.exchangeOutFeeTotal - a.exchangeOutFeeTotal);

                    if (params["hotWalletUsdt"] === "increase") data = data.sort((a: any, b: any) => a.hotWalletUsdt - b.hotWalletUsdt);
                    if (params["hotWalletUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.hotWalletUsdt - a.hotWalletUsdt);

                    // if (params["hotWalletUsdc"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.hotWalletUsdc - b.hotWalletUsdc);
                    // if (params["hotWalletUsdc"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.hotWalletUsdc - a.hotWalletUsdc);

                    // if (params["hotWalletBusd"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.hotWalletBusd - b.hotWalletBusd);
                    // if (params["hotWalletBusd"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.hotWalletBusd - a.hotWalletBusd);

                    if (params["withdrawUsdt"] === "increase") data = data.sort((a: any, b: any) => a.withdrawUsdt - b.withdrawUsdt);
                    if (params["withdrawUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.withdrawUsdt - a.withdrawUsdt);

                    if (params["depositUsdt"] === "increase") data = data.sort((a: any, b: any) => a.depositUsdt - b.depositUsdt);
                    if (params["depositUsdt"] === "descrease") data = data.sort((a: any, b: any) => b.depositUsdt - a.depositUsdt);

                    // if (params["depositMegadao"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.depositMega - b.depositMega);
                    // if (params["depositMegadao"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.depositMega - a.depositMega);

                    // if (params["hotWalletGp2"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.hotWalletGp2 - b.hotWalletGp2);
                    // if (params["hotWalletGp2"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.hotWalletGp2 - a.hotWalletGp2);

                    if (params["refundValue"] === "increase") data = data.sort((a: any, b: any) => a.refundValue - b.refundValue);
                    if (params["refundValue"] === "descrease") data = data.sort((a: any, b: any) => b.refundValue - a.refundValue);

                    // if (params["orderCount"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.orderCount - b.orderCount);
                    // if (params["orderCount"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.orderCount - a.orderCount);

                    if (params["loginCount"] === "increase") data = data.sort((a: any, b: any) => a.loginCount - b.loginCount);
                    if (params["loginCount"] === "descrease") data = data.sort((a: any, b: any) => b.loginCount - a.loginCount);

                    // if (params["convertRecieved"] === "increase")
                    //   data = data.sort((a: any, b: any) => a.convertRecieved - b.convertRecieved);
                    // if (params["convertRecieved"] === "descrease")
                    //   data = data.sort((a: any, b: any) => b.convertRecieved - a.convertRecieved);

                    return {
                      data: [{ isSum: true }, ...data],
                      count: data.length,
                    };
                  }}
                />
              );
            })()}
          </div>
        </section>
      );
    },
    () => true
  )
);

export const SystemReportDetailPopup: FC<{
  detail: any;
  onClose: () => void;
}> = (props) => {
  if (!props.detail) return null;

  const item = props.detail;

  const data = [
    {
      label: "Start",
      value: `${new Date(item.start).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "End",
      value: `${new Date(item.end).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Modified",
      value: `${new Date(item.modified).toLocaleDateString(getLocaleKey(), {
        hour12: false,
      })} - ${new Date(item.start).toLocaleTimeString(getLocaleKey(), {
        hour12: false,
      })}`,
    },
    {
      label: "Total won/lose volume",
      value: `${(+item.wonVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}/${(+item.lostVolume).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
      })}`,
    },
    {
      label: "Hot Wallet",
      value: (
        <span>
          {/* GPO:{" "}
          {(+item.hotWalletGp2).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          <br /> */}
          GT:{" "}
          {(+item.hotWalletUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
          {/* BUSD:{" "}
          {(+item.hotWalletBusd).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "} */}
          {/* <br />
          USDC:{" "}
          {(+item.hotWalletUsdc).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "} */}
        </span>
      ),
    },
    {
      label: "Deposit",
      value: (
        <span>
          {/* ETH:{" "}
          {(+item.depositEth).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          <br /> */}
          GT:{" "}
          {(+item.depositUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      label: "Withdraw",
      value: (
        <span>
          {/* GPO:{" "}
          {(+item.withdrawGp2).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          <br /> */}
          GT:{" "}
          {(+item.withdrawUsdt).toLocaleString(getLocaleKey(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
        </span>
      ),
    },
    // {
    //   label: "User 's Balance",
    //   value: (
    //     <span>
    //       MEGADAO:{" "}
    //       {(+item.userBalanceGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       {/* USD: {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} <br /> */}
    //       USDT:{" "}
    //       {(+item.userBalanceUsd).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       {/* BUT: {(+item.userBalanceBut).toLocaleString(getLocaleKey(), { maximumFractionDigits: 2, minimumFractionDigits: 2 })} */}
    //     </span>
    //   ),
    // },
    // {
    //   label: "Safe",
    //   value: (
    //     <span>
    //       GPO:{" "}
    //       {(+item.safeGp2).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}{" "}
    //       <br />
    //       USDT:{" "}
    //       {(+item.safeUsdt).toLocaleString(getLocaleKey(), {
    //         maximumFractionDigits: 2,
    //         minimumFractionDigits: 2,
    //       })}
    //     </span>
    //   ),
    // },
    {
      label: "Login Count",
      key: "loginCount",
      value: item.loginCount,
    },
    {
      label: "Update VIP Commission",
      key: "directCommission",
      value: item.directCommission,
    },
    {
      label: "CashBack",
      key: "cashBack",
      value: (+item.cashBack).toLocaleString(getLocaleKey(), {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    },
    {
      label: "New Package",
      key: "newPackage",
      value: item.newPackage,
    },
    {
      label: "Trading Commission",
      key: "rankCommission",
      value: item.rankCommission,
    },
  ];

  return (
    <div
      className="SystemReport__DetailPopup"
      id="SystemReport__DetailPopup"
      onClick={(e: any) => (e.target.id === "SystemReport__DetailPopup" ? props.onClose() : null)}
    >
      <div className="box">
        <div className="boxTitle">
          <span>Detail Report</span>
          <div className="btnClose" onClick={() => props.onClose()}>
            <Icon.Close />
          </div>
        </div>
        <div className="content">
          <table>
            <tbody>
              {data.map((row, key) => (
                <tr key={key}>
                  <td>{row.label}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
