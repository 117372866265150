import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Routes } from "../../../../AppRoutes";
import { Icon } from "../../../../components";

export const GameManagementLayout = (Component: FC<any>) => (props: any) => {
  return (
    <div className="game-management-layout">
      <div className="TabLinks">
        <NavLink to={Routes.OrdersPage.path} exact={true}>
          <Icon.OrderIcon />
          Orders
        </NavLink>

        <NavLink
          to={Routes.SupplierListPage.path}
          exact={true}
          isActive={() => {
            return props?.location?.pathname?.includes("/game-management/supplier");
          }}
        >
          <Icon.SupplierIcon />
          Suppliers
        </NavLink>

        <NavLink
          to={Routes.GameListPage.path}
          exact={true}
          isActive={() => {
            return props?.location?.pathname?.includes("/game-management/game");
          }}
        >
          <Icon.GameIcon />
          Games
        </NavLink>
      </div>
      <Component {...props} />
    </div>
  );
};
