import React, { useState } from "react";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, Icon, ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { withStartupWraper } from "../wrapper";
import moment from "moment";
import { translate } from "../../../languages";
import { StartupService } from "../../../services/startup";
import XLSX from 'xlsx';
import { getEnv } from "../../../configs";
import { PopupWraper } from "../../../components/popup";


export const ApprovalForCallingCapital = withStartupWraper(() => {
  const [params, setParams] = useState([] as any[]);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
  const [disableExport, setDisableExport] = useState<boolean>(true);
  const [approveStartUp, setApproveStartUp] = useState<any>({})

  const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");
  const [isRequesting, setIsRequesting] = useState<boolean>(false);


  const structure: ITableStructureItem[] = [
    {
      name: "Project's name",
      key: "projectName",
      render: (item) => (!!item.projectName ? item.projectName : "--"),
    },
    {
      name: "Representative email",
      key: "representativeEmail",
    },
    {
      name: "Created date",
      key: "created",
      render: (item) => {
        return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
    {
      name: translate("status"),
      key: "activeStatus",
      render: (item) => (
        <span 
          className={`status status--${item?.activeStatus === 0 ? "activated" : item?.activeStatus === 1 ? "deactived" : "not_showed"}`}
        >
          {item?.activeStatus === 0 ? translate("ACTIVATED") : item?.activeStatus === 1 ? translate("DEACTIVATED") : translate("NOT_SHOWED") }
        </span>
      ),
    },
    {
      name: 'Actions',
      key: 'actions',
      render: (item) => {
        return (
          <div className="action">
            <div
              className="action__more"
              onClick={() => setApproveStartUp({id: item?.agencyId, name: item?.projectName})}
            >
              <Icon.ActionEditIcon />
            </div>
          </div>
        );
      }
    }
  ];

  const handleConfirm = (id: any) => {
    setIsRequesting(true);
    StartupService.approveCallingCapitalStartup(id).then(() => {
      setIsRequesting(false);
      setForceUpdateTable(Math.random())
      setApproveStartUp({})
    })

  }

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await StartupService.getStartupInvestmentHistory({
          ...params,
          page: 1,
          pageSize: 10000,
        });

        const data = response.data;

        let fileHead:any = structure.map((v) => v.name);
        // fileHead.pop(); //remove last column on table
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            structure.map((column, index) => {
              if (column.key === "created") return moment(item[column.key]).format("DD/MM/y HH:mm:ss");
              if (column.key === "projectName") return !!item.projectName ? item.projectName : "--";
              if (column.key === "amount") return  NumberUtils.toFormatNumber(+item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY"));
              if (!column.key) return "";
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(
          wb,
          `Investment Hisotry List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
            .toLocaleTimeString()
            .replace(/:/g, "-")}.xlsx`,
          { type: "binary" }
        );

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error:any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <div className="ApprovalForCallingCapital">
      {/* <Button
          className="mb20"
          label="Export to Excel"
          buttonType="primary"
          disabled={disableExport}
          onClick={handleExportExcel}
        /> */}

      <Table
        hasOrderColumn
        filters={[
          {
            name: "Search by project's name, representative email",
            key: "searchString",
            input: TableFilterInputText,
          },
          // {
          //   name: translate("time"),
          //   key: "created",
          //   input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
          //   defaultValue: {
          //     fromDate: startOfMonth, //new Date(last1MothDate),
          //     toDate: endOfDay, //new Date(),
          //   },
          // },
        ]}
        structure={structure}
        isOpenFilterBox
        fetchData={async (state) => {
          let params = { ...state };
          // if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          // if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          setParams(params);
          return StartupService.startupApprovalForCallingCapitalList({
            ...params,
            page: params.pageNumber,
            pageSize: params.limit,
          }).then((res) => {
            setDisableExport(!(res?.data?.length > 0))
            return res;
          });
        }}
        forceUpdateTable={forceUpdateTable}
      />

        {approveStartUp?.id && (
          <PopupWraper center title="Change Status" onClose={() => setApproveStartUp({})}>
            <div className="mb8">
             {`Do you want to approve "${approveStartUp?.name}" project?`}
            </div>
            <div className="d-flex justify-content-center mt24">
              <Button 
                label={"Confirm"}
                onClick={() => handleConfirm(approveStartUp?.id)}
                isLoading={isRequesting}
                disabled={isRequesting}
              />
            </div>
          </PopupWraper>
        )}
    </div>
  );
});
