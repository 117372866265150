import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import { InputCheckbox } from "../../../components";
import { CreateAlert, onError } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";
import { withConfigWraper } from "../wraper";

type Props = {};

const RolePermission = withConfigWraper((props: Props) => {
  const rolePermissionConfig = useSelector((state) => state.rolePermissionConfig);
  const user = useSelector((state) => state.user);

  const history = useHistory();

  const checkConfig = (id: number, status: boolean) => {
    return ConfigService.updateRolePermissionServer(id, status)
      .then(async () => {
        CreateAlert({ type: "success", message: "Set config maintenance successful." });
        await ConfigService.getServerConfigRolePermission(store);
      })
      .catch((err) => {
        onError(err);
      });
  };

  const renderRolePermissionAdmin = () => {
    return rolePermissionConfig.ADMIN?.sort((a: any, b: any) => {
      return a.description.localeCompare(b.description);
    })?.map((item: any, index: number) => {
      return (
        <div key={index} className="col-sm-6">
          <InputCheckbox
            defaultValue={item?.isActive}
            type={"checkbox"}
            label={item?.description}
            value={item?.isActive}
            onChange={(e) => checkConfig(item?.userPermissionId, e)}
            name=""
            onTouched={() => true}
          />
        </div>
      );
    });
  };

  const renderRolePermissionViewer = () => {
    return rolePermissionConfig.VIEWER?.sort((a: any, b: any) => {
      return a.description.localeCompare(b.description);
    })?.map((item: any, index: number) => {
      return (
        <div key={index} className="col-sm-6">
          <InputCheckbox
            defaultValue={item?.isActive}
            type={"checkbox"}
            label={item?.description}
            value={item?.isActive}
            onChange={(e) => checkConfig(item?.userPermissionId, e)}
            name=""
            onTouched={() => true}
          />
        </div>
      );
    });
  };

  if (!(user.userRole === "SUPER_ADMIN" || user.userRole === "ADMIN"))
    history.push(Routes.withdrawConfig.path);

  return (
    <div className="row mb20 role__permission">
      {user.userRole === "SUPER_ADMIN" && (
        <div className="col-sm-4 mr40">
          <h1>Admin</h1>
          <div className="row ml3 role__permission--item">{renderRolePermissionAdmin()}</div>
        </div>
      )}
      {/* <div className="col-sm-4">
        <h1>Viewer</h1>
        <div className="row ml3 role__permission--item">{renderRolePermissionViewer()}</div>
      </div> */}
    </div>
  );
});

export default RolePermission;
