import { RequestMainService } from '../request';
import { CookieService, ECookieVariable } from '../cookie';
import { ObjectUtils } from '../../modules';
import moment from 'moment';

export class AdminService {
  static async getListUser(params: any = {}) {
    return RequestMainService.get(`/admin/user`, params).then(({ result }) => ({
      count: result.total,
      data: result.users
    }));
  }

  static getListUserStatus() {
    return [
      // { label: "KYC_CONFIRMED", value: "KYC_CONFIRMED" },
      { label: 'Email Verified', value: 'EMAIL_VERIFIED' },
      { label: 'Just Register', value: 'JUST_REGISTERED' }
    ];
  }

  static async getUserDetailByEmail(email: string) {
    return await RequestMainService.get(`/user/public-info/${email}`);

    // return {
    //     userInfo: userInfoRes.result,
    // };
  }

  static async getUserDetail(userId: number) {
    return RequestMainService.get(`/admin/get-user?userId=${userId}`);
  }

  static async getListTransactions(params: any = {}) {
    return RequestMainService.get(`/admin/transaction`, params).then(
      ({ result }) => ({
        count: result.total,
        totalValue: result.totalValue,
        data: result.transactions
      })
    );
  }

  static async getAgencyWithVendingMachineList(params: any = {} ) {
    return RequestMainService.get(`/admin/agencies/vending-machines`, params).then((res) => {
      return {
        count: res?.result?.total || 0,
        data: res?.result?.data || []
      }
    })
  }

  static async getVendingMachineList(params: any = {} ) {
    return RequestMainService.get(`/admin/vending-machines`, params).then((res) => {
      return {
        count: res?.result?.total || 0,
        data: res?.result?.data || []
      }
    })
  }

  static async getVendingMachineTransactionByAgency(agencyId: number, params: any = {} ) {
    return RequestMainService.get(`/admin/agencies/${agencyId}/vending-machines/transactions`, params).then((res) => {
      return {
        count: res?.result?.total || 0,
        data: res?.result?.transactions || [],
        totalDeposit: res?.result?.totalDeposit || 0,
        totalWithdraw: res?.result?.totalWithdraw || 0,
      }
    })
  }

  static async getVendingMachineTransactionByMachineId(params: any = {} ) {
    return RequestMainService.get(`/admin/vending-machines/transactions`, params).then((res) => {
      return {
        count: res?.result?.total || 0,
        data: res?.result?.transactions || [],
        totalDeposit: res?.result?.totalDeposit || 0,
        totalWithdraw: res?.result?.totalWithdraw || 0,
      }
    })
  }

  static async getMachineIdByAgency(agencyId: number) {
    return RequestMainService.get(`/admin/agencies/${agencyId}/vending-machines`);
  }

  static async getVendingMachineWallet() {
    return RequestMainService.get(`/admin/vending-machines/balance`);
  }

  static async getListTransactionsAdminWithdraw(params: any = {}) {
    return RequestMainService.get(`/admin/transaction-admin-withdraw`, params);
  }

  static async getMarketInfo(coinId: any) {
    return RequestMainService.get(
      `/market-info/${CookieService.get(
        ECookieVariable.USER_SYMBOL_ACTIVE_ID
      )}/${coinId}`
    ).then(({ result }) => result);
  }

  static async getPendingKYC() {
    return RequestMainService.get(`/admin/kycs/pending`).then(({ result }) => ({
      count: result.length,
      data: result
    }));
  }

  static async getKYCList(params: any) {
    return RequestMainService.get(`/admin/kycs`, params).then(({ result }) => ({
      count: result.total,
      data: result.kycs
    }));
  }

  static async getListWithdrawProgress(params: any = {}) {
    return RequestMainService.get(`/admin/withdraw/history`, params).then(
      ({ result }) => {
        // const data = result.withdrawProgresses.filter((v: any) => v.status === "JUST_CREATED");
        return {
          count: result?.total,
          data: result?.withdrawProgresses
        };
      }
    );
  }

  static async interventionLimit(amount: number) {
    return RequestMainService.post(`/game-setting/intervention-limit`, {
      interventionLimit: amount
    });
  }

  static async budgetAmount(amount: number) {
    return RequestMainService.post(`/game-setting/budget-limit`, {
      budgetAmount: amount
    });
  }

  static async budgetPercent(budgetPercent: number) {
    return RequestMainService.post(`/game-setting/budget-percent`, {
      budgetPercent: budgetPercent
    });
  }

  static async getBudgetAmount() {
    return RequestMainService.get(`/game-setting/budget-limit`).then(
      (res) => res.result
    );
  }

  static async getBudgetPercent() {
    return RequestMainService.get(`/game-setting/budget-percent`).then(
      (res) => res.result
    );
  }

  static async getInterventionLimit() {
    return RequestMainService.get(`/game-setting/intervention-limit`).then(
      (res) => res.result
    );
  }

  static async getAffiliation(numberOflevelsFromRoot: any, rootUserId: any) {
    return RequestMainService.get(
      `/admin/tree/${numberOflevelsFromRoot}/${rootUserId}?isHideBalance=true`
    ).then((res) => res.result);
  }

  static async getAffiliateDetail(userId: any) {
    return RequestMainService.get(`/tree-detail/${userId}`);
  }

  static async getReferralSponsor(userId: any, numberOflevels: any) {
    return RequestMainService.get(`/parents/${numberOflevels}/${userId}`);
  }

  static async getFranchiseUserReport(
    numberOflevelsFromRoot: any,
    rootUserId: any,
    params: any = {}
  ) {
    const offset = ObjectUtils.getIn(params, 'offset', 0);
    const limit = ObjectUtils.getIn(params, 'limit', 10);

    return RequestMainService.get(
      `/report-tree/${numberOflevelsFromRoot}/${rootUserId}`
    ).then((res) => {
      let data = [...res.result];
      if (typeof params.q === 'string')
        data = data.filter((v) => {
          return (
            `${v.firstName} ${v.lastName}`
              .toLowerCase()
              .search(params.q.toLowerCase()) > -1 ||
            v.email.toLowerCase().search(params.q.toLowerCase()) > -1
          );
        });

      const paramKeys = [
        'wonVolume',
        'lostVolume',
        'rankCommission',
        'directCommission',
        'cashBack',
        'agencyCount'
      ];

      paramKeys.map((sortKey) => {
        if (params[sortKey]) {
          if (params[sortKey] === 'increase')
            data = data.sort((a, b) => a[sortKey] - b[sortKey]);
          if (params[sortKey] === 'descrease')
            data = data.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return sortKey;
      });

      return {
        data: data.slice(offset, offset + limit),
        count: data.length,
        full: data
      };
    });
  }

  static async getFranchiseLeaderReport(
    numberOflevelsFromRoot: any,
    rootUserId: any,
    params: any = {}
  ) {
    const offset = ObjectUtils.getIn(params, 'offset', 0);
    const limit = ObjectUtils.getIn(params, 'limit', 10);

    return RequestMainService.get(
      `/leader-tree/${numberOflevelsFromRoot}/${rootUserId}`
    ).then((res) => {
      let data = [...res.result];
      if (typeof params.q === 'string')
        data = data.filter((v) => {
          return (
            `${v.firstName} ${v.lastName}`
              .toLowerCase()
              .search(params.q.toLowerCase()) > -1 ||
            v.email.toLowerCase().search(params.q.toLowerCase()) > -1
          );
        });

      const paramKeys = [
        'wonVolume',
        'lostVolume',
        'rankCommission',
        'directCommission',
        'cashBack',
        'agencyCount'
      ];

      paramKeys.map((sortKey) => {
        if (params[sortKey]) {
          if (params[sortKey] === 'increase')
            data = data.sort((a, b) => a[sortKey] - b[sortKey]);
          if (params[sortKey] === 'descrease')
            data = data.sort((a, b) => b[sortKey] - a[sortKey]);
        }

        return sortKey;
      });

      return {
        data: data.slice(offset, offset + limit),
        count: data.length,
        full: data
      };
    });
  }

  static async getFanshiseLeaderUserReport(userId: number, params: any = {}) {
    return RequestMainService.get(
      `/admin/branch-report/${userId}`,
      params
    ).then(({ result }) =>
      result.sort((a: any, b: any) => b.startAt - a.startAt)
    );
  }

  static async getSytemReport(params: any = {}) {
    return RequestMainService.get(`/admin/system-report`, params).then(
      (res) => res.result
    );
  }

  // static async kycApprove(kycId: string) {
  //   return RequestMainService.post(`/admin/kyc/approve/${kycId}`);
  // }

  // static async kycReject(kycId: string) {
  //   return RequestMainService.post(`/admin/kyc/reject/${kycId}`);
  // }

  static async kycApprove(kycId: any, reason: string) {
    return RequestMainService.post(`/admin/kyc/approve`, { kycId });
  }

  static async kycReject(kycId: any, reason: string) {
    return RequestMainService.post(`/admin/kyc/reject`, { kycId, reason });
  }

  static async withdrawApprove(withdrawId: string) {
    return RequestMainService.post(`/admin/withdraw/accept/${withdrawId}`);
  }

  static async withdrawReject(withdrawId: string) {
    return RequestMainService.post(`/admin/withdraw/reject/${withdrawId}`);
  }

  static async setRank(userId: number, rank: number) {
    return RequestMainService.post(`/privilege`, { userId, rank });
  }

  static async freezeUsers(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user`, { userIds });
  }

  static async unfreezeUsers(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user`, { userIds });
  }

  static async enableUsersTwoFa(userIds: number[]) {
    return RequestMainService.post(`/admin/enable-user-two-fa`, {
      userIds
    });
  }

  static async disableUsersTwoFa(userIds: number[]) {
    return RequestMainService.post(`/admin/disable-user-two-fa`, {
      userIds
    });
  }

  static async freezeUsersTransfer(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user-transfer`, {
      userIds
    });
  }

  static async unfreezeUsersTransfer(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user-transfer`, {
      userIds
    });
  }

  static async freezeUsersWithdraw(userIds: number[]) {
    return RequestMainService.post(`/admin/freeze-user-withdraw`, {
      userIds
    });
  }

  static async unfreezeUsersWithdraw(userIds: number[]) {
    return RequestMainService.post(`/admin/unfreeze-user-withdraw`, {
      userIds
    });
  }

  static async updateProfile(data: any) {
    return RequestMainService.put(`/admin/profile-user`, { ...data });
  }

  static async supportDeposit(blockNumberList: string) {
    return RequestMainService.post(`/admin/deposit/support`, {
      blockNumberList
    });
  }

  static async startWithdrawToAdminWallet() {
    return RequestMainService.post(`/admin/run-withdraw-for-admin`, {});
  }

  static async getListRequestClaim(params: any = {}) {
    return RequestMainService.get(`/admin/request-claim`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data
      })
    );
  }

  // static async getListGameOrders(params: any = {}) {
  //   return RequestMainService.get(`/admin/order-game`, params).then(({ result }) => ({
  //     count: result?.total,
  //     data: result?.data,
  //   }));
  // }

  static async handleRequestClaim(payload: any) {
    return RequestMainService.put(`/admin/request-claim`, payload);
  }

  static async getListAffilateTable(params: any = {}) {
    if (params.fromDate)
      params.fromDate = moment(params.fromDate)
        .utcOffset(0, true)
        .toISOString();
    if (params.toDate)
      params.toDate = moment(params.toDate).utcOffset(0, true).toISOString();
    return RequestMainService.get(`/admin/affilate-table`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data
      })
    );
  }

  static async getListLevelSettingHistory(params: any = {}) {
    params.fromDate = moment(params.fromDate).toISOString();
    params.toDate = moment(params.toDate).toISOString();
    return RequestMainService.get(`/admin/affiliation-rank`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data
      })
    );
  }

  static async setLevel(userId: number, rank: number) {
    return RequestMainService.put(`/admin/update-rank`, { userId, rank });
  }

  static async getOrdersForClaimRequest(experienceGameId: any) {
    return RequestMainService.get(
      `/admin/experience-game/${experienceGameId}/bet`
    )
      .then(({ result }) => ({
        count: result?.length,
        data: result
      }))
      .catch((err) => ({
        count: 0,
        data: []
      }));
  }

  static async getListRole() {
    return RequestMainService.get(`/admin/user-roles`).then((res) => {
      return res.result.userRoles;
    });
  }

  static async getListSupplier(params: any = {}) {
    return RequestMainService.get(`/admin/suppliers`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data
      })
    );
  }

  static async getSupplierDetail(supplierId: number) {
    return RequestMainService.get(`/admin/suppliers/${supplierId}`);
  }
  static async activeSupplier(supplierId: any, value: any) {
    return RequestMainService.put(`/admin/suppliers/${supplierId}/${value}`);
  }

  static async updateSupplier(data: any) {
    return RequestMainService.put(`/admin/suppliers/${data.supplierId}`, {
      ...data
    });
  }

  static async addSupplier(data: any) {
    return RequestMainService.post(`/admin/suppliers`, { ...data });
  }

  static async swapSupplier(supplierId: number, type: string) {
    return RequestMainService.put(
      `/admin/suppliers/${supplierId}/swap/${type}`
    );
  }

  static async getListGameOrders(params: any = {}) {
    return RequestMainService.get(`/admin/order-game`, params).then(
      ({ result }) => ({
        count: result?.total,
        data: result?.data,
        totalAmount: result?.totalAmount,
        totalProfit: result?.totalProfit,
        totalUserOrder: result?.totalUserOrder
      })
    );
  }

  static async getGameList(params: any = {}) {
    return RequestMainService.get(`/admin/games`, params).then(
      ({ result }) => ({
        count: result?.total,
        data: result?.data
      })
    );
  }

  static async getCategoryList() {
    return RequestMainService.get('/get-all-category');
  }

  static async getSupplierList() {
    return RequestMainService.get('/get-all-supplier');
  }

  static async CreateGame(payload: any) {
    return RequestMainService.post(`/admin/games`, payload);
  }

  static async getGameDetail(gameId: any) {
    return RequestMainService.get(`/admin/games/${gameId}`);
  }

  static async EditGame(payload: any, gameId: any) {
    return RequestMainService.put(`/admin/games/${gameId}`, payload);
  }

  static async SwapGame(gameId: any, type: any) {
    return RequestMainService.put(`/admin/games/${gameId}/swap/${type}`);
  }

  static async ActiveGame(gameId: any, value: any) {
    return RequestMainService.put(`/admin/games/${gameId}/${value}`);
  }

  static async getListExperienceGame(params: any = {}) {
    return RequestMainService.get(`/admin/experience-game`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data,
        totalPackage: result?.totalPackage,
        totalUser: result?.totalUser
      })
    );
  }

  static async resetLevel(userId: number) {
    return RequestMainService.put(`/admin/reset-rank/${userId}`);
  }

  static async receiveTransfer(params: any) {
    return RequestMainService.post('/admin/receive-transfer', params);
  }

  static async sendTransfer(params: any) {
    return RequestMainService.post('/admin/send-transfer', params);
  }

  static async getListCommissionHistoryFranchise(params: any = {}) {
    return RequestMainService.get(`/admin/commission-histories`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data,
        totalAmount: result?.totalAmount
      })
    );
  }

  static async getAgencyList(params: any) {
    return RequestMainService.get('/admin/agencies', params).then(
      ({ result }) => ({
        count: result?.total,
        data: result?.data
      })
    );
  }

  static async lockAgency(payload: any) {
    return RequestMainService.post(`/admin/agency/lock`, payload);
  }

  static async createAgency(payload: any) {
    return RequestMainService.post(`/admin/agency/add-agency`, payload);
  }

  static async getAgencyDetail(agencyId: any) {
    return RequestMainService.get(`/admin/agency/${agencyId}`);
  }

  static async editAgency(payload: any) {
    return RequestMainService.post(`/admin/agency/edit`, payload);
  }

  static async deleteAgency(agencyId: any) {
    return RequestMainService.get(
      `/admin/agency/delete-agency?agencyId=${agencyId}`
    );
  }

  static async updateActiveStatusAgency(payload: any) {
    return RequestMainService.post(
      `/admin/startup/edit-active-status-startup`,
      payload
    );
  }
  // static async getListOrder() {
  //   return {
  //     count: 3,
  //     data: [
  //       {
  //         orderId: '10313121',
  //         name: 'Áo GTC',
  //         email: 'customer1@gmail.com',
  //         address: 'Gò Vấp',
  //         type: 'product',
  //         agency: 'GTC 008',
  //         date: '21:19, Sun 18/02/2024',
  //         quantity: 10,
  //         revenue: 10,
  //         status: 'Completed',
  //         receive: true
  //       },
  //       {
  //         orderId: '2213546',
  //         name: 'Áo GTC1',
  //         email: 'customer2@gmail.com',
  //         address: 'Gò Vấp',
  //         type: 'product',
  //         agency: 'GTC 008',
  //         date: '21:19, Sun 18/02/2024',
  //         quantity: 10,
  //         revenue: 10,
  //         status: 'Cancelled',
  //         receive: true
  //       },
  //       {
  //         orderId: '2213547',
  //         name: 'Áo GTC1',
  //         email: 'customer3@gmail.com',
  //         address: 'Gò Vấp',
  //         type: 'product',
  //         agency: 'GTC 008',
  //         date: '21:19, Sun 18/02/2024',
  //         quantity: 10,
  //         revenue: 10,
  //         status: 'Delivery Waiting',
  //         receive: true
  //       }
  //     ]
  //   };
  // }

  static async getListOrder(params: any = {}) {
    return RequestMainService.get(`/admin/product-orders`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data
      })
    );
  }
  static async getOrderDetail(id: number) {
    return RequestMainService.get(`/admin/product-orders/${id}`).then(
      ({ result }) => ({
        data: result?.data
      })
    );
  }

  static async updateStatusOrder(
    orderId: number,
    status: string,
    reason: string
  ) {
    return RequestMainService.post(`/orders/change-status`, {
      orderId,
      status,
      reason
    });
  }

  static async getListAgency(params: any = {}) {
    return RequestMainService.get(`/admin/agency-report-revenues`, params).then(
      ({ result }) => ({
        count: result.total,
        data: result.data
      })
    );
  }

  static async getRevenueBelowAgent(id: any, params: any = {}) {
    return RequestMainService.get(
      `/admin/agency-report-revenues/${id}`,
      params
    ).then(({ result }) => ({
      count: result.total,
      data: result.data
    }));
  }
  static getListAgencyOptions() {
    let options: any[] = [];
    AdminService.getListAgency().then((res: any) => {
      let data = res?.data || [];
      options = data.map((element: any) => ({
        label: data?.agencyName,
        value: data?.agencyId
      }));
      console.log(options);
    });
    console.log(options);
    return options;
    // });
  }

  // static async getListAgency() {
  //   return RequestMainService.get(
  //     '/admin/agency-report-revenues?page=1&pageSize=10'
  //   ).then((result) => {
  //     console.log(result);
  //   });
  // }

  static async grantBalanceByMachineId(payload: any) {
    return RequestMainService.post(`/admin/vending-machines/grant-balance`, payload);
  }

  static async getGrantLimitHistoryList(params: any = {} ) {
    return RequestMainService.get(`/admin/vending-machines/grant-limit/histories`, params).then((res) => {
      return {
        count: res?.result?.total || 0,
        data: res?.result?.data || []
      }
    })
  }
}
