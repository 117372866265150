import React, { Fragment, memo, useState } from 'react'

import { TableScroll, Button, onError, ObjectUtils, Table, CreateAlert } from '../../../modules';
import { translate, getLocaleKey } from '../../../languages';
import { TableFilterRangeTimeInput, TableFilterInputText } from '../../../components/table-filter-inputs';
import { AdminService } from '../../../services/admin';
import { withTransactionWraper } from '../wraper';
import { useSelector } from '../../../store';
import { TableFilterInputSelect } from '../../../components/table-filter-inputs/select';
import _ from 'lodash';
import moment from 'moment';
import { GeneralPopupCompnt } from '../../../components/general-popup-compnt';

export const WithdrawProgress = withTransactionWraper(memo(() => {
    const StatusFilterOptions = [
        { label: "JUST CREATED", value: "JUST_CREATED" },
        { label: "SUCCESS", value: "SUCCESS" },
        { label: "REJECTED", value: "REJECTED" },
        { label: "PROCESSING", value: "PROCESSING" }
    ];

    enum EActionType {
        APPROVE = "approve",
        REJECT = "reject"
    }

    const coinsState = useSelector(state => state.coins);
    const coinsOptions = ObjectUtils.getIn(coinsState, 'data', [], arr => arr.map((item: any) => ({ label: item.name, value: item.coinId })))

    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [actionType, setActionType] = useState<EActionType>();
    const [item, setItem] = useState<any>();
    const [fetchData, setFetchData] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(false);

    let handleOnClickApproveButton = (item: any, fetchData:any) => {
        setItem(item);
        setFetchData(fetchData);
        setActionType(EActionType.APPROVE);
        setIsShowPopup(true);
    }
    let handleOnClickRejectButton = (item: any, fetchData:any) => {
        setItem(item);
        setFetchData(fetchData);
        setActionType(EActionType.REJECT);
        setIsShowPopup(true);
    }
    let handleOnClickConfirm = (actionType:any, item:any, fetchData:any) => {
        setIsLoading(true);
        if (actionType === EActionType.APPROVE) {
            AdminService.withdrawApprove(item?.transactionRequestId)
            .then((res) => {
                fetchData();
                CreateAlert({message: res?.status, type: 'success'})
            })
            .catch(onError)
            .finally(() => {
                setIsLoading(false);
                setIsShowPopup(false);
            })
        }

        if (actionType === EActionType.REJECT) {
            AdminService.withdrawReject(item?.transactionRequestId)
            .then((res) => {
                fetchData();
                CreateAlert({message: res?.status, type: 'success'})
            })
            .catch(onError)
            .finally(() => {
                setIsLoading(false);
                setIsShowPopup(false);
            })
        }
    }

    return (
        <div className="WithdrawProgress">
            <Table
                hasOrderColumn
                itemPerPages={10}
                filters={[
                    {
                        name: 'Time',
                        key: 'created',
                        input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
                        defaultValue: {
                            fromDate: moment()
                            .subtract(3, "months")
                            .startOf("day")
                            .format("YYYY/MM/DD HH:mm:ss"),
                            toDate: moment().endOf("day").format("YYYY/MM/DD HH:mm:ss"),
                        },
                    },
                    {
                        name: 'Email',
                        key: 'email',
                        input: TableFilterInputText
                    },
                    {
                        name: "Status",
                        key: "status",
                        input: (e) => (
                            <TableFilterInputSelect
                                isClearable={true}
                                {...e}
                                options={StatusFilterOptions}
                                isSearchable={false}
                            />
                        ),
                        defaultValue: "JUST_CREATED",
                    },
                ]}
                structure={[
                    {
                        name: 'Coin',
                        key: 'coinId',
                        render: (item) => {
                            const coin = coinsOptions.find((v: any) => v.value === item.coinId);
                            return ObjectUtils.getIn(coin, 'label', '--');
                        },
                    },
                    {
                        name: translate('time'),
                        key: 'created',
                        render: (item) => {
                            return <span>
                                {moment(item?.created).format('DD/MM/y HH:mm:ss')}
                            </span>
                        },
                    },
                    {
                        name: 'Email',
                        key: 'email',
                        className: 'email'
                    },
                    {
                        name: 'Value',
                        key: 'value',
                        render: (item) => (+item.value).toLocaleString(getLocaleKey(), { maximumFractionDigits: 4, minimumFractionDigits: 4 })
                    },
                    {
                        name: 'Status',
                        key: 'status',
                        render: (item: any) => (
                            <span
                                className={`status status--${item?.status?.toLowerCase()}`}
                            >
                                {_.startCase(
                                    _.toUpper(translate(item?.status))
                                )}
                            </span>
                        ),
                    },
                    {
                        name: 'Actions',
                        render: (item, fetchData) => {
                            return <Fragment>
                                <Button
                                    className="mr5"
                                    type="button"
                                    buttonType="success-outline"
                                    label="Approve"
                                    onClick={() => handleOnClickApproveButton(item, () => fetchData)}
                                    disabled={item?.status!=='JUST_CREATED'}
                                />

                                <Button
                                    type="button"
                                    buttonType="danger-outline"
                                    label="Reject"
                                    onClick={() => handleOnClickRejectButton(item, () => fetchData)}
                                    disabled={item?.status!=='JUST_CREATED'}
                                />
                            </Fragment>
                        }
                    },
                ]}
                fetchData={async state => {
                    let params = { ...state };
                    if (params['fromDate']) params['fromDate'] = new Date(params['fromDate']);
                    if (params['toDate']) params['toDate'] = new Date(params['toDate']);

                    return AdminService.getListWithdrawProgress({
                        page: params.pageNumber,
                        numberOfItemsPerPage: params.limit,
                        ...params
                    })
                }}
            />
            {isShowPopup && (
                <GeneralPopupCompnt
                onClose={() => {
                    setIsShowPopup(false);
                }}
                onClickConfirm={() => handleOnClickConfirm(actionType, item, fetchData)}
                textButton="OK"
                titlePopup={"Notification!"}
                messagePopup={`Are you sure to ${actionType}`}
                isLoading={isLoading}
                />
            )}
        </div>
    )
}))