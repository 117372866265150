import React from "react";
import { InputCheckbox } from "../../../components";
import { CreateAlert, onError } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";
import { withConfigWraper } from "../wraper";

export const MaintenanceConfig = withConfigWraper(() => {
  const maintenanceConfig = useSelector((state) => state.maintenanceConfig);
  const maintenanceJobServiceConfig = useSelector((state) => state.maintenanceJobServiceConfig);

  const checkConfig = () => {
    if (maintenanceConfig) {
      const body = {
        enable: false,
      };
      console.log("body", body);
      return ConfigService.switchMaintenanceServer(body)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config maintenance successful." });
          await ConfigService.getServerConfigMaintenance(store);
        })
        .catch(onError);
    } else {
      const body = {
        enable: true,
      };
      console.log("body", body);
      return ConfigService.switchMaintenanceServer(body)
        .then(async () => {
          CreateAlert({ type: "success", message: "Set config maintenance successful." });
          await ConfigService.getServerConfigMaintenance(store);
        })
        .catch(onError);
    }
  };

  const checkConfigMaintenanceJobService = () => {
    return ConfigService.swithMaintenanceJobService(!maintenanceJobServiceConfig)
    .then(async () => {
      CreateAlert({ type: "success", message: "Set config maintenance successful." });
      await ConfigService.getJobServiceConfigMaintenance(store);
    })
    .catch(onError);
  };

  return (
    <div className="row mb20">
      <div className="col-sm-5">
        <form className="">
          <InputCheckbox
            type={"checkbox"}
            label={"Maintenance Server"}
            value={maintenanceConfig}
            onChange={checkConfig}
            name=""
            onTouched={checkConfig}
          />
          <br />
          <InputCheckbox
            type={"checkbox"}
            label="Maintenance Job Service"
            value={maintenanceJobServiceConfig}
            onChange={() => checkConfigMaintenanceJobService()}
            name=""
            onTouched={() => checkConfigMaintenanceJobService()}
          />
        </form>
      </div>
    </div>
  );
});
