import React from "react";
import * as Yup from "yup";
import { InputNumber, InputText } from "../../../components";
import { translate } from "../../../languages";
import { CreateAlert, InputWraper, onError, useForm } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import { store, useSelector } from "../../../store";
import { withConfigWraper } from "../wraper";
// import RankConfigWithdraw from "./rankConfigWithdraw";
export const WithdrawConfig = withConfigWraper(() => {
  const withdrawConfig = useSelector((state) => state.withdrawConfig);
  //console.log(withdrawConfig);
  
  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "userWithdrawMaxOnDay",
        defaultValue: Number(withdrawConfig.USER_MAXIMUM_IN_DAY),
        isDisabled: true,
        validate: Yup.number()
          .required(translate("must-be-provided"))
          .min(1, translate("not filled in zero")),
      },
      {
        name: "systemWithdrawMaxOnDay",
        defaultValue: Number(withdrawConfig.SYSTEM_MAXIMUM_IN_DAY),
        isDisabled: true,
        validate: Yup.number()
          .required(translate("must-be-provided"))
          .min(1, translate("not filled in zero")),
      },

      // more
      {
        name: "userMaximumInDayCount",
        defaultValue: Number(withdrawConfig.USER_MAXIMUM_IN_DAY_COUNT),
        isDisabled: true,
        validate: Yup.number()
          .required(translate("must-be-provided"))
          .min(1, translate("not filled in zero")),
      },
      {
        name: "minWithdraw",
        defaultValue: Number(withdrawConfig.MIN_WITHDRAW),
        isDisabled: true,
        validate: Yup.number()
          .required(translate("must-be-provided"))
          .min(1, translate("not filled in zero")),
      },
      {
        name: "maximumAutoWithdrawUSD",
        defaultValue: Number(withdrawConfig.MAXIMUN_AUTO_WITHDRAW_USD),
        isDisabled: true,
        validate: Yup.number()
          .required(translate("must-be-provided"))
          .min(1, translate("not filled in zero")),
      },
      {
        name: "allowWithdrawTimeFrom",
        defaultValue: withdrawConfig.ALLOW_WITHDRAW_TIME_FROM,
        isDisabled: true,
        validate: Yup.string()
          .required(translate("must-be-provided")),
      },
      {
        name: "allowWithdrawTimeTo",
        defaultValue: withdrawConfig.ALLOW_WITHDRAW_TIME_TO,
        isDisabled: true,
        validate: Yup.string()
          .required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const valueConfig = {
        USER_MAXIMUM_IN_DAY: values.userWithdrawMaxOnDay,
        SYSTEM_MAXIMUM_IN_DAY: values.systemWithdrawMaxOnDay,
      };
      const payload = {
        isActiveScan: true,
        shouldProcessWithdraw: false,
        reservedEth: 0,
        withdrawRequestExpiration: 0,
        value: JSON.stringify(valueConfig),
        serverConfigName: "WITHDRAW_LIMIT_CONFIG",
      };
      return ConfigService.setConfig(payload);
    },
  });

  return (
    <div className="mb20">
      <span className="config--title mt40 mb32">Withdraw Config</span>
      <div className="row config--item ml3 mr3">
        <div className="col-sm-5">
          <form className="blockLists" onSubmit={handleSubmit}>
            <InputWraper
              label={"System total withdraw maximum per day"}
              inputProps={getInputProps("systemWithdrawMaxOnDay")}
              component={InputNumber}
            />
            <InputWraper
              label={"User total withdraw maximum per day"}
              inputProps={getInputProps("userWithdrawMaxOnDay")}
              component={InputNumber}
            />

            <InputWraper
              label={"User maximum limit per day"}
              inputProps={getInputProps("userMaximumInDayCount")}
              component={InputNumber}
            />

            <InputWraper
              label={"Minimum amount"}
              inputProps={getInputProps("minWithdraw")}
              component={InputNumber}
            />

            <InputWraper
              label={"Maximum auto approve withdraw"}
              inputProps={getInputProps("maximumAutoWithdrawUSD")}
              component={InputNumber}
            />

            <InputWraper
              label={"Allow time from"}
              inputProps={getInputProps("allowWithdrawTimeFrom")}
              component={InputText}
            />

            <InputWraper
              label={"Allow time to"}
              inputProps={getInputProps("allowWithdrawTimeTo")}
              component={InputText}
            />
            {/* <div>
                <Button
                    // className="mb20"
                    label="Submit"
                    // onClick={props.onClick}
                    isMiddle
                    buttonType="success"
                    className="btnBan"
                    type="submit"
                />
            </div> */}
          </form>
        </div>
      </div>
      {/* <span className="config--title mt48 mb32">List Rank Config</span>
      <div className="row config--item ml3 mr3">
        <div className="col-sm-8">
          <RankConfigWithdraw />
        </div>
      </div> */}
    </div>
  );
});
