import { RequestMainService } from "../request";
import { Store } from "redux";
import { SET_COIN_CONVERSION_RATE } from "./coinConversionRate.reducer";
import capitalize from "lodash/capitalize";
export interface IRequestWithdrawPayload {
  coinId: number;
  feeCoinId: number;
  value: number;
}

export class BankService {
  static async getTransactions(params: any = {}) {
    return RequestMainService.get(`/bank/transaction`, params).then(({ result }) => ({
      count: result.total,
      data: result.transactions,
    }));
  }

  static async getCoinConversionRate(store: Store) {
    return RequestMainService.get(`/bank/rates-to-usd`)
      .then(({ result }) => store.dispatch({ type: SET_COIN_CONVERSION_RATE, data: result }))
      .catch((res) =>
        store.dispatch({
          type: SET_COIN_CONVERSION_RATE,
          error: res.error,
        })
      );
  }

  static getTransactionTypeOptions() {
    return [
      // { label: "INIT", value: 0 },
      { label: "DEPOSIT", value: 1 },
      { label: "WITHDRAW", value: 2 },
      { label: "RECEIVE_TRANSFER", value: 3 },
      { label: "SEND_TRANSFER", value: 4 },
      // { label: "DEPOSIT_FEE", value: 5 },
      { label: "WITHDRAW_FEE", value: 6 },
      // { label: "SEND_TRANSFER_FEE", value: 7 },
      // { label: "RECEIVE_TRANSFER_FEE", value: 8 },
      // { label: "TRADE_FEE", value: 9 },
      // { label: "OTHER_INCOME", value: 10 },
      // { label: "OTHER_FEE", value: 11 },
      // { label: "BUY_PRESALE_COIN", value: 12 },
      { label: "REFUND_WITHDRAW_FEE", value: 13 },
      { label: "DIRECT_COMMISSION", value: 14 },
      { label: "BUY_AGENCY_LICENSE", value: 15 },
      // { label: "UPGRADE_MINER", value: 16 },
      { label: "BANK_RECEIVE_FROM_USER", value: 17 },
      { label: "BANK_SEND_TO_USER", value: 18 },
      // { label: "CONVERT_SENT", value: 19 },
      // { label: "CONVERT_RECEIVED", value: 20 },
      // { label: "SEND_EXCHANGE_FEE", value: 21 },
      // { label: "BUY_MINER_COMMISSION", value: 22 },
      // { label: "UPGRADE_MINER_COMMISSION", value: 23 },
      // { label: "SYSTEM_COMMISSION", value: 24 },
      // { label: "CASH_BACK", value: 25 },
      { label: "TRADING_COMMISSION", value: 26 },
      { label: "REFUND_WITHDRAW", value: 27 },
      // { label: "REFILL_DEMO_ACCOUNT", value: 28 },
      // { label: "RETURN_MINER_FEE", value: 29 },
      // { label: "PLACE_INVESTMENT", value: 46 },
      // { label: "INVESMENT_PROFIT", value: 47 },
      // { label: "AIRDROP_TOKEN", value: 48 },
      // { label: "AIRDROP_TOKEN_FROM_USER", value: 49 },
      // { label: "EXPERIENCE_GAME_REFUND", value: 50 },
      // { label: "CANCEL_GAME", value: 52 },
      // { label: "EXCHANGE_OUT", value: 53 },
      // { label: "EXCHANGE_OUT_FEE", value: 54 },
      // { label: "EXCHANGE_IN", value: 55 },
      // { label: "GAME_RECOVERY", value: 56 },
      // { label: "GAME_ADJUST", value: 57 },
      // { label: "GAME_TIP", value: 58 },
      // { label: "GAME_GIVE_PROMOTION", value: 59 },
      // { label: "GAME_REFUND", value: 60 },
      // { label: "GAME_CANCEL_TIP", value: 61 },
      // { label: "EXPERIENCE_GAME_FEE", value: 62 },
      // { label: "CIC_AIRDROP", value: 63 },
      // { label: "CIC_AIRDROP_FROM_F1", value: 64 }
      // { label: "AIRDROP_TOKEN_TRADE", value: 65 },
      // { label: "AIRDROP_COMMISSION", value: 66 },
      // { label: "TRANSFER_TO_POOL", value: 68 },
      // { label: "POOL_REWARD", value: 70 },
      // { label: "PAY_GOLD_COMMISSION", value: 73 },
      // { label: "PAY_DINAMOND_COMMISSION", value: 74 },
      // { label: "PAY_DINAMOND_COMMISSION", value: 74 },
      // { label: "KYC_FEE", value: 75 },
      { label: "BUY_BOOK", value: 76 },
      { label: "AGENCY_LOCKED", value: 78 },
      { label: "AGENCY_UNLOCKED", value: 79 },
      { label: "PAYMENT_OF_GOODS", value: 80 },
      { label: "PAYMENT_FROM_CUSTOMER", value: 81 },
      { label: "RECEIVE_FROM_CUSTOMER", value: 82 },
      { label: "CAPITAL_CONTRIBUTION_PROFIT", value: 83 },
      { label: "SHAREHOLDER_FEES", value: 84 },
      { label: "SHAREHOLDER_FEES_FROM_AGENT", value: 85 },
      { label: "INVESTMENT", value: 86 },
      { label: "SPENDING_BUSINESS_PROFITS", value: 87 },
      { label: "RECEIVE_SELLING_MACHINE", value: 88 },
      { label: "RECEIVE_TRANSFER_GTMART", value: 89 },
      { label: "RECEIVE_TAX_GTMART", value: 90 },
      { label: "RECEIVE_PROFIT_STORE_OWNER_GTMART", value: 91 },
      { label: "RECEIVE_REINVESTMENT_GTMART", value: 92 },
      { label: "RECEIVE_ORIGINAL_PRICE_GTMART ", value: 95 },
      { label: "SEND_QUALIFIED_REFERRER_REWARD ", value: 97 },
      { label: "QUALIFIED_REFERRER_REWARD ", value: 98 },
      { label: "GT_MOVE_DRIVER_PAY ", value: 99 },
      { label: "GT_MOVE_USER_PAY ", value: 100 },
      { label: "RECEIVE_PROFIT_GT_MOVE ", value: 101 },
      { label: "RECEIVE_TAX_GT_MOVE ", value: 102 },
      { label: "DRIVER_RECEIVE_PROFIT_GT_MOVE ", value: 103 },
      { label: "SET_LIMIT_INCREASE_MACHINE ", value: 107 },
      { label: "SET_LIMIT_DECREASE_MACHINE ", value: 108 },
      { label: "SEND_QUALIFIED_SPONSOR_REWARD", value: 109 },
      { label: "QUALIFIED_SPONSOR_REWARD", value: 110 },
      { label: "MINED_GT", value: 112 },
      { label: "MINED_DEPOSIT", value: 113 },
      { label: "MINED_WITHDRAW", value: 114 },
      { label: "MINED_PROFIT", value: 115 },
      { label: "MINED_TAX", value: 116 },
      { label: "MINED_CAPITAL", value: 117 },
      { label: "MINED_SYSTEM_TRANSFER", value: 118 },
    ].map((v) => ({ ...v, label: capitalize(v.label.replace(/_/g, " ")) }));
  }
}
