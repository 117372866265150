import React from "react";
import * as Yup from "yup";
import { InputNumber } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { DividendsService } from "../../../services/dividends";

interface Props {
  onClose: () => void;
}

export const DividendDistributionForm = ({ onClose }: Props) => {
  const { handleSubmit, getInputProps, isSubmitting } = useForm({
    structure: [
      {
        name: "amount",
        validate: Yup.number().required(translate("must-be-provided")).test("larger-than-0", "Not filled in negative number", (value: any) => value > 0),
      },
    ],
    onSubmit: async ({ amount }) =>
      DividendsService.dividend(amount).then(() => {
        onClose();
        CreateAlert({
          type: "success",
          message: "Dividends successfully distributed",
        });
      }).catch((err) => {
        const error = {...err}
        if(error?.error?.message === "INSUFFICIENT_FUNDS")
        CreateAlert({
          type: "danger",
          message: "Insufficient funds",
        });
      }),
  });

  return (
    <div
      style={{
        minWidth: "375px",
      }}
    >
      <form onSubmit={handleSubmit} autoComplete="on">
        <InputWraper label={translate("amount")} inputProps={getInputProps("amount")} renderInput={(state) => <InputNumber {...state} />} />

        <Button
          style={{
            width: "100%",
            marginTop: "30px",
          }}
          label={"Confirm"}
          buttonType="primary"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        />
      </form>
    </div>
  );
};
