import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../../AppRoutes';
import { Icon } from '../../../../components';

export const AffiliatedAgentLayout = (Component: FC<any>) => (props: any) => {
  return (
    <div className="affiliated-agent-layout">
      <div className="TabLinks">
        <NavLink to={Routes.affiliatedAgent.path} exact={true}>
          <Icon.Agency />
          Agency
        </NavLink>

        <NavLink to={Routes.revenueAgency.path} exact={true}>
          <Icon.Revenue />
          Revenue
        </NavLink>
      </div>
      <Component {...props} />
    </div>
  );
};
