import React, { useState } from "react";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { Button, CreateAlert, Icon, ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { withStartupWraper } from "../wrapper";
import moment from "moment";
import { translate } from "../../../languages";
import { StartupService } from "../../../services/startup";
import XLSX from 'xlsx';
import { getEnv } from "../../../configs";
import { PopupWraper } from "../../../components/popup";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";


export const Contract = withStartupWraper(() => {
  const [params, setParams] = useState([] as any[]);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
  const [disableExport, setDisableExport] = useState<boolean>(true);
  const [approveStartUp, setApproveStartUp] = useState<any>({})

  const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
  const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");
  const [isRequesting, setIsRequesting] = useState<boolean>(false);


  const structure: ITableStructureItem[] = [
    {
      name: "Project's name",
      key: "projectName",
      render: (item) => (!!item.projectName ? item.projectName : "--"),
    },
    {
      name: "Contract owner",
      key: "name",
    },
    {
      name: "Contract type",
      key: "contractType",
      render: (item) => (!!item.contractType ? item.contractType : "--"),

    },
    {
      name: "Created date",
      key: "created",
      render: (item) => {
        return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
      },
    },
  //  {
  //   name: "Contract",
  //   key: "licenseUrl",
  //   render: (item: any) => {
  //     const data = JSON.parse(item?.licenseUrl)
  //     return <img src={data[0].url || ""} alt=""/>;
  //   }
  //  },
   {
    name: "Download",
    key: "investmentDocumentUrl",
    render: (item) => {
      return <a href={item?.investmentDocumentUrl} rel="noopener noreferrer" target="__blank" download>Download</a>;
    }
   }
  ];

  const contractType = [
    { value: "INVESTMENT", label: "Investment" },
    { value: "FUNDRAISING", label: "Fundraising" },

  ]

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await StartupService.contactStartupList({
          ...params,
          page: 1,
          pageSize: 10000,
        });

        const data = response.data;

        let fileHead:any = structure.filter((el) => el.key !== "licenseUrl" ).map((v) => v.name);
        // fileHead.pop(); //remove last column on table
        const dataExport = [
          fileHead,
          ...data.filter((el: any) => el.key !== "licenseUrl" ).map((item: any) =>
            structure.map((column, index) => {
              if (column.key === "created") return moment(item[column.key]).format("DD/MM/y HH:mm:ss");
              if (column.key === "projectName") return !!item.projectName ? item.projectName : "--";
              if (!column.key) return "";
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(
          wb,
          `Startup Contract List ${now.toLocaleDateString().replace(/\//g, "-")} ${now
            .toLocaleTimeString()
            .replace(/:/g, "-")}.xlsx`,
          { type: "binary" }
        );

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error:any) {
        resolve(CreateAlert({ type: "danger", message: error.message }));
      }
    });
  };

  return (
    <div className="ApprovalForCallingCapital">
      <Button
          className="mb20"
          label="Export to Excel"
          buttonType="primary"
          disabled={disableExport}
          onClick={handleExportExcel}
        />

      <Table
        hasOrderColumn
        filters={[
          {
            name: "Search by project's name, contract owner",
            key: "searchString",
            input: TableFilterInputText,
          },
          {
            name: "Contract type",
            key: "contractType",
            input: (s) => (
              <TableFilterInputSelect
                {...s}
                options={contractType}
                isClearable={true}
                isSearchable={false}
              />
            ),
          },
          {
            name: translate("time"),
            key: "created",
            input: (e) => <TableFilterRangeTimeInput {...e} fromKey="fromDate" toKey="toDate" />,
            defaultValue: {
              fromDate: startOfMonth, //new Date(last1MothDate),
              toDate: endOfDay, //new Date(),
            },
          },
         
        ]}
        structure={structure}
        fetchData={async (state) => {
          let params = { ...state };
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          setParams(params);
          return StartupService.contactStartupList({
            ...params,
            page: params.pageNumber,
            pageSize: params.limit,
          }).then((res) => {
            setDisableExport(!(res?.data?.length > 0))
            return res;
          });
        }}
        forceUpdateTable={forceUpdateTable}
      />
    </div>
  );
});
