import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import XLSX from "xlsx";
import { AdminService } from "../../services/admin";
import { Button, CreateAlert, Icon, Table } from "../../modules";
import { Routes } from "../../AppRoutes";
import { AffiliatedAgentLayout } from "./components";
import { translate } from "../../languages";
import { TableFilterInputText } from "../../components/table-filter-inputs";
import { PopupWraper } from "../../components/popup";
import { InputSelect } from "../../components";

export enum EAffiliatedAgentStatus {
  ACTIVE = 1,
  WARNING = 2,
  LOCK = 3,
}

export enum EActiveStatus {
  ACTIVATED = 0,
  DEACTIVATED = 1,
  NOT_SHOWING = 2
}

export const AffiliatedAgentPage = AffiliatedAgentLayout(() => {
  const history = useHistory();
  const [params, setParams] = useState([] as any[]);
  const [currentData, setCurrentData] = useState(null as any);
  const [forceUpdateTable, setForceUpdateTable] = useState<boolean>();
  // const [isShowPopupActive, setIsShowPopupActive] = useState<boolean>(false);
  // const [dataItemActive, setDataItemActive] = useState<any>();
  const [dataItemChangeStatus, setDataItemChangeStatus] = useState<any>();
  const [valueChangeStatus, setValueChangeStatus] = useState<any>(null);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [disableExport, setDisableExport] = useState<boolean>(true);

  // const [dataItemDelete, setDataItemDelete] = useState<any>();
  //const user = useSelector((state) => state.user);
  // const startOfMonth = moment().subtract(1, "months").startOf("month");
  // const endOfDay = moment().endOf("day");

  function getStatusName(status: EAffiliatedAgentStatus): string {
    switch (status) {
      case EAffiliatedAgentStatus.ACTIVE:
        return "ACTIVE";
      case EAffiliatedAgentStatus.WARNING:
        return "WARNING";
      case EAffiliatedAgentStatus.LOCK:
        return "LOCK";
      default:
        return "N/A";
    }
  }

  function getActiveStatusName(status: EActiveStatus): string {
    switch (status) {
      case EActiveStatus.ACTIVATED:
        return "ACTIVE";
      case EActiveStatus.DEACTIVATED:
        return "WARNING";
      case EActiveStatus.NOT_SHOWING:
        return "LOCK";
      default:
        return "N/A";
    }
  }

  const statusFilterOptions = [
    { label: "All", value: "ALL" },
    { label: "Active", value: true },
    { label: "Deactive", value: false },
  ];

  const activeStatusOptions = [
    { label: "Activated", value: 0 },
    { label: "Deactivated", value: 1 },
    { label: "Not Show", value: 2 },
  ]

  const structure = [
    {
      name: "Project Name",
      key: "projectName",
    },
    {
      name: "Representative User",
      key: "representativeEmail",
      className: "representative-user",
    },
    {
      name: "Representative Id",
      key: "representativeId",
    },
    {
      name: "Machine ID",
      key: "machineId",
      render: (item: any) => (item?.machineId != null ? item?.machineId : "N/A"),
    },
    {
      name: "Number of Shareholders",
      key: "numberOfShareholder",
      render: (item: any) => (item?.numberOfShareholder != null ? new Intl.NumberFormat("en").format(item?.numberOfShareholder) : "N/A"),
    },
    {
      name: "Contribution Amount",
      key: "totalAmountContribution",
      render: (item: any) => (item?.totalAmountContribution != null ? new Intl.NumberFormat("en").format(item?.totalAmountContribution) : "N/A"),
    },
    {
      name: "Minimum Amount",
      key: "representativeMinAmount",
      render: (item: any) => (item?.representativeMinAmount != null ? new Intl.NumberFormat("en").format(item?.representativeMinAmount) : "N/A"),
    },
    {
      name: "Representative Balance",
      key: "representativeBalance",
      render: (item: any) => (item?.representativeBalance != null ? new Intl.NumberFormat("en").format(item?.representativeBalance) : "N/A"),
    },
    {
      name: "Created Date",
      key: "created",
      render: (item: any) => moment(item?.created).format("L HH:mm:ss"),
    },
    // {
    //   name: "Status",
    //   key: "status",
    //   render: (item: any) => <span className={`status status--${getStatusName(item?.status)?.toLowerCase()}`}>{getStatusName(item?.status)}</span>,
    // },
    {
      name: "Active Status",
      key: "activeStatus",
      render: (item: any) => {
          switch (item?.activeStatus) {
              case EActiveStatus.ACTIVATED:
                  return <span className="active-status active-status--activated">{'Activated'}</span>;
              case EActiveStatus.DEACTIVATED:
                  return <span className="active-status active-status--deactivated">{'Deactivated'}</span>;
              case EActiveStatus.NOT_SHOWING:
                  return <span className="active-status active-status--not-showing">{'Not Showing'}</span>;
              default:
                  return <span className="active-status">N/A</span>;
          }
      },
    },
    {
      name: "Actions",
      render: (item: any) => (
        <div className={`action`}>
          <div className="action--edit" onClick={() => history.push(Routes.affiliatedAgentEdit.path.replace(":id", item?.agencyId))}>
            <Icon.ActionEditIcon />
          </div>
          <div className="action--change-status" onClick={() => {
            setValueChangeStatus(activeStatusOptions?.find(x => x.value === item?.activeStatus)); 
            setDataItemChangeStatus(item);
          }}>
            <Icon.ActionMoreIcon />
          </div>
          {/* <div className="action--delete" onClick={() => setDataItemDelete(item)}>
            <Icon.ActionDeleteIcon />
          </div> */}
        </div>
      ),
    },
    // {
    //   name: "Lock",
    //   render: (item: any) => (
    //     <div className="lock">
    //       <InputToggleSwitch
    //         onChange={async (value) => {
    //           handleOnClickSwitchActive(item, value);
    //         }}
    //         value={item?.status === EAffiliatedAgentStatus.ACTIVE || item?.status === EAffiliatedAgentStatus.WARNING}
    //         onTouched={() => false}
    //         label=""
    //         name=""
    //         // isDisabled={item?.status === EAffiliatedAgentStatus.LOCK}
    //       />
    //     </div>
    //   ),
    // },
  ];

  // const handleOnClickSwitchActive = (item: any, value: any) => {
  //   setDataItemActive({ item, value });
  //   setIsShowPopupActive(true);
  // };

  // const handleOnClickConfirmActive = () => {
  //   let payload = {
  //     agencyId: dataItemActive?.item?.agencyId,
  //     isActive: dataItemActive?.value,
  //   };
  //   AdminService.lockAgency(payload)
  //     .then((res) => {
  //       setForceUpdateTable((state) => !state);
  //       CreateAlert({ message: `successfully.`, type: "success" });
  //     })
  //     .catch((err) => {
  //       CreateAlert({ message: err?.message, type: "danger" });
  //     })
  //     .finally(() => {
  //       setIsShowPopupActive(false);
  //     });
  // };

  const handleExportExcel = async () => {
    return new Promise(async (resolve) => {
      try {
        const response = await AdminService.getAgencyList({
          page: 1,
          pageSize: 10000,
          ...params,
        });

        const data = response.data; // response.data;

        const fileHead = structure.map((v) => v.name);
        fileHead.pop(); //remove last column on table
        const dataExport = [
          fileHead,
          ...data.map((item: any) =>
            structure.map((column, index) => {
              if (!column.key) return "";
              if (column.key === "created") return moment(item[column.key]).format("L HH:mm:ss");
              // if (column.key === "status") return getStatusName(item[column.key]);
              if (column.key === "activeStatus") return getActiveStatusName(item[column.key]);
              return item[column.key];
            })
          ),
        ];

        const ws = XLSX.utils.aoa_to_sheet(dataExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

        const now = new Date();
        XLSX.writeFile(wb, `Agency List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, {
          type: "binary",
        });

        resolve(
          CreateAlert({
            type: "success",
            message: "Export data success.",
          })
        );
      } catch (error) {
        resolve(CreateAlert({ type: "danger", message: (error as any).message }));
      }
    });
  };

  let handleOnClickConfirmChangeStatus = (item: any) => {
    let payload = {
      agencyId: item?.agencyId,
      activeStatus: valueChangeStatus?.value,
    }
    AdminService.updateActiveStatusAgency(payload)
      .then((res: any) => {
        setForceUpdateTable((state) => !state);
        CreateAlert({ message: `Change successfully.`, type: "success" });
      })
      .catch((err: any) => {
        CreateAlert({ message: translate(err?.message), type: "danger" });
      })
      .finally(() => {
        setDataItemChangeStatus(null);
        setValueChangeStatus(null);
      });
  };

  // let handleOnClickConfirmDelete = (item: any) => {
  //   AdminService.deleteAgency(item?.agencyId)
  //     .then((res: any) => {
  //       setForceUpdateTable((state) => !state);
  //       CreateAlert({ message: `Delete successfully.`, type: "success" });
  //     })
  //     .catch((err: any) => {
  //       CreateAlert({ message: translate(err?.message), type: "danger" });
  //     })
  //     .finally(() => {
  //       setDataItemDelete(null);
  //     });
  // };

  return (
    <div className="affiliated-agent-page">
      <div className="button-container">
        <Button disabled={ disableExport || !currentData} className="mb20" label="Export to Excel" buttonType="primary" onClick={handleExportExcel} />

        {/* <div className="button-group">
          <Button label="Add Supplier" buttonType="success" icon={Icon.AddNewIcon} onClick={() => history.push(Routes.affiliatedAgentCreate.path)} />
        </div> */}
      </div>

      <Table
        hasOrderColumn
        filters={[
          {
            name: "Search",
            key: "searchString",
            input: TableFilterInputText,
          },
        ]}
        structure={structure}
        fetchData={async (params: any) => {
          setParams(params);
          if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
          if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
          return AdminService.getAgencyList({
            page: params.pageNumber,
            pageSize: params.limit,
            ...params,
          }).then((res) => {
            setCurrentData(res);
            setDisableExport(!(res?.data?.length > 0))
            return res;
          });
        }}
        forceUpdateTable={forceUpdateTable}
      />

      {/* {isShowPopupActive ? (
        <GeneralPopupCompnt
          onClose={() => {
            setIsShowPopupActive(false);
          }}
          onClickConfirm={() => handleOnClickConfirmActive()}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={[
            `Are you sure you want to ${dataItemActive?.value === true ? "active" : "deactivate"}`,
            [<strong>{` "${dataItemActive?.item?.name}"`}</strong>],
            "?",
          ]}
        />
      ) : null} */}
      {dataItemChangeStatus && (
        <PopupWraper center title="Change Status" onClose={() => setDataItemChangeStatus(null)}>
          <div className="mb8">Change Active Status For "<strong>{dataItemChangeStatus?.name}</strong>"</div>
          <InputSelect
            name=""
            onTouched={() => null}
            options={activeStatusOptions}
            defaultValue={activeStatusOptions?.find(x => x.value === dataItemChangeStatus?.activeStatus)}
            value={valueChangeStatus}
            onChange={e => setValueChangeStatus(activeStatusOptions?.find(x => x.value === e))}
            isClearable={false}
          />
          <div className="d-flex justify-content-center mt24">
            <Button 
              label={"Save"}
              onClick={() => handleOnClickConfirmChangeStatus(dataItemChangeStatus)}
              isLoading={isRequesting}
              disabled={isRequesting}
            />
          </div>
        </PopupWraper>
      )}
      {/* {dataItemDelete && (
        <GeneralPopupCompnt
          onClose={() => setDataItemDelete(null)}
          onClickConfirm={() => handleOnClickConfirmDelete(dataItemDelete)}
          textButton="OK"
          titlePopup={"Notification!"}
          messagePopup={[`Are you sure you want to delete`, [<strong>{` "${dataItemDelete?.name}"`}</strong>], "?"]}
        />
      )} */}
    </div>
  );
});
