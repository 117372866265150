import React from "react"
import { PopupWraper } from "../../../../components/popup";
import { Button, CreateAlert, InputWraper, useForm } from "../../../../modules";
import * as Yup from "yup";
import { StartupService } from "../../../../services/startup";
import { InputText } from "../../../../components";
import { useHistory } from 'react-router-dom';
import { Routes } from "../../../../AppRoutes";

interface Props {
  // onSubmit: (reson: string) => any;
  payload: any;
  onClose: ()=> void;
  
}

export const ReasonForm = ( { onClose, payload }: Props ) => {
  const navigate = useHistory();
  const { getInputProps, handleSubmit, isSubmitting } = useForm({
    structure: [
        { 
            name: 'reason',
            // defaultValue: data?.projectName,
            validate: Yup.string()
                .required("Must be provided"),
        },
       
    ],
    onSubmit:  async (values) => {
        let param = {
         ...payload,
         reason: values?.reason,
        }
        return StartupService.updateStartupStatus(param).then(() => {
          CreateAlert({message: "Reject startup successfully", type: "success"});
          navigate.push(Routes.startupList.path);
        }).catch((err: any) => {
          const error = {...err}
          CreateAlert({message: error.message, type: "danger"});
        })
    }
});

  return (
    <PopupWraper
      title="Reject Reason"
      center
      onClose={() => onClose()}
    >
       <div className="col-12 d-flex flex-column align-items-center justify-content-center">
        <InputWraper
            label={"Reason"}
            inputProps={getInputProps('reason')}
            component={InputText}
        />
         <div className="col-12 d-flex justify-content-between">
           <Button 
            label={"Cancel"} 
            onClick={()=> onClose()} 
            // isLoading={isSubmitting} 
            // disabled={isSubmitting}
            // type="reset"
            className="mr16"
            buttonType="grey"
          />
          <Button 
            label={"Submit"} 
            onClick={handleSubmit} 
            isLoading={isSubmitting} 
            disabled={isSubmitting}
            // type="submit"
          />
         </div>
      </div>
    </PopupWraper>
  )
}