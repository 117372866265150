import React, { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { ConfigService } from "../../../services/config/config.service";
import { withConfigWraper } from "../wraper";
import { Button, onError, InputWraper, useForm, CreateAlert, Icon } from "../../../modules";
import { InputNumber, InputText } from "../../../components";
import { store } from "../../../store";
import { translate } from "../../../languages";
import * as Yup from "yup";
import { GeneralPopupCompnt } from "../../../components/general-popup-compnt";

export const StakingConfigPage = withConfigWraper(() => {
    const [stakingAmount, setStakingAmount] = useState<any>();
    const [isDisableInput1, setIsDisableInput1] = useState<boolean>(true);
    const [dataPopup, setDataPopup] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { handleSubmit, getInputProps } = useForm({
        enableReinitialize: true,
        structure: [
            {
                name: "stakingAmount",
                defaultValue: stakingAmount ?? 'N/A',
                validate: Yup.number()
                    .typeError(translate("must be a number"))
                    .required(translate("must be provided"))
                    .positive('must be greater than 0')
                    .integer('must be an integer'),
                isDisabled: isDisableInput1,
            },
        ],
        onSubmit: async (values) => {
            let payload = {
                stakingAmount: +values?.stakingAmount,
            };
            setDataPopup(payload);
        },
    });

    let handleOnClickConfirmPopup = () => {
        if (isLoading === true) return;
        setIsDisableInput1(true);
        setIsLoading(true);

        const payload = {
            isActiveScan: true,
            shouldProcessWithdraw: false,
            value: JSON.stringify({VALUE: +dataPopup?.stakingAmount}),
            serverConfigName: "MINED_CONFIG",
        };

        return ConfigService.setConfig(payload).then(async () => {
                ConfigService.getServerConfigByName('MINED_CONFIG').then((res) => {
                    setStakingAmount(res?.VALUE);
                });
            }).catch((err) => {
                CreateAlert({
                    type: 'danger',
                    message: translate(err?.message)
                });
            }).finally(() => {
                setDataPopup(null);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        ConfigService.getServerConfigByName('MINED_CONFIG').then((res) => {
            setStakingAmount(res?.VALUE);
        });
    }, []);

    return (
        <div className="row mb20 vending-machine-config-page">
            <div className="col-sm-5">
                <form
                    className="blockLists"
                    onSubmit={handleSubmit}
                >
                    <InputWraper
                        label={"GT Amount For Staking"}
                        inputProps={getInputProps("stakingAmount")}
                        renderInput={(props) => (
                            <>
                                <InputText {...props} />
                                <div
                                    className="icon"
                                    onClick={() => setIsDisableInput1((state) => !state)}
                                >
                                    <Icon.ActionEditIcon />
                                </div>
                            </>
                        )}
                    />
                    <div>
                        <Button
                            label="Submit"
                            isMiddle
                            buttonType="success"
                            type="submit"
                            disabled={stakingAmount == null || isDisableInput1 || dataPopup != null || ((stakingAmount === +getInputProps('stakingAmount').value))}
                        />
                    </div>
                </form>
            </div>
            {dataPopup ? (
                <GeneralPopupCompnt
                    onClose={() => {
                        setDataPopup(null);
                    }}
                    onClickConfirm={() => handleOnClickConfirmPopup()}
                    textButton="OK"
                    titlePopup={"WARNING!"}
                    messagePopup={[
                        <>
                            {(stakingAmount != dataPopup?.stakingAmount) &&
                            <>
                                <div style={{textAlign: 'start', marginBottom: '8px'}}>
                                    <div className="label">GT Amount For Staking:</div>
                                    <div className="content">
                                        <div className="content1">- OLD: <span style={{color: '#ff4c51'}}>{stakingAmount}</span></div>
                                        <div className="content1">- NEW: <span style={{color: '#56ca00'}}>{dataPopup?.stakingAmount}</span></div>
                                    </div>
                                </div>
                            </>}
                            {(stakingAmount == dataPopup?.stakingAmount) && "No change"}
                        </>,
                    ]}
                />
            ) : null}
        </div>
    );
});
