import moment from "moment";
import React, { useState } from "react";
import { PopupWraper } from "../../../components/popup";
import { Button, Icon, ITableStructureItem, Table } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";
import CreateAnnouncement from "./createAnnouncement";
import EditAnnouncement from "./editAnnouncement";

const AnnouncementCompnt = () => {
  const [editData, setEditData] = useState(null);
  const [createData, setCreateData] = useState(false);
  const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());

  const structure: ITableStructureItem[] = [
    {
      name: "Content",
      key: "content",
      className: "content",
      render: (item) => <span dangerouslySetInnerHTML={{__html: item?.content}}></span>
    },
    {
      name: "Created Date",
      key: "created",
      render: (item) => moment(item?.created).format("DD/MM/y HH:mm:ss")
    },
    {
      name: "Expiration Date",
      key: "expirationDate",
      render: (item) => moment(item?.expirationDate).format("DD/MM/y HH:mm:ss")
    },
    {
      name: "Status",
      key: "status",
      render: (item) => <span className={`status status--${item?.status?.toLowerCase()}`}>{item?.status?.toUpperCase()}</span>
    },
    {
      name: "Actions",
      render: (item) => {
        return (
          <div className="action">
            <div className={`action__edit action__edit--${item?.status?.toLowerCase()}`} onClick={() => {
              if (item?.status?.toLowerCase() === 'expired') return;
              setEditData({ ...item })
            }}>
              <Icon.ActionEditIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Button
        label="New Announcement"
        onClick={() => setCreateData(true)}
        buttonType="primary"
        icon={Icon.AddNewIcon}
        className="btnBan mb20"
      />
      <Table
        hasOrderColumn
        itemPerPages={10}
        structure={structure}
        fetchData={async (params) => {
          if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).toISOString();
          if (params["toDate"]) params["toDate"] = moment(params["toDate"]).toISOString();
          return ConfigService.getListAnnouncement();
        }}
        forceUpdateTable={forceUpdateTable}
      />
      {editData && (
        <PopupWraper center title="Edit Announcement" onClose={() => setEditData(null)}>
          <EditAnnouncement
            data={editData}
            onFinishEdit={() => {
              setForceUpdateTable(Math.random);
              setEditData(null);
            }}
          />
        </PopupWraper>
      )}
      {createData && (
        <PopupWraper center title="Create Announcement" onClose={() => setCreateData(false)}>
          <CreateAnnouncement
            onFinishEdit={() => {
              setForceUpdateTable(Math.random);
              setCreateData(false);
            }}
          />
        </PopupWraper>
      )}
    </>
  );
};

export default AnnouncementCompnt;
