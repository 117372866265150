import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { FilterInputDateTime, TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, ITableStructureItem, NumberUtils, Table } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";
import XLSX from "xlsx";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Routes } from "../../../AppRoutes";

export const TransactionByMachineIdList = () => {

    const routeMatch: any = useRouteMatch();
    const machineId = routeMatch?.params?.id;
    const history = useHistory();

    let today = moment();

    const [params, setParams] = useState<any>({});
    const [currentData, setCurrentData] = useState<any>();

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getVendingMachineTransactionByMachineId({
                    ...params,
                    page: 1,
                    numberOfTransactionsPerPage: 10000,
                    machineId: machineId
                });
                let sumReports = (response?.data?.[0] != null) ? Object.keys(response?.data?.[0]).reduce((ouput: any, key: string) => {
                    ouput[key] = response?.data?.reduce((sum: number, item: any) => sum + item[key], 0);
                    return ouput;
                }, {}) : {};
                const data = [{isSum: true, sumReports: sumReports}, ...response.data];

                let fileHead: any = structure.map((v) => v.name);
                // fileHead.pop(); //remove last column on table
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) => {
                        if (item.isSum) {
                            return structure.map((column:any, index:any) => {
                                if (column.key === "projectName") return "SUM";
                                if ([
                                    "amount",
                                ].includes(column.key)) return (item?.sumReports?.[column.key] != null) ? NumberUtils.toFormatNumber(+item.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
                                if (column.key === "created") return null;
                                return "";
                            });
                        }
                        return structure.map((column:any, index:any) => {
                            if ([
                                "amount",
                            ].includes(column.key)) return (item?.[column.key] != null) ? NumberUtils.toFormatNumber(+item?.[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
                            if (column.key === "transactionType") return translate(`Vending Machine Type: ${item?.[column.key]}`)
                            if (!column.key) return "";
                            return item[column.key];
                        });
                    }),
                ];
                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                const now = new Date();
                XLSX.writeFile(wb, `Vending Machine List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, { type: "binary" });
                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    const filter: any = [
        {
            name: 'Search',
            key: "keyword",
            input: (e: any) => (
                <TableFilterInputText
                    {...e}
                    placeholder={"Search"}
                />
            ),
        },
        {
            name: 'Time',
            key: 'created',
            input: (e: any) => (
                <TableFilterRangeTimeInput
                    {...e}
                    fromKey="fromDate"
                    toKey="toDate"
                />
            ),
            defaultValue: {
                fromDate: today.clone().subtract(30, 'days').startOf('day').format('L HH:mm:ss'), 
                toDate: today.clone().startOf('day').format('L HH:mm:ss')
            }
        },
        {
            name: "Type",
            key: 'transactionType',
            input: (s: any) => (
                <TableFilterInputSelect {...s} options={[
                    {
                        label: 'DEPOSIT',
                        value: 'TRANSFER'
                    },
                    {
                        label: 'WITHDRAW',
                        value: 'WITHDRAW'
                    },
                    {
                        label: 'PURCHASE',
                        value: 'PURCHASE'
                    },
                ]} />
            )
        },
    ];

    const sumMiddleware = (func: (item: any) => any) => (item: any, fetchData: any, column: any) => {
        if (item?.isSum) {
            if (column.key === "projectName") return "SUM";
            if ([
                "amount",
            ].includes(column.key)) return (item?.sumReports?.[column.key] != null) ? NumberUtils.toFormatNumber(+item.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
            if (column.key === "transactionType") return null;
            if (column.key === "created") return null;
            return "--";
        }

        return func(item);
    };

    const structure: ITableStructureItem[] = [
        {
            name: "Project Name",
            key: "projectName",
            render: sumMiddleware(item => item?.projectName),
        },
        {
            name: "Representative's Name",
            key: "representativeName",
        },
        {
            name: "Buyer ID",
            key: "id",
        },
        {
            name: "Buyer Name",
            key: "firstName",
        },
        {
            name: "Type",
            key: "transactionType",
            render: sumMiddleware(item => <div className={`transaction-type transaction-type--${item?.transactionType.toLowerCase()}`}>{translate(`Vending Machine Type: ${item?.transactionType}`)}</div>)
        },
        {
            name: "GT Amount",
            key: "amount",
            render: sumMiddleware(item => (item?.amount != null) ? NumberUtils.toFormatNumber(+item.amount, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Machine ID",
            key: "machineId",
        },
        {
            name: "Date",
            key: "created",
            render: sumMiddleware((item: any) => moment(item?.created).format("L HH:mm:ss")),
        },
    ];

    return (
        <div className="VendingMachineListByAgency">
            <div className="back" onClick={() => history.push(Routes.vendingMachine.path)}>
                <div className="back__icon"><Icon.ArrowLeft /></div>
                <div className="back__text">Vending Machine</div>
            </div>
            <div className="button-container">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="primary"
                    disabled={!currentData}
                    onClick={handleExportExcel}
                />
                <div className="infor-container">
                    <div className="total-orders">
                        <div className="total-orders__icon">
                            <Icon.TotalDepositIcon />
                        </div>
                        <div className="total-orders__content">
                            {(currentData?.totalDeposit != null) ? NumberUtils.toFormatNumber(+currentData?.totalDeposit, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"}
                            <span className="sub-text">&nbsp;TOTAL DEPOSIT</span>
                        </div>
                    </div>
                    <div className="total-user">
                        <div className="total-user__icon">
                            <Icon.TotalDepositIcon />
                        </div>
                        <div className="total-user__content">
                            {(currentData?.totalWithdraw != null) ? NumberUtils.toFormatNumber(+currentData?.totalWithdraw, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"}
                            <span className="sub-text">&nbsp;TOTAL WITHDRAW</span>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                hasOrderColumn
                itemPerPages={10}
                className="overload"
                structure={structure}
                filters={filter}
                fetchData={async (params) => {
                    if (params["fromDate"]) params["fromDate"] = moment(params["fromDate"]).utcOffset(0, true).toISOString();
                    if (params["toDate"]) params["toDate"] = moment(params["toDate"]).utcOffset(0, true).toISOString();
                    setParams(params);
                    return AdminService.getVendingMachineTransactionByMachineId({
                        ...params,
                        page: params.pageNumber,
                        numberOfTransactionsPerPage: params.limit,
                        machineId: machineId
                    }).then((res: any) => {
                        setCurrentData(res);
                        let sumReports =
                            res?.data?.[0] != null
                                ? Object.keys(res?.data?.[0]).reduce((ouput: any, key: string) => {
                                    ouput[key] = res?.data?.reduce((sum: number, item: any, idx: number) => sum + item[key], 0);
                                    return ouput;
                                }, {})
                                : {};
                        return {
                            data: [{ isSum: true, sumReports: sumReports }, ...res?.data],
                            count: res?.count,
                        };
                    });
                }}
            />
        </div>
    );
};
