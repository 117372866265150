import { RequestMainService } from "../request";

export class ProductManagementService {
  static async getProductList( param: any){
    return RequestMainService.get("/admin/products",param).then(({result})=> {
      return {
        data: result.data,
        count: result.total,
      }
    });
  }

  static async getProductDetail(id: any) { 
    return RequestMainService.get(`/admin/products/${id}`).then(({ result })=> {
      return result.data
    });
  }

  static async changeProductStatus(param: any) {
    return RequestMainService.post(`/admin/product/change-status`, param);
  }

  static async getDeletedProductList( param: any){
    return RequestMainService.get("/admin/products-delete",param).then(({result})=> {
      return {
        data: result.data,
        count: result.total,
      }
    });
  }
}