import React, { FC } from "react";
import * as Yup from "yup";
import { InputNumber } from "../../../components";
import { translate } from "../../../languages";
import { Button, CreateAlert, InputWraper, useForm } from "../../../modules";
import { ConfigService } from "../../../services/config/config.service";

const CreatePool: FC<{ onFinishEdit: () => void }> = (props) => {
  const { handleSubmit, getInputProps } = useForm({
    structure: [
      {
        name: "reward",
        validate: Yup.string().required(translate("must-be-provided")),
      },
      {
        name: "streak",
        validate: Yup.string().required(translate("must-be-provided")),
      },
    ],
    onSubmit: async (values) => {
      const { reward, streak } = values;
      return ConfigService.createPoolStreak({
        reward,
        streak,
      })
        .then(() => {
          CreateAlert({
            message: "Create success.",
            type: "success",
          });
          props.onFinishEdit();
        })
        .catch((error: any) => {
          CreateAlert({
            message: error.message,
            type: "danger",
          });
        });
    },
  });

  return (
    <form className="Edit" onSubmit={handleSubmit}>
      <div className="group">
        <InputWraper
          label={"Reward"}
          inputProps={getInputProps("reward")}
          component={InputNumber}
          className="mr5"
        />
        <InputWraper
          label={translate("Streak")}
          inputProps={getInputProps("streak")}
          component={InputNumber}
          className="ml5"
        />
      </div>

      <div>
        <Button label="Create" isMiddle buttonType="success" className="btnBan" type="submit" />
      </div>
    </form>
  );
};

export default CreatePool;
