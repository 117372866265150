import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { FilterInputDateTime, TableFilterInputText, TableFilterRangeTimeInput } from "../../../components/table-filter-inputs";
import { TableFilterInputSelect } from "../../../components/table-filter-inputs/select";
import { getEnv } from "../../../configs";
import { translate } from "../../../languages";
import { Button, CreateAlert, Icon, InputWraper, ITableStructureItem, NumberUtils, Table, useForm } from "../../../modules";
import { AdminService } from "../../../services/admin";
import { withTransactionWraper } from "../wraper";
import XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../AppRoutes";
import * as Yup from "yup";
import { PopupWraper } from "../../../components/popup";
import { InputText, InputTextArea } from "../../../components";
import { ConfirmPopup } from "../../startup/components";

export const VendingMachineList = withTransactionWraper(() => {

    const history = useHistory();
    
    const [params, setParams] = useState<any>({});
    const [currentData, setCurrentData] = useState<any>();
    const [vendingMachineWallet, setVendingMachineWallet] = useState<any>();
    const [dataGrantBalance, setDataGrantBalance] = useState<any>();

    const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
            try {
                const response = await AdminService.getAgencyWithVendingMachineList({
                    ...params,
                    page: 1,
                    numberOfTransactionsPerPage: 10000,
                });
                let sumReports = (response?.data?.[0] != null) ? Object.keys(response?.data?.[0]).reduce((ouput: any, key: string) => {
                    ouput[key] = response?.data?.reduce((sum: number, item: any) => sum + item[key], 0);
                    return ouput;
                }, {}) : {};
                const data = [{isSum: true, sumReports: sumReports}, ...response.data];

                let fileHead: any = structure.map((v) => v.name);
                fileHead.pop(); //remove last column on table
                const dataExport = [
                    fileHead,
                    ...data.map((item: any) => {
                        if (item.isSum) {
                            return structure.map((column:any, index:any) => {
                                if (column.key === "machineId") return "SUM";
                                if ([
                                    "totalDeposit1Day",
                                    "totalWithdraw1Day",
                                    "totalDeposit7Days",
                                    "totalWithdraw7Days",
                                    "totalDeposit30Days",
                                    "totalWithdraw30Days"
                                ].includes(column.key)) return (item?.sumReports?.[column.key] != null) ? NumberUtils.toFormatNumber(+item.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
                                return "";
                            });
                        }
                        return structure.map((column:any, index:any) => {
                            if ([
                                "totalDeposit1Day",
                                "totalWithdraw1Day",
                                "totalDeposit7Days",
                                "totalWithdraw7Days",
                                "totalDeposit30Days",
                                "totalWithdraw30Days"
                            ].includes(column.key)) return (item?.[column.key] != null) ? NumberUtils.toFormatNumber(+item?.[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
                            if (!column.key) return "";
                            return item[column.key];
                        });
                    }),
                ];
                const ws = XLSX.utils.aoa_to_sheet(dataExport);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
                const now = new Date();
                XLSX.writeFile(wb, `Vending Machine List ${now.toLocaleDateString().replace(/\//g, "-")} ${now.toLocaleTimeString().replace(/:/g, "-")}.xlsx`, { type: "binary" });
                resolve(
                    CreateAlert({
                        type: "success",
                        message: "Export data success.",
                    }),
                );
            } catch (error: any) {
                resolve(CreateAlert({ type: "danger", message: error.message }));
            }
        });
    };

    const filter: any = [
        {
            name: translate("search"),
            key: "keyword",
            input: (e: any) => (
                <TableFilterInputText
                    {...e}
                    placeholder={"Search"}
                />
            ),
        },
        {
            name: "Report Date",
            key: "reportDate",
            input: (e: any) => (
                <FilterInputDateTime
                    {...e}
                    format="dd/MM/yyyy"
                />
            ),
            defaultValue: moment().format("L"),
        }
    ];

    const sumMiddleware = (func: (item: any) => any) => (item: any, fetchData: any, column: any) => {
        if (item?.isSum) {
            if (column.key === "machineId") return "SUM";
            if ([
                "totalDeposit1Day",
                "totalWithdraw1Day",
                "totalDeposit7Days",
                "totalWithdraw7Days",
                "totalDeposit30Days",
                "totalWithdraw30Days"
            ].includes(column.key)) return (item?.sumReports?.[column.key] != null) ? NumberUtils.toFormatNumber(+item.sumReports[column.key], +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000";
            if (column.key === "action") return null;
            return "--";
        }

        return func(item);
    };

    const structure: ITableStructureItem[] = [
        {
            name: "Machine ID",
            key: "machineId",
            render: sumMiddleware(item => item?.machineId),
        },
        {
            name: "Agency ID",
            key: "agencyId",
        },
        {
            name: "Project Name",
            key: "projectName",
        },
        {
            name: "Representative's ID",
            key: "id",
        },
        {
            name: "Representative's Name",
            key: "representativeName",
        },
        {
            name: "Representative's Email",
            key: "representativeEmail",
        },
        {
            name: "Total GT Deposit (1 Day)",
            key: "totalDeposit1Day",
            render: sumMiddleware(item => (item?.totalDeposit1Day != null) ? NumberUtils.toFormatNumber(+item.totalDeposit1Day, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Total GT Withdraw (1 Day)",
            key: "totalWithdraw1Day",
            render: sumMiddleware(item => (item?.totalWithdraw1Day != null) ? NumberUtils.toFormatNumber(+item.totalWithdraw1Day, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Total GT Deposit (7 Days)",
            key: "totalDeposit7Days",
            render: sumMiddleware(item => (item?.totalDeposit7Days != null) ? NumberUtils.toFormatNumber(+item.totalDeposit7Days, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Total GT Withdraw (7 Days)",
            key: "totalWithdraw7Days",
            render: sumMiddleware(item => (item?.totalWithdraw7Days != null) ? NumberUtils.toFormatNumber(+item.totalWithdraw7Days, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Total GT Deposit (30 Days)",
            key: "totalDeposit30Days",
            render: sumMiddleware(item => (item?.totalDeposit30Days != null) ? NumberUtils.toFormatNumber(+item.totalDeposit30Days, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Total GT Withdraw (30 Days)",
            key: "totalWithdraw30Days",
            render: sumMiddleware(item => (item?.totalWithdraw30Days != null) ? NumberUtils.toFormatNumber(+item.totalWithdraw30Days, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"),
        },
        {
            name: "Action",
            key: "action",
            render: sumMiddleware((item: any) => (
                <div className="action">
                    <div className="action__detail" onClick={() => history.push(Routes.transactionByMachineId.renderPath(item?.machineId))}><Icon.View /></div>
                    <div className="action__grant" onClick={() => setDataGrantBalance(item)}><Icon.ActionEditIcon /></div>
                </div>
            )),
        }
    ];

    useEffect(() => {
        AdminService.getVendingMachineWallet().then((res) => {
            setVendingMachineWallet(res?.result);
        })
    }, [])

    return (
        <div className="VendingMachineList">
            <div className="button-container">
                <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="primary"
                    disabled={!currentData}
                    onClick={handleExportExcel}
                />
                <div className="infor-container">
                    <div className="total-orders">
                        <div className="total-orders__icon">
                            <Icon.VendingMachineWalletIcon />
                        </div>
                        <div className="total-orders__content">
                            {(vendingMachineWallet?.balance != null) ? NumberUtils.toFormatNumber(+vendingMachineWallet?.balance, +getEnv("NUMBER_DECIMAL_DISPLAY")) : "0.00000000"}
                            <span className="sub-text">&nbsp;VENDING MACHINE WALLET</span>
                        </div>
                    </div>
                </div>
            </div>
            <Table
                hasOrderColumn
                itemPerPages={10}
                className="overload"
                structure={structure}
                filters={filter}
                fetchData={async (params) => {
                    if (params["reportDate"]) params["reportDate"] = moment(params["reportDate"]).utcOffset(0, true).toISOString();
                    setParams(params);
                    return AdminService.getVendingMachineList({
                        ...params,
                        page: params.pageNumber,
                        numberOfTransactionsPerPage: params.limit,
                    }).then((res:any) => {
                        setCurrentData(res);
                        let sumReports = (res?.data?.[0] != null) ? Object.keys(res?.data?.[0]).reduce((ouput: any, key: string) => {
                            ouput[key] = res?.data?.reduce((sum: number, item: any) => sum + item[key], 0);
                            return ouput;
                        }, {}) : {};
                        return {
                            data: [{isSum: true, sumReports: sumReports}, ...res?.data], 
                            count: res?.count
                        };
                    });
                }}
            />
            {dataGrantBalance && (
                <GrantBalancePopup 
                    onClose={() => setDataGrantBalance(null)}
                    dataGrantBalance={dataGrantBalance}
                />
            )}
        </div>
    );
});

const GrantBalancePopup = (props: any) => {

    const [isShowConfirmPopup, setIsShowConfirmPopup] = useState<boolean>(false);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);

    const { getInputProps, handleSubmit, isSubmitting } = useForm({
        structure: [
            {
                name: "value",
                validate: Yup.number()
                    .required("Must be provided")
                    .moreThan(0, "Must be greater than 0"),
            },
            {
                name: "description",
                validate: Yup.string().required("Must be provided"),
            },
        ],
        onSubmit: async (values) => {
            setIsShowConfirmPopup(true);
        },
    });

    let handleOnClickConfirmGrant = async () => {
        let payload = {
            machineId: props?.dataGrantBalance?.machineId,
            value: +getInputProps('value').value,
            description: getInputProps('description').value
        };
        setIsRequesting(true);
        return AdminService.grantBalanceByMachineId(payload).then(() => {
            setIsShowConfirmPopup(false);
            props?.onClose();
            CreateAlert({ message: "Grant balance successfully", type: "success" });
        }).catch((err: any) => {
            CreateAlert({ message: translate(err.message), type: "danger" });
        }).finally(() => setIsRequesting(false));
    }

    return (
        <div className="grant-balance-popup">
            <PopupWraper
                title="Grant Balance"
                center
                onClose={() => props?.onClose()}
            >
                <InputWraper
                    label={"Value"}
                    inputProps={getInputProps('value')}
                    component={InputText}
                />
                <InputWraper
                    label={"Description"}
                    inputProps={getInputProps('description')}
                    component={InputTextArea}
                />
                <div className="d-flex justify-content-center">
                    <Button 
                        label={"Grant"}
                        onClick={handleSubmit}
                    />
                </div>
            </PopupWraper>
            {isShowConfirmPopup && (
                <PopupWraper 
                    id="grant-confirm-popup" 
                    center 
                    title="Confirm" 
                    onClose={() => setIsShowConfirmPopup(false)}
                >
                    <div className="text">{`Are you sure you want to grant a balance of "${getInputProps('value').value} GT" to a vending machine with ID "${props?.dataGrantBalance?.machineId}" with description "${getInputProps('description').value}"?`}</div>
                    <div className="mt32 d-flex justify-content-center">
                        <Button
                            label={"Confirm"}
                            onClick={() => handleOnClickConfirmGrant()}
                            isLoading={isRequesting}
                            disabled={isRequesting}
                        />
                    </div>
                </PopupWraper>
            )}
        </div>
    );
};