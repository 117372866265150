import React, { FC, useEffect, useState } from 'react';
import { AdminService } from '../../services/admin';
import { PopupWraper } from '../popup';
import { NumberUtils } from '../../modules';
import { getEnv } from '../../configs';

// function selects(obj: any, keys: any[]) {
//     return keys.reduce((acc, key) => {
//         acc[key] = obj[key];
//         return acc;
//     }, {})
// }

export function flatTreeData(input: any[]) {
  try {
    let output = [];

    const getChildren = (obj: any) => {
      if (obj && obj.children && obj.children.length > 0) {
        const { children } = obj;

        children.map((item: any) => {
          output.push({ ...item });
          getChildren(item);
          return item;
        });
      }
    };

    output.push(input);
    getChildren(input);

    output.map((item: any) => {
      delete item.children;
      return item;
    });

    return output;
  } catch (error) {
    return [];
  }
}

export const TreeView: FC<{
  affiliate: any[];
  onGetTree: (id: any) => void;
}> = (props) => {
  let [affiliation, setAffiliation] = useState(null as any);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [dataReferralSponsor, setDataReferralSponsor] = useState<any>([]);

  useEffect(() => {
    setTree();
    // eslint-disable-next-line
  }, []);

  const setTree = () => {
    // @ts-ignore
    const OrgChart: any = window ? window.OrgChart : undefined;

    if (OrgChart) {
      const { affiliate, onGetTree } = props;
      setAffiliation(affiliate);
      // console.log("affiliate",affiliate);

      const data = affiliate.map((item: any) => {
        let email = item.email;
        let level = item.rank;
        let img = `/assets/images/rank/rank_${item.rank}.png`;
        // let fullname = item.firstName.toUpperCase() + ' ' + item.lastName.toUpperCase();
        let fullname = item.firstName + ' ' + item.lastName;

        return {
          // ...selects(item, ['email', 'parentId', 'level', 'volume', 'numberOfF1Agency', 'volumeAllBranch']),
          id: item.userId,
          pid: item.presenterId,
          'Display name': fullname,
          Email: email,
          // Level: level,
          // "Personal volume": item.volume,
          // "Downlines volume": item.volumeAllBranch,
          // 'Presenter Email': item.presenterEmail,
          img
        };
      });

      //[BEGIN] - customize template
      OrgChart.templates.myTemplate = Object.assign(
        {},
        OrgChart.templates.polina
      );
      OrgChart.templates.myTemplate.node =
        '<rect x="0" y="0" height="60" width="300" fill="#039BE5" stroke-width="1" stroke="#686868" rx="30" ry="30"></rect>';
      OrgChart.templates.myTemplate.size = [300, 60];
      OrgChart.templates.myTemplate.img_0 =
        '<clipPath id="ulaImg">' +
        '<circle cx="20" cy="20" r="35"></circle>' +
        '</clipPath>' +
        '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="8" y="5" width="35" height="45">' +
        '</image>';
      OrgChart.templates.myTemplate.field_0 =
        '<text width="210" class="field_0" style="font-size: 18px;" fill="#ffffff" x="48" y="26" text-anchor="start">{val}</text>';
      OrgChart.templates.myTemplate.field_1 =
        '<text width="210" class="field_1" style="font-size: 14px;" fill="#ffffff" x="48" y="45" text-anchor="start">{val}</text>';
      OrgChart.templates.myTemplate.nodeMenuButton =
        '<g style="cursor:pointer;" transform="matrix(1,0,0,1,270,23)" control-node-menu-id="{id}"><rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect><circle cx="0" cy="0" r="2" fill="#ffffff"></circle><circle cx="0" cy="7" r="2" fill="#ffffff"></circle><circle cx="0" cy="14" r="2" fill="#ffffff"></circle></g>';
      //[END] - customize template

      var treeIcon =
        '<svg width="12px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><path d="M17,16l-4-4V8.82a3,3,0,1,0-2,0V12L7,16H3v5H8V17.95l4-4.2,4,4.2V21h5V16Z" transform="translate(-3 -3)" /></svg>';
      var chart = new OrgChart(document.getElementById('tree'), {
        template: 'myTemplate',
        enableSearch: false,
        nodeBinding: {
          field_0: 'Display name',
          field_1: 'Email',
          // field_2: "Level",
          // field_3: "Personal volume",
          // field_4: "Downlines volume",
          // field_5: 'Presenter Email',
          img_0: 'img'
        },
        nodeMouseClick: OrgChart.action.none,
        orientation: OrgChart?.orientation?.left,
        collapse: { level: 1, allChildren: true },
        mouseScrool: OrgChart?.action?.ctrlZoom,
        enableKeyNavigation: true,
        levelSeparation: 200, //The gap between each level. Default value - 60
        siblingSeparation: 20, //The gap between nodes in a subtree. Default value - 20
        subtreeSeparation: 40, //The gap between subtrees. Default value - 40
        sticky: true, //Stops the chart locking to the top of the screen once you move it.
        lazyLoading: false,
        nodeMenu: {
          call: {
            text: 'Get tree',
            icon: treeIcon,
            onClick: (nodeId: any) => getTreeHandler(nodeId)
          },
          call2: {
            text: 'Get referral sponsor',
            icon: treeIcon,
            onClick: (nodeId: any) => handleClickGetReferralSponsor(nodeId)
          }
        },
        nodes: data
      });

      chart.on('click', async function (sender: any, args: any) {
        var nodeSender = sender.get(args.node.id);
        let currentNode = chart.config.nodes.find(
          (element: any) => element.id === nodeSender.id
        );
        await AdminService.getAffiliateDetail(nodeSender.id).then((res) => {
          if (currentNode != null) {
            chart.updateNode({
              ...currentNode,
              // "Email": res?.result?.email,
              // "Display Name": res?.result?.firstName + ' ' + res?.result?.lastName,
              // "Username": res?.result?.userName,
               // "Presenter ID": res?.result?.presenterId,
              "ID": res?.result?.id,
              "Presenter Email": res?.result?.presenterEmail,
              'Sponser Email': res?.result?.sponsorEmail,
              'Sponser ID': res?.result?.sponsorId,
              Level: res?.result?.level,
              'Personal volume': res?.result?.volume,
              'Downlines volume': res?.result?.volumeAllBranch
              // "Rank": res?.result?.rank,
              // "Invest Status": res?.result?.investStatus,
              // "Volume": NumberUtils.toFormatNumber(+res?.result?.volume, +getEnv("NUMBER_DECIMAL_DISPLAY")),
              // "Number Of F1 Agency": res?.result?.numberOfF1Agency,
              // "Volume All Branch": NumberUtils.toFormatNumber(+res?.result?.volumeAllBranch, +getEnv("NUMBER_DECIMAL_DISPLAY")),
              // "Order Fee": NumberUtils.toFormatNumber(+res?.result?.orderFee, +getEnv("NUMBER_DECIMAL_DISPLAY")),
              // "Main Balance": NumberUtils.toFormatNumber(+res?.result?.mainBalance, +getEnv("NUMBER_DECIMAL_DISPLAY")),
              // "Description": res?.result?.description,
              // "Total Package Volume": NumberUtils.toFormatNumber(+res?.result?.totalPackageVolume, +getEnv("NUMBER_DECIMAL_DISPLAY")),
              // "Total Downline": res?.result?.totalDownline
            });
            sender.editUI.show(nodeSender.id, true);
          }
        });
      });

      const getTreeHandler = (nodeId: any) => {
        // console.log("nodeId",nodeId);
        var nodeData = chart.get(nodeId);
        // console.log("nodeData",nodeData);
        onGetTree(nodeData.id);

        // var employeeName = nodeData["name"];
      };

      const handleClickGetReferralSponsor = (nodeId: any) => {
        AdminService.getReferralSponsor(nodeId, 15).then((res: any) =>
          setDataReferralSponsor(res?.result)
        );
      };
    }

    // eslint-disable-next-line
  };

  if (affiliation) {
    if (affiliation?.[0]?.userId !== props?.affiliate?.[0]?.userId) {
      setTree();
      setAffiliation(props?.affiliate);
    }
  }

  return (
    <div className="TreeView">
      <div id="tree" />
      {dataReferralSponsor?.length > 0 && (
        <PopupWraper
          center
          title="Referral sponsor"
          onClose={() => setDataReferralSponsor([])}
        >
          {dataReferralSponsor?.map((element: any, idx: number) => (
            <div>{`${idx + 1}. ${element?.email}`}</div>
          ))}
        </PopupWraper>
      )}
    </div>
  );
};
