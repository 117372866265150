import React from "react";
import { PopupWraper } from "../../../../components/popup";
import { Button } from "../../../../modules";

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  message: string
}

export const ConfirmPopup = ({ onClose, onConfirm, message} : Props) => {

  return (
    <PopupWraper center title="Confirm" onClose={() => onClose()}>
      <h4>{message}</h4>
      <div className="mt32 d-flex justify-content-between">
        <Button
          label={"Exit"}
          onClick={()=> onClose()}
          buttonType="grey"
        />
         <Button
          label={"Confirm"}
          onClick={()=> onConfirm()}
        />
      </div>
    </PopupWraper>
  )
}