import React, { FC, useEffect, useState } from 'react';
import { AdminService } from '../../../../services/admin';
import { useRouteMatch } from 'react-router';
import { ITableStructureItem, Table } from '../../../../modules';
import { PageHead } from '../../../../components/page-head';
import { Routes } from '../../../../AppRoutes';
import { getLocaleKey, translate } from '../../../../languages';

export const RevenueAgencyDetail: FC = () => {
  const router = useRouteMatch();
  const agenctId = Object.values(router.params)?.[0];
  const agenctName = Object.values(router.params)?.[1];

  const structure: ITableStructureItem[] = [
    {
      name: translate('production-service-label'),
      key: 'name',
      render: (item) => {
        return <p>{item.name}</p>;
      }
    },
    {
      name: translate('price'),
      key: 'price',
      render: (item) => {
        return (
          <p>
            {item?.amount?.toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })}
          </p>
        );
      }
    },
    {
      name: translate('quantity'),
      key: 'totalquantity',
      render: (item) => {
        return <p>{item.totalquantity}</p>;
      }
    },
    {
      name:"Profits (GT)",
      key: 'profits',
      render: (item) => {
        const profit = (item?.amount - item?.originalPrice) || 0;
        return (
          <p>
            {profit.toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })}
          </p>
        );
      }
    },
    {
      name: "Total Profits (GT)",
      key: 'totalrevenues',
      render: (item) => {
        return (
          <p>
            {item?.totalrevenues?.toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })}
          </p>
        );
      }
    },
    {
      name: `${translate('revenue-label')} (GT)`,
      key: 'totalrevenues',
      render: (item) => {
        return (
          <p>
            {item?.totalrevenues?.toLocaleString(getLocaleKey(), {
              maximumFractionDigits: 3,
              minimumFractionDigits: 3
            })}
          </p>
        );
      }
    }
  ];
  return (
    <>
      <PageHead
        urlPath={Routes.revenueAgency.path}
        title={String(agenctName) || ''}
      />
      <Table
        hasOrderColumn
        itemPerPages={10}
        className="overload"
        structure={structure}
        // filters={[
        //   {
        //     name: translate('search'),
        //     key: 'keyword',
        //     input: TableFilterInputText
        //   }
        // ]}
        fetchData={async (params) => {
          //   setParams(params);
          return AdminService.getRevenueBelowAgent(agenctId, {
            page: 1,
            pageSize: 10,
            ...params
          });
        }}
      />
    </>
  );
};
