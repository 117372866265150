import capitalize from "lodash/capitalize";
import moment from "moment";
import React, { memo, useState } from "react";
import XLSX from "xlsx";
import { Button, Icon, NumberUtils, ObjectUtils, Table } from "../../modules";
import { getEnv } from "../../configs";
import { useSelector } from "../../store";
import { AdminService } from "../../services/admin";
import { ECoinCode, ENetWork, ETransactionType } from "../../types";
import { TableFilterInputSelect } from "../../components/table-filter-inputs/select";
import { translate } from "../../languages";
import { TableFilterInputText, TableFilterRangeTimeInput } from "../../components/table-filter-inputs";
import { BankService } from "../../services";
import { withStakingWraper } from "./withStakingWraper";

export const StakingPage = withStakingWraper(() => {
    // const [currentData, setCurrentData] = useState(null as any);
    // const [params, setParams] = useState([] as any[]);

    const startOfMonth = moment().startOf("month").format("YYYY/MM/DD HH:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY/MM/DD HH:mm:ss");

    const coins = useSelector((state) => state.coins);

    let structure = [
        {
            name: "Coin",
            key: "coinId",
            className: "coin",
            render: (item: any) => {
                const coin = coins?.data?.find((item: any) => item?.coinId === item.coinId);
                let coinAvatar;
                switch (coin?.code) {
                    case ECoinCode.KTGT: {
                        coinAvatar = "ktgt.png";
                        break;
                    }
                    case ECoinCode.GTTAX: {
                        coinAvatar = "gttax.png";
                        break;
                    }
                }
                return (
                    <>
                        <img
                            className="coin-avatar"
                            src={`/assets/images/coins/${coinAvatar}`}
                            alt=""
                        />
                        <span className="coin-label">{coins?.data?.find((coin:any) => coin.coinId === item.coinId)?.name}</span>
                    </>
                );
            },
        },
        {
            name: "ID",
            key: "id",
            className: "email",
        },
        {
            name: "Email",
            key: "email",
            className: "email",
        },
        {
            name: "Amount",
            key: "value",
            render: (item: any) => {
                const { balanceAfter, balanceBefore } = item;
                let isIncrease = false;
                if (balanceAfter > balanceBefore) isIncrease = true;
                return (
                    <span className={isIncrease ? "textSuccess" : "textDanger"}>
                        {isIncrease ? "+" : "-"}
                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(+item?.value)}
                    </span>
                );
            },
        },
        {
            name: "Balance After",
            key: "balanceAfter",
            render: (item: any) => {
                return <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY"), maximumFractionDigits: +getEnv("NUMBER_DECIMAL_DISPLAY") }).format(+item?.balanceAfter)}</span>;
            },
        },
        {
            name: "Type",
            key: "transactionTypeId",
            render: (item: any) => {
                return ObjectUtils.getIn(capitalize(item.transactionType.name.replace(/_/g, " ")), "", "--");
            },
        },
        {
            name: "Time",
            key: "created",
            render: (item: any) => {
                return <span>{moment(item?.created).format("DD/MM/y HH:mm:ss")}</span>;
            },
        },
    ];

    return (
        <div className="staking-page">
            <Table
                hasOrderColumn
                hasSearchButton={true}
                itemPerPages={10}
                filters={[
                    {
                        name: translate("time"),
                        key: "created",
                        input: (e) => (
                            <TableFilterRangeTimeInput
                                {...e}
                                fromKey="fromDate"
                                toKey="toDate"
                            />
                        ),
                        defaultValue: {
                            fromDate: startOfMonth, //new Date(last1MothDate),
                            toDate: endOfDay, //new Date(),
                        },
                    },
                    {
                        name: "ID",
                        key: "id",
                        input: TableFilterInputText,
                    },
                    {
                        name: translate("type"),
                        key: "transactionTypeId",
                        input: (s) => (
                            <TableFilterInputSelect
                                {...s}
                                options={BankService.getTransactionTypeOptions()?.filter((item) => [
                                    ETransactionType.MINED_GT,
                                    ETransactionType.MINED_DEPOSIT,
                                    ETransactionType.MINED_WITHDRAW,
                                    ETransactionType.MINED_PROFIT,
                                    ETransactionType.MINED_CAPITAL,
                                    ETransactionType.MINED_SYSTEM_TRANSFER
                                ].includes(item.value))}
                            />
                        ),
                    },
                ]}
                structure={structure}
                fetchData={async (state) => {
                    let params = { ...state };
                    if (params["fromDate"]) params["fromDate"] = new Date(params["fromDate"]);
                    if (params["toDate"]) params["toDate"] = new Date(params["toDate"]);
                    // setParams(params);

                    return AdminService.getListTransactions({
                        ...params,
                        page: params.pageNumber,
                        numberOfTransactionsPerPage: params.limit,
                        transactionTypeId: ((state?.transactionTypeId ?? '') === '') ? [
                            ETransactionType.MINED_GT,
                            ETransactionType.MINED_DEPOSIT,
                            ETransactionType.MINED_WITHDRAW,
                            ETransactionType.MINED_PROFIT,
                            ETransactionType.MINED_CAPITAL,
                            ETransactionType.MINED_SYSTEM_TRANSFER
                        ] : state?.transactionTypeId,
                    }).then((res) => {
                        // setCurrentData(res);
                        return res;
                    });
                }}
            />
        </div>
    )
});
