// @ts-nocheck
// ignore error ts

import React, { useEffect, useRef, useState } from "react";

export const EditorForm = (props:any) => {
    const editorRef = useRef(null);
    const [editorLoaded, setEditorLoaded] = useState(false);
    const { CKEditor, ClassicEditor } = editorRef.current || {};

    useEffect(() => {
        editorRef.current = {
            CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
            ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
        };
        setEditorLoaded(true);
    }, []);

    return editorLoaded ? (
        <div className="editor">
            <CKEditor
                id={props?.name}
                editor={ClassicEditor}
                data={props?.value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    props?.onChange(data);
                }}
                config={{
                    toolbar: [
                        'undo', 'redo', '|',
                        'heading', '|',
                        'bold', 'italic', 'underline', 'strikethrough', '|',
                        'link', '|',
                        'bulletedList', 'numberedList', '|',
                        'indent', 'outdent', '|',
                        'blockQuote', 'insertTable', '|',
                        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
                        'highlight', 'removeFormat', '|',
                        'specialCharacters', 'horizontalLine', '|',
                        'maximize'
                    ]
                }}
            />
        </div>
    ) : (
        <></>
    );
};
