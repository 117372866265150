import React from "react";
import { Button } from "../../../modules";
import { withConfigWraper } from "../wraper";
import TablePool from "./tablePool";

type Props = {};

const PoolConfig = withConfigWraper((props: Props) => {
  return (
    <div className="row mb20">
      <div className="col-sm-12">
        <TablePool />
      </div>
    </div>
  );
});

export default PoolConfig;
