import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import XLSX from 'xlsx';

import { withProductManagementWrapper } from '../wrapper';
import {
  Button,
  CreateAlert,
  Icon,
  ITableStructureItem,
  Message,
  Table,
  TableScroll
} from '../../../modules';
import { AdminService } from '../../../services/admin';
import './orderManagement.scss';
import {
  InputDateTimeRange,
  TableFilterInputText,
  TableFilterRangeTimeInput,
  TableFilterInputTextCustom
} from '../../../components/table-filter-inputs';
import { InputToggleSwitch } from '../../../components/toggle-switch';
import { InputSelect, InputText } from '../../../components';
import { moduleConfig } from '../../../modules/module.config';
import { getLocaleKey, translate } from '../../../languages';
import { NavLink } from 'react-router-dom';
import { Routes } from '../../../AppRoutes';
import { TableFilterInputSelect as TableFilterInputSelectCustom } from '../../../components/table-filter-inputs/select-custom';
export const OrderManagement = withProductManagementWrapper(
  memo(
    () => {
      const startOfMonth = moment()
        .startOf('month')
        .format('YYYY/MM/DD HH:mm:ss');
      const endOfDay = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss');
      let [agencyOptions, setAgencyOptions] = useState<any>(null);
      let [statusOptions, setStatusOptions] = useState([
        { value: 'COMPLETED', label: translate('COMPLETED') },
        { value: 'DELIVERY_WAITING', label: translate('DELIVERY_WAITING') },
        { value: 'CANCELLED', label: translate('CANCELLED') }
      ] as any[]);
      const [params, setParams] = useState([] as any[]);
      const [disableExport, setDisableExport] = useState<boolean>(true);

      // let [receiveAtStore, setReceiveAtStore] = useState<boolean>(true);
      // let [inputTextFilter, setInputTextFilter] = useState<string>('');

      // let [statusSelected, setStatusSelected] = useState(
      //   statusOptions[0] as any
      // );
      // let [params, setParams] = useState<any>();
      // let [orderData, setOrderData] = useState(null as any);
      // let [rangeTime, setRangeTime] = useState({
      //   startTime: moment().startOf('month').format('YYYY/MM/DD HH:mm:ss'), //Date.now() - 1000 * 60 * 60 * 24 * 30,
      //   endTime: moment().endOf('day').format('YYYY/MM/DD HH:mm:ss') //Date.now(),
      // } as any);
      // useEffect(() => {
      //   AdminService.getListOrder({
      //     fromDate: new Date(rangeTime?.startTime),
      //     toDate: new Date(rangeTime?.endTime),
      //     page: 1,
      //     pageSize: 10
      //   })
      //     .then((res) => {
      //       setOrderData({
      //         count: res.count,
      //         data: res.data
      //       });
      //     })
      //     .catch((err) => setOrderData({ error: err.error }));
      // }, []);

      // useEffect(() => {
      //   // setOrderData(null);
      //   AdminService.getListOrder({
      //     fromDate: new Date(rangeTime?.startTime),
      //     toDate: new Date(rangeTime?.endTime),
      //     page: params?.pageNumber || 1,
      //     pageSize: params?.limit || 20
      //   })
      //     .then((res) => {
      //       setOrderData({
      //         count: res.count,
      //         data: res.data
      //       });
      //     })
      //     .catch((err) => setOrderData({ error: err.error }));
      // }, [params]);

      // const handleFilter = async () => {
      //   setOrderData(null);
      //   let params: any = {
      //     fromDate: new Date(rangeTime?.startTime),
      //     toDate: new Date(rangeTime?.endTime),
      //     status: statusSelected?.value || '',
      //     keyword: inputTextFilter || ''
      //   };
      //   // if (statusSelected) params['status'] = statusSelected;
      //   // if (inputTextFilter) params['keyword'] = statusSelected;

      //   AdminService.getListOrder(params)
      //     .then((res) => {
      //       setOrderData({
      //         count: res.data.length,
      //         data: res.data
      //       });
      //     })
      //     .catch((err) => setOrderData({ error: err.error }));
      // };

      useEffect(() => {
        AdminService.getListAgency().then((res: any) => {
          let result = res?.data || [];
          let options = result.map((element: any) => ({
            label: element?.agencyName,
            value: element?.agencyId
          }));
          setAgencyOptions(options);
        });
      }, []);

      const handleExportExcel = async () => {
        return new Promise(async (resolve) => {
          try {
            const response = await AdminService.getListOrder({
              ...params,
              page: 1,
              pageSize: 10000
            });
            const data = response.data;

            let fileHead: any = structure.map((v) => v.name);
            fileHead.pop(); //remove last column on table
            const dataExport = [
              fileHead,
              ...data.map((item: any) => {
                return structure.map((column, index) => {
                  if (column.key === 'deliverType') {
                    item[column.key] =
                      item[column.key] === 'ONLINE' ? 'NO' : 'YES';
                  }
                  if (column.key === 'created')
                    return moment(item[column.key]).format('L HH:mm:ss');
                  if (column.key === 'status')
                    return translate(item[column.key]);
                  if (!column.key) return '';
                  return item[column.key];
                });
              })
            ];
            const ws = XLSX.utils.aoa_to_sheet(dataExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
            const now = new Date();
            XLSX.writeFile(
              wb,
              `Order List ${now.toLocaleDateString().replace(/\//g, '-')} ${now
                .toLocaleTimeString()
                .replace(/:/g, '-')}.xlsx`,
              { type: 'binary' }
            );
            resolve(
              CreateAlert({
                type: 'success',
                message: 'Export data success.'
              })
            );
          } catch (error: any) {
            resolve(CreateAlert({ type: 'danger', message: error.message }));
          }
        });
      };

      const structure: ITableStructureItem[] = [
        {
          name: translate('order-id-label'),
          key: 'orderCode',
          render: (item) => {
            return <p className="orderCode">{item.orderCode}</p>;
          }
        },
        {
          name: translate('order-name-label'),
          key: 'productName',
          render: (item) => {
            return <p className="product-name">{item.productName}</p>;
          }
        },
        {
          name: translate('user-email-label'),
          key: 'email',
          render: (item) => {
            return <p>{item.email}</p>;
          }
        },
        // {
        //   name: 'Address',
        //   key: 'address',
        //   render: (item) => {
        //     return <p className="address">{item.address}</p>;
        //   }
        // },
        // {
        //   name: 'Product Type',
        //   key: 'productType',
        //   render: (item) => {
        //     return <p>{item.productType || '--'}</p>;
        //   }
        // },
        {
          name: translate('agency-label'),
          key: 'agencyName',
          render: (item) => {
            return <p>{item.agencyName}</p>;
          }
        },
        {
          name: translate('DATE'),
          key: 'created',
          render: (item) => {
            // return <p>{moment(item?.date).format('DD/MM/y HH:mm:ss')}</p>;
            return (
              <p className="date">
                {moment(item.created).format('HH:mm, ddd DD/MM/YYYY')}
              </p>
            );
          }
        },
        {
          name: translate('Quantity'),
          key: 'quantity',
          render: (item) => {
            return <p>{item.quantity}</p>;
          }
        },
        {
          name: `${translate('revenue-label')} (GT)`,
          key: 'totalAmount',
          render: (item) => {
            return (
              <p>
                {item?.totalAmount?.toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })}
              </p>
            );
          }
        },
        {
          name: `Profits (GT)`,
          key: 'Profits',
          render: (item) => {
            const profits =item?.totalAmount - (item?.originalPrice * item?.quantity);
            return (
              <p>
                {profits.toLocaleString(getLocaleKey(), {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: 3
                })}
              </p>
            );
          }
        },
        {
          name: translate('order-status'),
          key: 'status',
          render: (item) => {
            return (
              <p
                className={
                  item.status === 'COMPLETED'
                    ? 'status completed'
                    : item.status === 'CANCELLED'
                    ? 'status cancelled'
                    : item.status === 'DELIVERY_WAITING'
                    ? 'status waiting'
                    : ''
                }
              >
                {translate(item.status)}
              </p>
            );
          }
        },
        // {
        //   name: 'Receive at the store',
        //   key: 'deliverType',
        //   render: (item) => {
        //     return (
        //       <InputToggleSwitch
        //         onChange={async (value) => ({})}
        //         value={item.deliverType === 'ONLINE' ? false : true}
        //         onTouched={() => false}
        //         label=""
        //         name=""
        //       />
        //     );
        //   }
        // },
        {
          name: translate('actions-label'),
          key: 'actions',
          render: (item) => {
            return (
              <div className="action">
                <div className="action__edit">
                  <NavLink
                    to={Routes.orderDetail.renderPath(item.orderId)}
                    exact={true}
                  >
                    <div style={{ color: 'white' }}>
                      <Icon.View />
                    </div>
                  </NavLink>
                </div>
              </div>
            );
          }
        }
      ];
      return (
        <>
          {(() => {
            if (!agencyOptions) {
              return <Message type="loading" />;
            } else {
              return (
                <div className="OrderList">
                  <Button
                    className="mb20"
                    label="Export to Excel"
                    buttonType="primary"
                    disabled={disableExport}
                    onClick={handleExportExcel}
                  />
                  <Table
                    // doubleScroll
                    hasOrderColumn
                    itemPerPages={10}
                    className="overload"
                    structure={structure}
                    filters={[
                      {
                        name: translate('time'),
                        key: 'created',
                        input: (e) => (
                          <TableFilterRangeTimeInput
                            {...e}
                            fromKey="fromDate"
                            toKey="toDate"
                          />
                        ),
                        defaultValue: {
                          fromDate: startOfMonth, //new Date(last1MothDate),
                          toDate: endOfDay //new Date(),
                        }
                      },
                      {
                        name: translate('status'),
                        key: 'status',
                        input: (s) => (
                          <TableFilterInputSelectCustom
                            {...s}
                            options={statusOptions}
                          />
                        )
                      },
                      {
                        name: translate('agency-label'),
                        key: 'agencyId',
                        input: (s) => (
                          <TableFilterInputSelectCustom
                            {...s}
                            options={agencyOptions}
                          />
                        )
                      },
                      {
                        name: translate('search'),
                        key: 'keyword',
                        input: (e: any) => {
                          return (
                            <TableFilterInputTextCustom
                              {...e}
                              placeholder={translate(
                                'search-by-orderId-productionName-email,agencyName'
                              )}
                            />
                          );
                        }
                      }
                    ]}
                    fetchData={async (params) => {
                      setParams(params);
                      if (params['fromDate'])
                        params['fromDate'] = new Date(params['fromDate']);
                      if (params['toDate'])
                        params['toDate'] = new Date(params['toDate']);

                      return AdminService.getListOrder({
                        page: params.pageNumber,
                        pageSize: params.limit,
                        ...params
                      }).then((res) => {
                        setDisableExport(!(res?.data?.length > 0));
            
                        return res;
                      });;
                    }}
                  />
                </div>
              );
            }
          })()}
        </>
      );
    },
    () => true
  )
);
