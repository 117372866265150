import moment from 'moment';
import React, { memo, useState } from 'react';
import XLSX from 'xlsx';
import { PopupWraper } from '../../../components/popup';
import { TableFilterInputText } from '../../../components/table-filter-inputs';
import { TableFilterInputSelect } from '../../../components/table-filter-inputs/select';
import { translate } from '../../../languages';
import {
  Button,
  CreateAlert,
  Icon,
  ITableStructureItem,
  Table
} from '../../../modules';
import { AdminService } from '../../../services/admin';
import { withUserWraper } from '../wraper';
import { Ban } from './ban';
import { EditUser } from './edit';
import './UserList.scss';

export const UserList = withUserWraper(
  memo(() => {
    const [disableExport, setDisableExport] = useState<boolean>(true);
    const [params, setParams] = useState([] as any[]);
    const [editData, setEditData] = useState(null);
    const [freezeDataId, setFreezeDataId] = useState<number | null>(null);
    const [forceUpdateTable, setForceUpdateTable] = useState(Math.random());
    // const user = useSelector((state) => state.user);

    const structure: ITableStructureItem[] = [
      {
        name: 'ID',
        key: 'id',
        render: (item) => {
          return <span className="email">{item.id}</span>;
        }
      },
      {
        name: 'Email',
        key: 'email',
        render: (item) => {
          return <span className="email">{item.email}</span>;
        }
      },
      {
        name: 'Role',
        key: 'userRole',
        render: (item) => <span>{item.userRole || ''}</span>
      },
      {
        name: 'Display Name',
        key: 'firstName'
      },
      {
        name: 'Phone number',
        key: 'phoneNumber',
        render: (item) => item?.phoneNumber ?? 'N/A'
      },
      {
        name: 'ID Type',
        key: 'identificationTypeName',
        render: (item) => item?.identificationTypeName ?? 'N/A'
      },
      {
        name: 'ID Number',
        key: 'personalId',
        render: (item) => item?.personalId ?? 'N/A'
      },
      // {
      //     name: "Last Name",
      //     key: "lastName",
      // },
      // {
      //     name: "Phone",
      //     key: "phoneNumber",
      // },
      {
        name: 'Created Date',
        key: 'created',
        render: (item) => {
          return (
            <span>{moment(item?.created).format('DD/MM/y HH:mm:ss')}</span>
          );
        }
      },
      {
        name: 'Status',
        key: 'status',
        render: (item) => (
          <span className={`status status--${item?.status?.toLowerCase()}`}>
            {translate(item?.status)}
          </span>
        ),
        sort: {
          descreaseValue: 'desc',
          increaseValue: ''
        }
      },
      {
        name: 'Actions',
        key: 'actions',
        render: (item) => {
          return (
            <div className="action">
              <div
                className="action__edit"
                onClick={() => setEditData({ ...item })}
              >
                <Icon.ActionEditIcon />
              </div>
              <div
                className="action__more"
                onClick={() => setFreezeDataId(item.userId)}
              >
                <Icon.ActionMoreIcon />
              </div>
            </div>
          );
        }
      }
    ];

    const handleExportExcel = async () => {
      return new Promise(async (resolve) => {
        try {
          const response = await AdminService.getListUser({
            ...params,
            page: 1,
            numberOfItemsPerPage: 10000
          });

          const data = response.data;

          let fileHead: any = structure.map((v) => v.name);
          fileHead.pop(); //remove last column on table
          const dataExport = [
            fileHead,
            ...data.map((item: any) =>
              structure.map((column, index) => {
                if (column.key === 'created')
                  return moment(item[column.key]).format('L HH:mm:ss');
                if (column.key === 'status') return translate(item[column.key]);
                if (!column.key) return '';
                return item[column.key];
              })
            )
          ];

          const ws = XLSX.utils.aoa_to_sheet(dataExport);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

          const now = new Date();
          XLSX.writeFile(
            wb,
            `User List ${now.toLocaleDateString().replace(/\//g, '-')} ${now
              .toLocaleTimeString()
              .replace(/:/g, '-')}.xlsx`,
            { type: 'binary' }
          );

          resolve(
            CreateAlert({
              type: 'success',
              message: 'Export data success.'
            })
          );
        } catch (error: any) {
          resolve(CreateAlert({ type: 'danger', message: error.message }));
        }
      });
    };

    return (
      <div className="UserList">
        <Button
          className="mb20"
          label="Export to Excel"
          buttonType="primary"
          disabled={disableExport}
          onClick={handleExportExcel}
        />

        <Table
          hasOrderColumn
          hasSearchButton={true}
          itemPerPages={10}
          filters={[
            {
              name: 'Search',
              key: 'keyword',
              input: TableFilterInputText
            },
            {
              name: translate('type'),
              key: 'status',
              input: (s) => (
                <TableFilterInputSelect
                  {...s}
                  options={AdminService.getListUserStatus()}
                  isClearable={true}
                  isSearchable={false}
                />
              )
            }
          ]}
          structure={structure}
          fetchData={async (params) => {
            setParams(params);
            return AdminService.getListUser({
              page: params.pageNumber,
              numberOfItemsPerPage: params.limit,
              ...params
            }).then((res) => {
              setDisableExport(!(res?.data?.length > 0))
              return res
            });
          }}
          forceUpdateTable={forceUpdateTable}
        />
        {editData && (
          <PopupWraper
            center
            title="Edit user"
            onClose={() => setEditData(null)}
          >
            <EditUser
              data={editData}
              onFinishEdit={() => {
                setForceUpdateTable(Math.random);
                setEditData(null);
              }}
            />
          </PopupWraper>
        )}
        {freezeDataId && (
          <PopupWraper
            center
            title="Freeze user"
            onClose={() => setFreezeDataId(null)}
          >
            <Ban
              userId={freezeDataId}
              // onClick={async () => {
              //     try {
              //         // userId
              //         console.log("here");
              //         if (user.userId === userId)
              //             throw new Error(
              //                 "Can't Freeze Yourself"
              //             );

              //         if (freezeDataId) {
              //             const res = await AdminService.freezeUsers(
              //                 [freezeData.userId]
              //             );
              //             const { success } = res;
              //             if (success) {
              //                 CreateAlert({
              //                     type: "success",
              //                     message: "Freeze data success.",
              //                 });
              //                 setFreezeData(null);
              //             }
              //         }
              //     } catch (error) {
              //         CreateAlert({
              //             type: "danger",
              //             message:
              //                 error?.message ||
              //                 "Freeze data fail.",
              //         });
              //     }
              // }}
            />
          </PopupWraper>
        )}
      </div>
    );
  })
);
