import React, { memo } from 'react';
import { Button, InputWraper, useForm } from '../../../../../modules';
import { InputText } from '../../../../../components';
import { translate } from '../../../../../languages';
import { PopupWraper } from '../../../../../components/popup';

function PopupDetailKYCRejected(props: any) {
  let defaultImage = '/assets/images/logo-symbol-white1.png';
  let dataDetailKYC = props?.dataDetailKYC;
  const { handleSubmit, getInputProps } = useForm({
    enableReinitialize: true,
    structure: [
      {
        name: 'email',
        defaultValue: dataDetailKYC?.email,
        isDisabled: true
      },
      {
        name: 'displayName',
        defaultValue: dataDetailKYC?.displayName,
        isDisabled: true
      },
      {
        name: 'reason',
        defaultValue:
          dataDetailKYC?.details?.[dataDetailKYC?.details?.length - 1]
            ?.reason || '',
        isDisabled: true
      }
    ],
    onSubmit: function (values: any, dirtyFields: any): void | Promise<any> {
      throw new Error('Function not implemented.');
    }
  });
  return (
    <>
      {props?.isShow === true ? (
        <PopupWraper
          title={translate('information-for-rejection-kyc')}
          center
          onClose={props.hideDetailKYC}
        >
          <div className="group">
            <InputWraper
              label={translate('email-address')}
              inputProps={getInputProps('email')}
              component={InputText}
            />
          </div>
          <div className="group">
            <InputWraper
              label={'Display Name'}
              inputProps={getInputProps('displayName')}
              component={InputText}
            />
          </div>

          <div className="group">
            <InputWraper
              label={translate('reason')}
              inputProps={getInputProps('reason')}
              component={InputText}
            />
          </div>

          <div className="group list-image">
            <div className="image-item">
              <p className="label">{translate('face-photo')}</p>
              <div className="image-wrapper">
                <img
                  // onClick={() => onImageGallery(dataDetailKYC?.dataImage, 0)}
                  src={props.dataDetailKYC?.avatarFileId || defaultImage}
                  alt=""
                />
              </div>
            </div>

            <div className="image-item">
              <p className="label">{translate('front-of-card')}</p>
              <div className="image-wrapper">
                <img
                  // onClick={() => onImageGallery(dataDetailKYC?.dataImage, 1)}
                  src={props.dataDetailKYC?.frontOfCardFileId || defaultImage}
                  alt=""
                />
              </div>
            </div>

            <div className="image-item">
              <p className="label">{translate('back-of-card')}</p>
              <div className="image-wrapper">
                <img
                  // onClick={() => onImageGallery(dataDetailKYC?.dataImage,2)}
                  src={props.dataDetailKYC?.backOfCardFileId || defaultImage}
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* <div className="btn-action-wrapper">
                  <Button
                    className=""
                    label={'Approve'}
                    buttonType="primary"
                    onClick={() => props.handleShowPopupConfirmVerify('approve')}
                  />
      
                  <Button
                    className=""
                    label={'Reject'}
                    buttonType="grey"
                    onClick={() => props.handleShowPopupConfirmVerify('reject')}
                  />
                </div> */}
        </PopupWraper>
      ) : (
        <></>
      )}
    </>
  );
}

export default PopupDetailKYCRejected;
